import { retellLectureQuestions } from "../speakingQuestions/reTellLecture";

export const retellLectureTest = [
    {
        id: "rtlt_001",
        type: 'speaking',
        subType: "retell_lecture",
        questions: [
            { qId: "rtlq_001", testIndex: 0 },
            { qId: "rtlq_002", testIndex: 1 },
            { qId: "rtlq_003", testIndex: 2 },
            { qId: "rtlq_004", testIndex: 3 },
            { qId: "rtlq_005", testIndex: 4 },
            { qId: "rtlq_006", testIndex: 5 },
            { qId: "rtlq_007", testIndex: 6 },
        ],
        duration: retellLectureQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rtlt_002",
        type: 'speaking',
        subType: "retell_lecture",
        questions: [
            { qId: "rtlq_001", testIndex: 0 },
            { qId: "rtlq_002", testIndex: 1 },
            { qId: "rtlq_003", testIndex: 2 },
            { qId: "rtlq_004", testIndex: 3 },
            { qId: "rtlq_005", testIndex: 4 },
            { qId: "rtlq_006", testIndex: 5 },
            { qId: "rtlq_007", testIndex: 6 },
        ],
        duration: retellLectureQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 2
    },
    {
        id: "rtlt_003",
        type: 'speaking',
        subType: "retell_lecture",
        questions: [
            { qId: "rtlq_001", testIndex: 0 },
            { qId: "rtlq_002", testIndex: 1 },
            { qId: "rtlq_003", testIndex: 2 },
            { qId: "rtlq_004", testIndex: 3 },
            { qId: "rtlq_005", testIndex: 4 },
            { qId: "rtlq_006", testIndex: 5 },
            { qId: "rtlq_007", testIndex: 6 },
        ],
        duration: retellLectureQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 3
    },
    {
        id: "rtlt_004",
        type: 'speaking',
        subType: "retell_lecture",
        questions: [
            { qId: "rtlq_001", testIndex: 0 },
            { qId: "rtlq_002", testIndex: 1 },
            { qId: "rtlq_003", testIndex: 2 },
            { qId: "rtlq_004", testIndex: 3 },
            { qId: "rtlq_005", testIndex: 4 },
            { qId: "rtlq_006", testIndex: 5 },
            { qId: "rtlq_007", testIndex: 6 },
        ],
        duration: retellLectureQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 4
    },
    {
        id: "rtlt_005",
        type: 'speaking',
        subType: "retell_lecture",
        questions: [
            { qId: "rtlq_001", testIndex: 0 },
            { qId: "rtlq_002", testIndex: 1 },
            { qId: "rtlq_003", testIndex: 2 },
            { qId: "rtlq_004", testIndex: 3 },
            { qId: "rtlq_005", testIndex: 4 },
            { qId: "rtlq_006", testIndex: 5 },
            { qId: "rtlq_007", testIndex: 6 },
        ],
        duration: retellLectureQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 5
    },
    {
        id: "rtlt_006",
        type: 'speaking',
        subType: "retell_lecture",
        questions: [
            { qId: "rtlq_001", testIndex: 0 },
            { qId: "rtlq_002", testIndex: 1 },
            { qId: "rtlq_003", testIndex: 2 },
            { qId: "rtlq_004", testIndex: 3 },
            { qId: "rtlq_005", testIndex: 4 },
            { qId: "rtlq_006", testIndex: 5 },
            { qId: "rtlq_007", testIndex: 6 },
        ],
        duration: retellLectureQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 6
    },
]