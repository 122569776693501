import { useContext } from "react";
import TestBox from "../../../ProtectedComponents/TestBox/TestBox";
import "./styles.scss";
import { AuthContext } from "../../../Context/AuthContext/AuthContext";
const AnswerShortQuestionPage = () => {
  const { tests, setTests } = useContext(AuthContext)

  return (
    <div className="test-boxes-container">
      {tests.filter(td => td.type == "speaking" && td.subType == "answer_short_question").map((rd, index) => {
        return <TestBox key={index} testData={rd} link='/practice/pte-speaking/answer-short-question-test' />;
      })}
    </div>
  );
};

export default AnswerShortQuestionPage;
