
import MobileAudio from '../../Audio/describeImage/mob-1.mp3'

export const listeningHighlightIncorrectWordsQuestion = [
    {
        id: "lhiwq_001",
        audio: MobileAudio,
        paragraph: `The problem caused by plastic pollution is getting out of hand, but public awareness and intervention are about to change that. About 9 billion kilograms of plastic waste reaches the world's ocean from coastal areas annually. This plastic causes stress and harm to the marine creatures such as turtles, whales, and seabirds that starve because their bellies are full of plastic particles leaving no room for real food. A recent study about the potential adverse impacts of tiny pieces of plastic in the marine food chain says that it might adversely affect human health and food security.`,
        blanks: [
            {
                index: 23,
                answer: 'awareness'
            },
            {
                index: 44,
                answer: 'stress'
            },
            {
                index: 88,
                answer: 'recent'
            },
            {
                index: 111,
                answer: 'affect'
            },
            
        ],
        options:['recent', 'affect', 'awareness', 'stress'],
        type: 'listening',
        subType: "highlight_incorrect_words",
        time: 10,
        waitingTime:5,
        difficulty: 4
    },
    {
        id: "lhiwq_002",
        audio: MobileAudio,
        paragraph: `The problem caused by plastic pollution is getting out of hand, but public awareness and intervention are about to change that. About 9 billion kilograms of plastic waste reaches the world's ocean from coastal areas annually. This plastic causes stress and harm to the marine creatures such as turtles, whales, and seabirds that starve because their bellies are full of plastic particles leaving no room for real food. A recent study about the potential adverse impacts of tiny pieces of plastic in the marine food chain says that it might adversely affect human health and food security.`,
        blanks: [
            {
                index: 23,
                answer: 'awareness'
            },
            {
                index: 44,
                answer: 'stress'
            },
            {
                index: 88,
                answer: 'recent'
            },
            {
                index: 111,
                answer: 'affect'
            },
            
        ],
        options:['recent', 'affect', 'awareness', 'stress'],
        type: 'listening',
        subType: "highlight_incorrect_words",
        time: 10,
        waitingTime:5,

        difficulty: 4
    },
    {
        id: "lhiwq_003",
        audio: MobileAudio,
        paragraph: `The problem caused by plastic pollution is getting out of hand, but public awareness and intervention are about to change that. About 9 billion kilograms of plastic waste reaches the world's ocean from coastal areas annually. This plastic causes stress and harm to the marine creatures such as turtles, whales, and seabirds that starve because their bellies are full of plastic particles leaving no room for real food. A recent study about the potential adverse impacts of tiny pieces of plastic in the marine food chain says that it might adversely affect human health and food security.`,
        blanks: [
            {
                index: 23,
                answer: 'awareness'
            },
            {
                index: 44,
                answer: 'stress'
            },
            {
                index: 88,
                answer: 'recent'
            },
            {
                index: 111,
                answer: 'affect'
            },
            
        ],
        options:['recent', 'affect', 'awareness', 'stress'],
        type: 'listening',
        subType: "highlight_incorrect_words",
        time: 10,
        waitingTime:5,
        difficulty: 3
    },
    {
        id: "lhiwq_004",
        audio: MobileAudio,
        paragraph: `The problem caused by plastic pollution is getting out of hand, but public awareness and intervention are about to change that. About 9 billion kilograms of plastic waste reaches the world's ocean from coastal areas annually. This plastic causes stress and harm to the marine creatures such as turtles, whales, and seabirds that starve because their bellies are full of plastic particles leaving no room for real food. A recent study about the potential adverse impacts of tiny pieces of plastic in the marine food chain says that it might adversely affect human health and food security.`,
        blanks: [
            {
                index: 23,
                answer: 'awareness'
            },
            {
                index: 44,
                answer: 'stress'
            },
            {
                index: 88,
                answer: 'recent'
            },
            {
                index: 111,
                answer: 'affect'
            },
            
        ],
        options:['recent', 'affect', 'awareness', 'stress'],
        type: 'listening',
        subType: "highlight_incorrect_words",
        time: 10,
        waitingTime:5,
        difficulty: 2
    },
    {
        id: "lhiwq_005",
        audio: MobileAudio,
        paragraph: `The problem caused by plastic pollution is getting out of hand, but public awareness and intervention are about to change that. About 9 billion kilograms of plastic waste reaches the world's ocean from coastal areas annually. This plastic causes stress and harm to the marine creatures such as turtles, whales, and seabirds that starve because their bellies are full of plastic particles leaving no room for real food. A recent study about the potential adverse impacts of tiny pieces of plastic in the marine food chain says that it might adversely affect human health and food security.`,
        blanks: [
            {
                index: 23,
                answer: 'awareness'
            },
            {
                index: 44,
                answer: 'stress'
            },
            {
                index: 88,
                answer: 'recent'
            },
            {
                index: 111,
                answer: 'affect'
            },
            
        ],
        options:['recent', 'affect', 'awareness', 'stress'],
        type: 'listening',
        subType: "highlight_incorrect_words",
        time: 10,
        waitingTime:5,
        difficulty: 3
    },

]