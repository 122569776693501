import TobaccoLectureAudio from '../../Audio/reTellLecture/tobacco_question.mp3'
import TobaccoAnswerAudio from '../../Audio/reTellLecture/tobacco_answer.mp3'
import TobaccoImage from '../../Audio/reTellLecture/tobacco.webp'



export const retellLectureQuestions = [
    {
        id:"rtlq_001",
        audio: TobaccoLectureAudio,
        image: TobaccoImage,
        answerAudio: TobaccoAnswerAudio,
        questionText:"You will hear a lecture. After hearing the lecture in 10 seconds please speak into the microphone and retell what you have heard from the lecture in your own words. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"retell_lecture",
        time: 40,
        difficulty: 4,
        waitingTime: 10,
    },
    {
        id:"rtlq_002",
        audio: TobaccoLectureAudio,
        image: TobaccoImage,
        answerAudio: TobaccoAnswerAudio,
        questionText:"You will hear a lecture. After hearing the lecture in 10 seconds please speak into the microphone and retell what you have heard from the lecture in your own words. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"retell_lecture",
        time: 40,
        difficulty: 4,
        waitingTime: 10,
    },
    {
        id:"rtlq_003",
        audio: TobaccoLectureAudio,
        image: TobaccoImage,
        answerAudio: TobaccoAnswerAudio,
        questionText:"You will hear a lecture. After hearing the lecture in 10 seconds please speak into the microphone and retell what you have heard from the lecture in your own words. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"retell_lecture",
        time: 40,
        difficulty: 3,
        waitingTime: 10,
    },
    {
        id:"rtlq_004",
        audio: TobaccoLectureAudio,
        image: TobaccoImage,
        answerAudio: TobaccoAnswerAudio,
        questionText:"You will hear a lecture. After hearing the lecture in 10 seconds please speak into the microphone and retell what you have heard from the lecture in your own words. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"retell_lecture",
        time: 40,
        difficulty: 2,
        waitingTime: 10,
    },
    {
        id:"rtlq_005",
        audio: TobaccoLectureAudio,
        image: TobaccoImage,
        answerAudio: TobaccoAnswerAudio,
        questionText:"You will hear a lecture. After hearing the lecture in 10 seconds please speak into the microphone and retell what you have heard from the lecture in your own words. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"retell_lecture",
        time: 40,
        difficulty: 3,
        waitingTime: 10,
    },
    {
        id:"rtlq_006",
        audio: TobaccoLectureAudio,
        image: TobaccoImage,
        answerAudio: TobaccoAnswerAudio,
        questionText:"You will hear a lecture. After hearing the lecture in 10 seconds please speak into the microphone and retell what you have heard from the lecture in your own words. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"retell_lecture",
        time: 40,
        difficulty: 4,
        waitingTime: 10,
    },
    {
        id:"rtlq_007",
        audio: TobaccoLectureAudio,
        image: TobaccoImage,
        answerAudio: TobaccoAnswerAudio,
        questionText:"You will hear a lecture. After hearing the lecture in 10 seconds please speak into the microphone and retell what you have heard from the lecture in your own words. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"retell_lecture",
        time: 40,
        difficulty: 3,
        waitingTime: 10,
    },
 
] 