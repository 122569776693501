import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import AudioAnimation from '../../Components/AudioAnimation/AudioAnimation'
import './styles.scss'
const ListeningFillInTheBlanks = (props) => {
    const { question, option, setOption, setTestState, testState, waitingTime, hearingTime } = props
    const changeSelect = (e, sendIndex, options) => {
        setOption(prev => {
            if (prev && prev.length > 0) {
                let modify = false
                let ss = prev.map((p, index) => {
                    if (p && p.index == sendIndex) {
                        modify = true
                        return {
                            chosen: e,
                            index: sendIndex,
                            options
                        }

                    }
                    return p;

                })
                if (!modify) {

                    return [...ss, { chosen: e, index: sendIndex, options }]
                }
                else {
                    return [...ss]
                }
            }
            else {
                return [{ chosen: e, index: sendIndex, options }];

            }
        })

    }
    useEffect(() => {
        if (option && question && option.length == question.blanks.length) {
            setTestState("completed")
        }
        else {
            setTestState("stopped")
        }
    }, [option, option?.length, question])
   
    return (
        <div className='listening-fib-container'>
            <div className="listening-fib-box">
                <div className="practice-header">You will hear an audio. type the missing word in each blank</div>
                <div className="audio-box">
                    {testState == "completed" ? null : (
                        <div className="status-time">
                            00:
                            {testState == "waiting"
                                ? waitingTime
                                : hearingTime}
                        </div>
                    )}
                    {testState == "completed" ?
                        <div className="completed-mark">
                            <i className="fas fa-check-circle"></i>
                        </div>
                        : (
                            <AudioAnimation
                                color={testState == "waiting" ? "grey" : "grey"}
                                animationState={
                                    testState == "waiting" ? "paused" : "running"
                                }
                            />
                        )}{" "}
                    <div className="status-progress">
                        <div
                            className="status-progress-inner"
                            style={{
                                width: `${((35 -
                                    Number(
                                        testState == "completed"
                                            ? 0
                                            : testState == "waiting"
                                                ? waitingTime
                                                : hearingTime
                                    )) /
                                    35) *
                                    100
                                    }%`,
                            }}
                        ></div>
                    </div>
                    <div className="status-heading">Current Status: <span className="status-text">
                        {testState}
                    </span>
                    </div>
                </div>
                <div className="fib-texts practice-desc" >
                    {
                        question && question.paragraph.split(" ").map((pg, index) => {
                            let cc = <span>{pg + " "}</span>

                            question.blanks.map((qb, index2) => {
                                if (qb.index == index) {
                                    cc = <input type="text" name="" id="" onChange={(e) => changeSelect(e.target.value, index, qb.options)} />


                                }
                            })
                            return cc
                        })
                    }
                    {/* Lorem ipsum dolor, sit <input type="text" name="" id="" /> consectetur adipisicing elit. Excepturi voluptatum sunt unde?
                    Iusto eos iure veniam <input type="text" name="" id="" /> laudantium quisquam rerum natus! <input type="text" name="" id="" />
                    distinctio sit totam fugiat, a dolore architecto neque.  Lorem ipsum dolor, sit <input type="text" name="" id="" /> consectetur adipisicing elit. Excepturi voluptatum sunt unde?
                    Iusto eos iure veniam <input type="text" name="" id="" /> laudantium quisquam rerum natus! <input type="text" name="" id="" />
                    distinctio sit totam fugiat, a dolore architecto neque. */}
                </div>
            </div>

        </div>
    )
}

export default ListeningFillInTheBlanks