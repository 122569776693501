import { chooseSingleAnswerQuestion } from "../readingQuestions/chooseSingleAnswerQuestion";

export const csaTest = [
    {
        id: "csat_001",
        type: 'reading',
        subType: "choose_single_answer",
        questions: [{ qId: "csaq_001", testIndex: 0 },
        { qId: "csaq_002", testIndex: 1 },
        { qId: "csaq_003", testIndex: 2 },
        { qId: "csaq_004", testIndex: 3 },
        { qId: "csaq_005", testIndex: 4 },
        { qId: "csaq_006", testIndex: 5 }],
        duration: chooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "csat_002",
        type: 'reading',
        subType: "choose_single_answer",
        questions: [{ qId: "csaq_001", testIndex: 0 },
        { qId: "csaq_002", testIndex: 1 },
        { qId: "csaq_003", testIndex: 2 },
        { qId: "csaq_004", testIndex: 3 },
        { qId: "csaq_005", testIndex: 4 },
        { qId: "csaq_006", testIndex: 5 }],
        duration: chooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0

    },
    {
        id: "csat_003",
        type: 'reading',
        subType: "choose_single_answer",
        questions: [{ qId: "csaq_001", testIndex: 0 },
        { qId: "csaq_002", testIndex: 1 },
        { qId: "csaq_003", testIndex: 2 },
        { qId: "csaq_004", testIndex: 3 },
        { qId: "csaq_005", testIndex: 4 },
        { qId: "csaq_006", testIndex: 5 }],
        duration: chooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "csat_004",
        type: 'reading',
        subType: "choose_single_answer",
        questions: [{ qId: "csaq_001", testIndex: 0 },
        { qId: "csaq_002", testIndex: 1 },
        { qId: "csaq_003", testIndex: 2 },
        { qId: "csaq_004", testIndex: 3 },
        { qId: "csaq_005", testIndex: 4 },
        { qId: "csaq_006", testIndex: 5 }],
        duration: chooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "csat_005",
        type: 'reading',
        subType: "choose_single_answer",
        questions: [{ qId: "csaq_001", testIndex: 0 },
        { qId: "csaq_002", testIndex: 1 },
        { qId: "csaq_003", testIndex: 2 },
        { qId: "csaq_004", testIndex: 3 },
        { qId: "csaq_005", testIndex: 4 },
        { qId: "csaq_006", testIndex: 5 }],
        duration: chooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "csat_006",
        type: 'reading',
        subType: "choose_single_answer",
        questions: [{ qId: "csaq_001", testIndex: 0 },
        { qId: "csaq_002", testIndex: 1 },
        { qId: "csaq_003", testIndex: 2 },
        { qId: "csaq_004", testIndex: 3 },
        { qId: "csaq_005", testIndex: 4 },
        { qId: "csaq_006", testIndex: 5 }],
        duration: chooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
]