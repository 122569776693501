import { rnwFillInTheBlanksQuestion } from "../readingQuestions/rnwFillInTheBlanksQuestion";

export const rnwFillInTheBlanksTest = [
    {
        id: "rnwfitbt_001",
        type: 'reading',
        subType: "reading_and_writing_fill_in_the_blanks",
        questions: [{ qId: "rnwfitbq_001", testIndex: 0 },
        { qId: "rnwfitbq_002", testIndex: 1 },
        { qId: "rnwfitbq_003", testIndex: 2 },
        { qId: "rnwfitbq_004", testIndex: 3 },
        { qId: "rnwfitbq_005", testIndex: 4 },
        { qId: "rnwfitbq_006", testIndex: 5 }],
        duration: rnwFillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rnwfitbt_002",
        type: 'reading',
        subType: "reading_and_writing_fill_in_the_blanks",
        questions: [{ qId: "rnwfitbq_001", testIndex: 0 },
        { qId: "rnwfitbq_002", testIndex: 1 },
        { qId: "rnwfitbq_003", testIndex: 2 },
        { qId: "rnwfitbq_004", testIndex: 3 },
        { qId: "rnwfitbq_005", testIndex: 4 },
        { qId: "rnwfitbq_006", testIndex: 5 }],
        duration: rnwFillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0

    },
    {
        id: "rnwfitbt_003",
        type: 'reading',
        subType: "reading_and_writing_fill_in_the_blanks",
        questions: [{ qId: "rnwfitbq_001", testIndex: 0 },
        { qId: "rnwfitbq_002", testIndex: 1 },
        { qId: "rnwfitbq_003", testIndex: 2 },
        { qId: "rnwfitbq_004", testIndex: 3 },
        { qId: "rnwfitbq_005", testIndex: 4 },
        { qId: "rnwfitbq_006", testIndex: 5 },
        ],
        duration: rnwFillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rnwfitbt_004",
        type: 'reading',
        subType: "reading_and_writing_fill_in_the_blanks",
        questions: [{ qId: "rnwfitbq_001", testIndex: 0 },
        { qId: "rnwfitbq_002", testIndex: 1 },
        { qId: "rnwfitbq_003", testIndex: 2 },
        { qId: "rnwfitbq_004", testIndex: 3 },
        { qId: "rnwfitbq_005", testIndex: 4 },
        { qId: "rnwfitbq_006", testIndex: 5 }],
        duration: rnwFillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rnwfitbt_005",
        type: 'reading',
        subType: "reading_and_writing_fill_in_the_blanks",
        questions: [{ qId: "rnwfitbq_001", testIndex: 0 },
        { qId: "rnwfitbq_002", testIndex: 1 },
        { qId: "rnwfitbq_003", testIndex: 2 },
        { qId: "rnwfitbq_004", testIndex: 3 },
        { qId: "rnwfitbq_005", testIndex: 4 },
        { qId: "rnwfitbq_006", testIndex: 5 }
        ],
        duration: rnwFillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rnwfitbt_006",
        type: 'reading',
        subType: "reading_and_writing_fill_in_the_blanks",
        questions: [{ qId: "rnwfitbq_001", testIndex: 0 },
        { qId: "rnwfitbq_002", testIndex: 1 },
        { qId: "rnwfitbq_003", testIndex: 2 },
        { qId: "rnwfitbq_004", testIndex: 3 },
        { qId: "rnwfitbq_005", testIndex: 4 },
        { qId: "rnwfitbq_006", testIndex: 5 }],
        duration: rnwFillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
]