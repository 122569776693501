import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
const TestingPage = () => {
  const navigate = useNavigate();
  const [allowed, setAllowed] = useState(false);
  const getPermission = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        console.log("You let me use your mic!");
        setAllowed(true);
      })
      .catch(function (err) {
        message.error("You need to allow the microphone to continue");
        console.log("No mic for you!", err);
      });
  };
  useEffect(() => {
    getPermission();
  }, []);
  return (
    <div className="testing-page">
      <div className="container">
        <div className="testing-page-inner">
          <h2 className="practice-header">
            {allowed ? 'Your mic is working fine. Proceed' : 'Check if your mic and audio is on'}
          </h2>
          <div className="testing-buttons">
            <button
              className="main-button"
              onClick={() => {
                if (allowed) {
                  navigate("/practice/rules");
                } else {
                  getPermission();
                }
              }}
            >
              {allowed ? "Next" : "Request"}
            </button>
            <button className="alternate-button"

              onClick={() => {
                window.close()

              }}>Exit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestingPage;
