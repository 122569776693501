import { useRef, useState } from "react";
import { useEffect } from "react";
import "./styles.scss";
import Droppable from "./Droppable";
import Draggable from "./Draggable";
import { DndContext } from '@dnd-kit/core';

const SelectBestWord = (props) => {
  const { question, option, setOption, setTestState } = props
  const [selectedWords, setSelectedWords] = useState([])

  useEffect(() => {
    if (question && question.options.length == selectedWords.length) {
      setOption(selectedWords)
      setTestState("completed")
    }
    else {
      setTestState("stopped")
    }
  }, [question, selectedWords, selectedWords.length])
  function handleDragEnd(event) {
    console.log(event)
    const { over, active } = event;
    setSelectedWords(prev => {
      let cc = prev
      let found = false;
      if (prev.length > 0) {

        let dd = cc.map((c, ind) => {
          if (c.filledIndex == over.data.current.index) {
            found = true
            return { ...c, word: active.data.current.text }
          }
          else {
            return {
              ...c
            }
          }

        })
        if (!found) {
          return [...dd, { word: active.data.current.text, filledIndex: over.data.current.index }]
        }
        else {
          return [...dd]
        }
      }
      else {
        return [{ word: active.data.current.text, filledIndex: over.data.current.index }]

      }
    })

  }
  return (
    <DndContext onDragEnd={handleDragEnd}>

      <div className="select-best-word-container">
        <div className="select-best-word-box">
          <div className="practice-header">
            In the text below some words are missing. Drag the word from the box
            below to the appropriate place in text, to undo an answer choice drag
            the word back to the box below text.
          </div>
          <div className="select-best-word-body practice-desc">
            {
              question && question.paragraph.split(" ").map((pg, index) => {
                let cc = <span>{pg + " "}</span>

                question.blanks.map((qb, index2) => {
                  if (qb.index == index) {
                    cc =
                      <Droppable id={index}>

                        {
                          selectedWords.find(s => s.filledIndex == index) ?
                            selectedWords.find(s => s.filledIndex == index).word : null
                        }
                      </Droppable>

                  }
                })
                return cc
              })
            }

          </div>
          <div className="blank-box best-word-collection ">{
            question && question.options.filter(op => {
              let sel = true;

              selectedWords.map(s => {
                if (s.word == op) {
                  sel = false
                }
              })
              return sel
            }).map((qo, index) => {
              return <Draggable text={qo} id={index} status={'sorted'} key={index} />

            })
          }

          </div>

        </div>


      </div>
    </DndContext>
  );
};

export default SelectBestWord;
