import { message } from "antd";
import { server } from "../fetch";

export const login = async (values) => {
  try {
    const res = await fetch(`${server}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });
    if (res.status === 401) {
      message.error("The email or password do not match");
      throw new Error("The authentication failed");
    }
    const data = await res.json();
     
    localStorage.setItem("epiUser", JSON.stringify(data.user));
    localStorage.setItem("epiToken", JSON.stringify(data.acessToken));
    localStorage.setItem(
      "epiRefreshToken",
      JSON.stringify(data.refreshToken)
    );

    return data;
  } catch (error) {
     
  }
};

export const subscriberCheck = async (values) => {
  try {
    const res = await fetch(`${server}/subscriber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });
     
    if (res.status !== 200) {
      message.error("The subscriber Link failed");
      throw new Error("The Subscriber Link failed");
    }
    const data = values;
    localStorage.setItem("subscriber", JSON.stringify(data));
    return data;
  } catch (error) {
     
  }
};

export const logout = () => {
  localStorage.removeItem("epiToken");
  localStorage.removeItem("epiRefreshToken");
  localStorage.removeItem("epiAuth");
};
