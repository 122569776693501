import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import AnalyticsBox from "../../NpComponents/AnalyticsBox/AnalyticsBox";
import Analytics from "../Analytics/Analytics";

import "./styles.scss";
const AdminDashboard = () => {
  const { menu, blog, contact, jobs } = useContext(AuthContext);
  const dashboardAnalyticsData = [
    {
      icon: <i class="fa-solid fa-utensils"></i>,
      title: "Menu",
      data: 0,
      background: "blue",
    },
    {
      icon: <i class="fa-solid fa-scroll"></i>,
      title: "Blogs",
      data: 0,
      background: "blue",
    },
    {
      icon: <i class="fa-solid fa-envelope"></i>,
      title: "Contacts",
      data: contact.length,
      background: "blue",
    },
    {
      icon: <i class="fa-regular fa-address-card"></i>,
      title: "Memberships",
      data: jobs.length,
      background: "red",
    },
  ];

  return (
    <div className="dashboard-page-container">
      <div className="dashboard-analytics-boxes">
        <div className="row">
          {dashboardAnalyticsData.map((data, index) => {
            return (
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <AnalyticsBox item={data} />
              </div>
            );
          })}
        </div>
      </div>
      <Analytics />
    </div>
  );
};

export default AdminDashboard;
