import { mcQuestion } from "../readingQuestions/mcQuestion";
import { rnwFillInTheBlanksQuestion } from "../readingQuestions/rnwFillInTheBlanksQuestion";

export const mcqTest = [
    {
        id: "rmcqt_001",
        type: 'reading',
        subType: "multiple_choice_multiple_answer",
        questions: [{ qId: "rmcq_001", testIndex: 0 },
        { qId: "rmcq_002", testIndex: 1 },
        { qId: "rmcq_003", testIndex: 2 },
        { qId: "rmcq_004", testIndex: 3 },
        { qId: "rmcq_005", testIndex: 4 },
        { qId: "rmcq_006", testIndex: 5 }],
        duration: mcQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rmcqt_002",
        type: 'reading',
        subType: "multiple_choice_multiple_answer",
        questions: [{ qId: "rmcq_001", testIndex: 0 },
        { qId: "rmcq_002", testIndex: 1 },
        { qId: "rmcq_003", testIndex: 2 },
        { qId: "rmcq_004", testIndex: 3 },
        { qId: "rmcq_005", testIndex: 4 },
        { qId: "rmcq_006", testIndex: 5 }],
        duration: mcQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0

    },
    {
        id: "rmcqt_003",
        type: 'reading',
        subType: "multiple_choice_multiple_answer",
        questions: [{ qId: "rmcq_001", testIndex: 0 },
        { qId: "rmcq_002", testIndex: 1 },
        { qId: "rmcq_003", testIndex: 2 },
        { qId: "rmcq_004", testIndex: 3 },
        { qId: "rmcq_005", testIndex: 4 },
        { qId: "rmcq_006", testIndex: 5 }],
        duration: mcQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rmcqt_004",
        type: 'reading',
        subType: "multiple_choice_multiple_answer",
        questions: [{ qId: "rmcq_001", testIndex: 0 },
        { qId: "rmcq_002", testIndex: 1 },
        { qId: "rmcq_003", testIndex: 2 },
        { qId: "rmcq_004", testIndex: 3 },
        { qId: "rmcq_005", testIndex: 4 },
        { qId: "rmcq_006", testIndex: 5 }],
        duration: mcQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rmcqt_005",
        type: 'reading',
        subType: "multiple_choice_multiple_answer",
        questions: [{ qId: "rmcq_001", testIndex: 0 },
        { qId: "rmcq_002", testIndex: 1 },
        { qId: "rmcq_003", testIndex: 2 },
        { qId: "rmcq_004", testIndex: 3 },
        { qId: "rmcq_005", testIndex: 4 },
        { qId: "rmcq_006", testIndex: 5 }],
        duration: mcQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rmcqt_006",
        type: 'reading',
        subType: "multiple_choice_multiple_answer",
        questions: [{ qId: "rmcq_001", testIndex: 0 },
        { qId: "rmcq_002", testIndex: 1 },
        { qId: "rmcq_003", testIndex: 2 },
        { qId: "rmcq_004", testIndex: 3 },
        { qId: "rmcq_005", testIndex: 4 },
        { qId: "rmcq_006", testIndex: 5 }],
        duration: mcQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
]