export const readAloudQuestions = [
    {
        id:"raq_001",
        readingText: `Football fans are people who love football. They usually have a favorite team, 
        such as the Dallas Cowboys or the Minnesota Vikings. When major football games are shown on TV, 
        fans have a party and watch the game together. They eat snacks and cheer for their favorite football
         team. Some fans bet money on which team will win the game.`,
        type: 'speaking',
        subType:"read_aloud",
        time: 20,
        difficulty: 4
    },
    {
        id:"raq_002",
        readingText: `Capitalism is an economic system based on the private ownership of the means of 
        production and their operation for profit. Central characteristics of capitalism include capital 
        accumulation, competitive markets, price systems, private property, property rights recognition, 
        voluntary exchange, and wage labor.`,
        type: 'speaking',
        subType:"read_aloud",
        time: 20,
        difficulty: 4
    },
    {
        id:"raq_003",
        readingText: `The Electoral College is a process, not a place. The Founding Fathers established 
        it in the Constitution, in part, as a compromise between the election of the President by a vote 
        in Congress and election of the President by a popular vote of qualified citizens.`,
        type: 'speaking',
        subType:"read_aloud",
        time: 20,
        difficulty: 3
    },
    {
        id:"raq_004",
        readingText: `Video is an electronic medium for the recording, copying, playback, broadcasting, 
        and display of moving visual media.`,
        type: 'speaking',
        subType:"read_aloud",
        time: 10,
        difficulty: 2
    },
    {
        id:"raq_005",
        readingText: `A lithium-ion or Li-ion battery is a type of rechargeable battery which uses the 
        reversible reduction of lithium ions to store energy. The negative electrode of a conventional 
        lithium-ion cell is typically graphite, a form of carbon.`,
        type: 'speaking',
        subType:"read_aloud",
        time: 15,
        difficulty: 3
    },
    {
        id:"raq_006",
        readingText: `The Mona Lisa is a half-length portrait painting by Italian artist Leonardo da Vinci. 
        Considered an archetypal masterpiece of the Italian Renaissance, it has been described as "the best 
        known, the most visited, the most written about, the most sung about, [and] the most parodied work of 
        art in the world".`,
        type: 'speaking',
        subType:"read_aloud",
        time: 20,
        difficulty: 4
    },
    {
        id:"raq_007",
        readingText: `Socialism is a political philosophy and movement encompassing a wide range of economic and 
        social systems which are characterised by social ownership of the means of production, as opposed to 
        private ownership.`,
        type: 'speaking',
        subType:"read_aloud",
        time: 15,
        difficulty: 3
    },
    {
        id:"raq_008",
        readingText: `Democracy is a form of government in which the people have the authority to deliberate 
        and decide legislation or to choose governing officials to do so.`,
        type: 'speaking',
        subType:"read_aloud",
        time: 10,
        difficulty: 2
    },
    {
        id:"raq_009",
        readingText: `Solaris is a proprietary Unix operating system originally developed by Sun Microsystems. 
        After the Sun acquisition by Oracle in 2010, it was renamed Oracle Solaris.`,
        type: 'speaking',
        subType:"read_aloud",
        time: 10,
        difficulty: 2
    },
    {
        id:"raq_010",
        readingText: `The Space Exploration Technologies Corporation, commonly referred to as SpaceX, is an 
        American spacecraft manufacturer, launch service provider and satellite communications company 
        headquartered in Hawthorne, California, United States.`,
        type: 'speaking',
        subType:"read_aloud",
        time: 15,
        difficulty: 3
    },
] 