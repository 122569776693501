

export const chooseSingleAnswerQuestion = [
    {
        id: "csaq_001",
        paragraph: `Declines in renewable energy pricing are creating opportunities for electric cooperatives in the U.S.  to deliver cost savings to their members.  However, this is also creating risks for those utilities that are slower to transition to these technologies, according to a new study.   Electric cooperatives have proposed retiring significant coal capacity and generating 55 percent of their energy from wind and solar resources by 2026. This would likely result in net customer cost savings, because of the highly competitive bids for wind and solar energy.  The long-term fixed prices for new wind and solar projects beat the operating costs of many existing coal assets in the West.`,
        question: "What is the writer's main point about renewable energy pricing?",
        mcq: [
            {
                index: 0,
                option: 'Electric cooperatives plan to increase the energy pricing.',
                isTrue: false
            },
            {
                index: 1,
                option: "Coal capacity is being increased to meet rising consumer demand.",
                isTrue: false
            },
            {
                index: 2,
                option: 'The prices for new wind and solar projects are higher than the operating costs of existing coal assets.',
                isTrue: false
            },
            {
                index: 3,
                option: `The decrease in renewable energy pricing can benefit consumers.`,
                isTrue: true
            },

        ],
        type: 'reading',
        subType: "choose_single_answer",
        time: 10,
        difficulty: 4
    },
    {
        id: "csaq_002",
        paragraph: `Declines in renewable energy pricing are creating opportunities for electric cooperatives in the U.S.  to deliver cost savings to their members.  However, this is also creating risks for those utilities that are slower to transition to these technologies, according to a new study.   Electric cooperatives have proposed retiring significant coal capacity and generating 55 percent of their energy from wind and solar resources by 2026. This would likely result in net customer cost savings, because of the highly competitive bids for wind and solar energy.  The long-term fixed prices for new wind and solar projects beat the operating costs of many existing coal assets in the West.`,
        question: "What is the writer's main point about renewable energy pricing?",
        mcq: [
            {
                index: 0,
                option: 'Electric cooperatives plan to increase the energy pricing.',
                isTrue: false
            },
            {
                index: 1,
                option: "Coal capacity is being increased to meet rising consumer demand.",
                isTrue: false
            },
            {
                index: 2,
                option: 'The prices for new wind and solar projects are higher than the operating costs of existing coal assets.',
                isTrue: false
            },
            {
                index: 3,
                option: `The decrease in renewable energy pricing can benefit consumers.`,
                isTrue: true
            },

        ],
        type: 'reading',
        subType: "choose_single_answer",
        time: 10,
        difficulty: 4
    },
    {
        id: "csaq_003",
        paragraph: `Declines in renewable energy pricing are creating opportunities for electric cooperatives in the U.S.  to deliver cost savings to their members.  However, this is also creating risks for those utilities that are slower to transition to these technologies, according to a new study.   Electric cooperatives have proposed retiring significant coal capacity and generating 55 percent of their energy from wind and solar resources by 2026. This would likely result in net customer cost savings, because of the highly competitive bids for wind and solar energy.  The long-term fixed prices for new wind and solar projects beat the operating costs of many existing coal assets in the West.`,
        question: "What is the writer's main point about renewable energy pricing?",
        mcq: [
            {
                index: 0,
                option: 'Electric cooperatives plan to increase the energy pricing.',
                isTrue: false
            },
            {
                index: 1,
                option: "Coal capacity is being increased to meet rising consumer demand.",
                isTrue: false
            },
            {
                index: 2,
                option: 'The prices for new wind and solar projects are higher than the operating costs of existing coal assets.',
                isTrue: false
            },
            {
                index: 3,
                option: `The decrease in renewable energy pricing can benefit consumers.`,
                isTrue: true
            },

        ],
        type: 'reading',
        subType: "choose_single_answer",
        time: 10,
        difficulty: 4
    },
    {
        id: "csaq_004",
        paragraph: `Declines in renewable energy pricing are creating opportunities for electric cooperatives in the U.S.  to deliver cost savings to their members.  However, this is also creating risks for those utilities that are slower to transition to these technologies, according to a new study.   Electric cooperatives have proposed retiring significant coal capacity and generating 55 percent of their energy from wind and solar resources by 2026. This would likely result in net customer cost savings, because of the highly competitive bids for wind and solar energy.  The long-term fixed prices for new wind and solar projects beat the operating costs of many existing coal assets in the West.`,
        question: "What is the writer's main point about renewable energy pricing?",
        mcq: [
            {
                index: 0,
                option: 'Electric cooperatives plan to increase the energy pricing.',
                isTrue: false
            },
            {
                index: 1,
                option: "Coal capacity is being increased to meet rising consumer demand.",
                isTrue: false
            },
            {
                index: 2,
                option: 'The prices for new wind and solar projects are higher than the operating costs of existing coal assets.',
                isTrue: false
            },
            {
                index: 3,
                option: `The decrease in renewable energy pricing can benefit consumers.`,
                isTrue: true
            },

        ],
        type: 'reading',
        subType: "choose_single_answer",
        time: 10,
        difficulty: 4
    },
    {
        id: "csaq_005",
        paragraph: `Declines in renewable energy pricing are creating opportunities for electric cooperatives in the U.S.  to deliver cost savings to their members.  However, this is also creating risks for those utilities that are slower to transition to these technologies, according to a new study.   Electric cooperatives have proposed retiring significant coal capacity and generating 55 percent of their energy from wind and solar resources by 2026. This would likely result in net customer cost savings, because of the highly competitive bids for wind and solar energy.  The long-term fixed prices for new wind and solar projects beat the operating costs of many existing coal assets in the West.`,
        question: "What is the writer's main point about renewable energy pricing?",
        mcq: [
            {
                index: 0,
                option: 'Electric cooperatives plan to increase the energy pricing.',
                isTrue: false
            },
            {
                index: 1,
                option: "Coal capacity is being increased to meet rising consumer demand.",
                isTrue: false
            },
            {
                index: 2,
                option: 'The prices for new wind and solar projects are higher than the operating costs of existing coal assets.',
                isTrue: false
            },
            {
                index: 3,
                option: `The decrease in renewable energy pricing can benefit consumers.`,
                isTrue: true
            },

        ],
        type: 'reading',
        subType: "choose_single_answer",
        time: 10,
        difficulty: 4
    },
]