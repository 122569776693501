
import { useEffect } from 'react'
import AudioAnimation from '../../Components/AudioAnimation/AudioAnimation'
import './styles.scss'
const ListeningSingleAnswer = (props) => {
    const { question, option, setOption, setTestState, hearingTime, waitingTime, testState } = props

    const changeSelect = (e) => {
        setOption(prev => {
            if (prev) {
                if (prev.index == e.index) {
                    return null;
                } else {
                    return { ...e }
                }

            }
            else {
                return { ...e };

            }
        })

    }
    useEffect(() => {
        if (option && question) {
            setTestState("completed")
        }
        else {
            setTestState("stopped")
        }
    }, [option, question])
    return (
        <div className='listening-single-answer-container' >
            <div className="listening-single-answer-box">
                <div className="lsa-left">
                    <div className="audio-box">
                        {testState == "completed" ? null : (
                            <div className="status-time">
                                00:
                                {testState == "waiting"
                                    ? waitingTime
                                    : hearingTime}
                            </div>
                        )}
                        {testState == "completed" ?
                            <div className="completed-mark">
                                <i className="fas fa-check-circle"></i>
                            </div>
                            : (
                                <AudioAnimation
                                    color={testState == "waiting" ? "grey" : "grey"}
                                    animationState={
                                        testState == "waiting" ? "paused" : "running"
                                    }
                                />
                            )}{" "}
                        <div className="status-progress">
                            <div
                                className="status-progress-inner"
                                style={{
                                    width: `${((35 -
                                        Number(
                                            testState == "completed"
                                                ? 0
                                                : testState == "waiting"
                                                    ? waitingTime
                                                    : hearingTime
                                        )) /
                                        35) *
                                        100
                                        }%`,
                                }}
                            ></div>
                        </div>
                        <div className="status-heading">Current Status: <span className="status-text">
                            {testState}
                        </span>
                        </div>
                    </div>
                </div>
                <div className="lsa-right">
                    <div className="practice-header">Listen to the recording and answer the question by selecting al the correct responses.
                        You will need to select more than one response.
                    </div>
                    <div className="practice-desc lsa-question">
                        {question && question.question}
                    </div>

                    <div className="lsa-selection-container">
                        <div className="choose-single-check-boxes">
                            {question && question.mcq.map((vv, index) => {

                                return (
                                    <div
                                        className="check-box-container"
                                        onClick={() => {
                                            changeSelect(vv)
                                        }}
                                    >
                                        <div className="check-round">
                                            {option && option.index == vv.index && (
                                                <div className="check-mark">
                                                    <i className="fa-solid fa-check"></i>
                                                </div>
                                            )}
                                        </div>
                                        <div className="ckeck-name">{vv.option}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-buttons lsm-footer">
                <button className="alternate-button">Save & Exit</button>
                <button className="main-button">Next</button>
            </div>
        </div >
    )
}

export default ListeningSingleAnswer