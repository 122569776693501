import { useEffect, useState } from 'react'
import './styles.scss'
import { allQuestions } from '../../Assets/Data/questions'
import { testsData } from '../../Assets/Data/tests'
type Props = {}

const Analytical = (props: Props) => {
    const [myTestProcess, setMyTestProcess] = useState<any>()
    useEffect(() => {
        let cc = localStorage.getItem('mytestProcess')
        if (cc) {
            let dd = JSON.parse(cc)
            let newq = dd.answered.map((da, index) => {


                let myq = allQuestions.find(aq => aq.id == da.question)
                // var blobUrl = URL.createObjectURL(da.answer);
                // console.log("fiel", blobUrl)
                return { ...da, question: myq }
            })
            dd.answered = newq
            setMyTestProcess(dd)
        }
    }, [localStorage.getItem('mytestProcess')])
    return (
        <div>
            <div className="anyylitics-container">
                {
                    myTestProcess && myTestProcess.answered && myTestProcess.answered.map((mtp, index) => {
                        return <div className="analytics-item">
                            {
                                mtp.question.id
                            }
                            <audio controls >
                                <source src={mtp.answer.blobUrl} type="audio/mpeg" />
                            </audio>
                        </div>

                    })
                }
            </div>
        </div>
    )
}

export default Analytical