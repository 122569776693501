import {useDroppable} from '@dnd-kit/core';
import {ReactNode} from 'react'
type Props= {
  children: ReactNode,
  id: number
}
const Droppable = ({children, id}: Props) => {
  const {isOver, setNodeRef} = useDroppable({
    id: `droppable${id}`,
    data:{
      index: id
    }
  });
  const style = {
    borderColor: isOver ? 'red' : "initial",
    borderStyle: isOver?"dashed":"solid",
    color: "#000"

  };
  return (
    
    <span ref={setNodeRef} style={style} className="blank-box">
    {children}
  </span>
  )
}

export default Droppable