

const initializeIndexDb = () => {
    let myDB = null;
    if (!('indexedDB' in window)) {
        console.log("This browser doesn't support IndexedDB");

    }
    const request = window.indexedDB.open("pte", 1);
    request.onerror = (event) => {
        console.log("Error opening db: ", event)
    };
    request.onsuccess = (event) => {
        myDB = event.target.result;
    };
    request.onupgradeneeded = function (e) {
        myDB = e.target.result;
        if(!myDB.objectStoreNames.contains("testProcess")) {
            myDB.createObjectStore("testProcess",{keyPath:"id"});
        }

        console.log("running onupgradeneeded");
        // if(!thisDB.objectStoreNames.contains("firstOS")) {
        //     console.log("makng a new object store");
        //     thisDB.createObjectStore("firstOS");
        // }
    }

    return myDB

}

export default initializeIndexDb