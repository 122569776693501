import DecadeAudio from '../../Audio/answerShortQuestion/decade-1.mp3'
import AnimalsAudio from '../../Audio/answerShortQuestion/animals.mp3'
import FruitAudio from '../../Audio/answerShortQuestion/fruit.mp3'
import HammersAudio from '../../Audio/answerShortQuestion/hammer.mp3'
import IceAudio from '../../Audio/answerShortQuestion/ice.mp3'
import SevenAudio from '../../Audio/answerShortQuestion/seven.mp3'
import SouthAudio from '../../Audio/answerShortQuestion/south.mp3'
import ExpiryAudio from '../../Audio/answerShortQuestion/expiry-date.mp3'
import StudentAudio from '../../Audio/answerShortQuestion/student.mp3'
import VillageAudio from '../../Audio/answerShortQuestion/village.mp3'

export const answerShortQuestion = [
    {
        id: "asqq_001",
        audio: StudentAudio,
        type: 'speaking',
        subType: "answer_short_question",
        time: 5,
        difficulty: 4
    },
    {
        id: "asqq_002",
        audio: DecadeAudio,
        type: 'speaking',
        subType: "answer_short_question",
        time: 5,
        difficulty: 4
    },
    {
        id: "asqq_003",
        audio: AnimalsAudio,
        type: 'speaking',
        subType: "answer_short_question",
        time: 5,
        difficulty: 3
    },
    {
        id: "asqq_004",
        audio: FruitAudio,
        type: 'speaking',
        subType: "answer_short_question",
        time: 5,
        difficulty: 2
    },
    {
        id: "asqq_005",
        audio: HammersAudio,
        type: 'speaking',
        subType: "answer_short_question",
        time: 5,
        difficulty: 3
    },
    {

        id: "asqq_006",
        audio: SevenAudio,
        type: 'speaking',
        subType: "answer_short_question",
        time: 5,
        difficulty: 4
    },
    {


        id: "asqq_007",
        audio: SouthAudio,
        type: 'speaking',
        subType: "answer_short_question",
        time: 5,
        difficulty: 3
    },
    {
        id: "asqq_008",
        audio: ExpiryAudio,
        type: 'speaking',
        subType: "answer_short_question",
        time: 5,
        difficulty: 2
    },
    {
        id: "asqq_009",
        audio: VillageAudio,
        type: 'speaking',
        subType: "answer_short_question",
        time: 5,
        difficulty: 2
    },
    {
        id: "asqq_010",
        audio: IceAudio,
        type: 'speaking',
        subType: "answer_short_question",
        time: 5,
        difficulty: 3
    },
] 