import { listeningHighlightIncorrectWordsQuestion } from "../listeningQuestions/highlightIncorrectWordsQuestions";

export const highlightIncorrectWordsTest = [
    {
        id: "hiwt_001",
        type: 'listening',
        subType: "highlight_incorrect_words",
        questions: [{ qId: "lhiwq_001", testIndex: 0 },
        { qId: "lhiwq_002", testIndex: 1 },
        { qId: "lhiwq_003", testIndex: 2 },
        { qId: "lhiwq_004", testIndex: 3 },
        { qId: "lhiwq_005", testIndex: 4 },
        { qId: "lhiwq_006", testIndex: 5 }],
        duration: listeningHighlightIncorrectWordsQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "hiwt_002",
        type: 'listening',
        subType: "highlight_incorrect_words",
        questions: [{ qId: "lhiwq_001", testIndex: 0 },
        { qId: "lhiwq_002", testIndex: 1 },
        { qId: "lhiwq_003", testIndex: 2 },
        { qId: "lhiwq_004", testIndex: 3 },
        { qId: "lhiwq_005", testIndex: 4 },
        { qId: "lhiwq_006", testIndex: 5 }],
        duration: listeningHighlightIncorrectWordsQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0

    },
    {
        id: "hiwt_003",
        type: 'listening',
        subType: "highlight_incorrect_words",
        questions: [{ qId: "lhiwq_001", testIndex: 0 },
        { qId: "lhiwq_002", testIndex: 1 },
        { qId: "lhiwq_003", testIndex: 2 },
        { qId: "lhiwq_004", testIndex: 3 },
        { qId: "lhiwq_005", testIndex: 4 },
        { qId: "lhiwq_006", testIndex: 5 }],
        duration: listeningHighlightIncorrectWordsQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "hiwt_004",
        type: 'listening',
        subType: "highlight_incorrect_words",
        questions: [{ qId: "lhiwq_001", testIndex: 0 },
        { qId: "lhiwq_002", testIndex: 1 },
        { qId: "lhiwq_003", testIndex: 2 },
        { qId: "lhiwq_004", testIndex: 3 },
        { qId: "lhiwq_005", testIndex: 4 },
        { qId: "lhiwq_006", testIndex: 5 }],
        duration: listeningHighlightIncorrectWordsQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "hiwt_005",
        type: 'listening',
        subType: "highlight_incorrect_words",
        questions: [{ qId: "lhiwq_001", testIndex: 0 },
        { qId: "lhiwq_002", testIndex: 1 },
        { qId: "lhiwq_003", testIndex: 2 },
        { qId: "lhiwq_004", testIndex: 3 },
        { qId: "lhiwq_005", testIndex: 4 },
        { qId: "lhiwq_006", testIndex: 5 }],
        duration: listeningHighlightIncorrectWordsQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "hiwt_006",
        type: 'listening',
        subType: "highlight_incorrect_words",
        questions: [{ qId: "lhiwq_001", testIndex: 0 },
        { qId: "lhiwq_002", testIndex: 1 },
        { qId: "lhiwq_003", testIndex: 2 },
        { qId: "lhiwq_004", testIndex: 3 },
        { qId: "lhiwq_005", testIndex: 4 },
        { qId: "lhiwq_006", testIndex: 5 }],
        duration: listeningHighlightIncorrectWordsQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
]