import { chooseMultipleAnswerQuestionTest } from './listeningTests/chooseMultipleAnswerQuestionsTest'
import { highlightCorrectParagraphTest } from './listeningTests/highlightCorrectParagraphTest'
import { highlightIncorrectWordsTest } from './listeningTests/highlightIncorrectWordsTest'
import { listeningChooseSingleAnswerTest } from './listeningTests/listeningChooseSingleAnswerTest'
import { listeningFillInTheBlanksTest } from './listeningTests/listeningFillInTheBlanksTest'
import { summarizeSpokenTest } from './listeningTests/summarizeSpokenTest'
import { writeFromDictationTest } from './listeningTests/writeFromDictationTest'
import { csaTest } from './readingTests/csaTest'
import { fillInTheBlanksTest } from './readingTests/fillInTheBlanksTest'
import { mcqTest } from './readingTests/mcqTest'
import { reOrderParagraphTest } from './readingTests/reOrderParagraphTest'
import { rnwFillInTheBlanksTest } from './readingTests/rnwFillInTheBlanksTest'
import { answerShortQuestionTest } from './speakingTest/answerShortQuestionTest'
import { describeImageTest } from './speakingTest/describeImageTest'
import {readAloudTest} from './speakingTest/readAloudTest'
import { repeatSentenceTest } from './speakingTest/repeatSentenceTest'
import { retellLectureTest } from './speakingTest/retellLectureTest'
export const testsData = [
    ...readAloudTest,
    ...repeatSentenceTest,
    ...describeImageTest,
    ...retellLectureTest,
    ...answerShortQuestionTest,
    ...rnwFillInTheBlanksTest,
    ...mcqTest,
    ...reOrderParagraphTest,
    ...fillInTheBlanksTest,
    ...csaTest,
    ...writeFromDictationTest,
    ...summarizeSpokenTest,
    ...highlightCorrectParagraphTest,
    ...highlightIncorrectWordsTest,
    ...listeningFillInTheBlanksTest,
    ...chooseMultipleAnswerQuestionTest,
    ...listeningChooseSingleAnswerTest

]