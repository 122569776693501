

export const mcQuestion = [
    {
        id: "rmcq_001",
        paragraph: `Water covers more than 66% of the surface of earth. Nearly all of Earth's water is found in seas and other saltwater bodies, while most of the remaining freshwater is solidified in the form of snow cover.

        Waterways, lakes, wetlands and aquifers that contain easily accessible freshwater represent less than 1% of the world's water supply. But this valuable resource supports an enormous diversity of life, and is vital for human survival.
        
        The demand for water for a variety of different uses such as drinking, sanitation, farming, and energy production continues to increase as the worldwide population grows. In the meantime, human activities and environmental change are influencing changes in water cycles, putting freshwater ecosystems under strain. Pollution, construction and mining activities pose additional challenges.
        `,
        question:"Which of the following are true statements about water?",
        mcq: [
            {
                index: 0,
                option: 'The demand for water has seen a steady decline over time.',
                isTrue: false
            },
            {
                index: 1,
                option: "Freshwater constitutes about 1% of the world's  water supply.",
                isTrue: false
            },
            {
                index: 2,
                option: 'Over two thirds of the surface of the earth is covered with water',
                isTrue: false
            },
            {
                index: 3,
                option: `Most of the Earth's water is solidified in the form of snow.`,
                isTrue: false
            },
            {
                index: 4,
                option: `Human survival does not depend on water.`,
                isTrue: false
            },

        ],
        type: 'reading',
        subType: "multiple_choice_multiple_answer",
        time: 10,
        difficulty: 4
    },
    {
        id: "rmcq_002",
        paragraph: `Water covers more than 66% of the surface of earth. Nearly all of Earth's water is found in seas and other saltwater bodies, while most of the remaining freshwater is solidified in the form of snow cover.

        Waterways, lakes, wetlands and aquifers that contain easily accessible freshwater represent less than 1% of the world's water supply. But this valuable resource supports an enormous diversity of life, and is vital for human survival.
        
        The demand for water for a variety of different uses such as drinking, sanitation, farming, and energy production continues to increase as the worldwide population grows. In the meantime, human activities and environmental change are influencing changes in water cycles, putting freshwater ecosystems under strain. Pollution, construction and mining activities pose additional challenges.
        `,
        question:"Which of the following are true statements about water?",
        mcq: [
            {
                index: 0,
                option: 'The demand for water has seen a steady decline over time.',
                isTrue: false
            },
            {
                index: 1,
                option: "Freshwater constitutes about 1% of the world's  water supply.",
                isTrue: false
            },
            {
                index: 2,
                option: 'Over two thirds of the surface of the earth is covered with water',
                isTrue: false
            },
            {
                index: 3,
                option: `Most of the Earth's water is solidified in the form of snow.`,
                isTrue: false
            },
            {
                index: 4,
                option: `Human survival does not depend on water.`,
                isTrue: false
            },

        ],
        type: 'reading',
        subType: "multiple_choice_multiple_answer",
        time: 10,
        difficulty: 4
    },
    {
        id: "rmcq_003",
        paragraph: `Water covers more than 66% of the surface of earth. Nearly all of Earth's water is found in seas and other saltwater bodies, while most of the remaining freshwater is solidified in the form of snow cover.

        Waterways, lakes, wetlands and aquifers that contain easily accessible freshwater represent less than 1% of the world's water supply. But this valuable resource supports an enormous diversity of life, and is vital for human survival.
        
        The demand for water for a variety of different uses such as drinking, sanitation, farming, and energy production continues to increase as the worldwide population grows. In the meantime, human activities and environmental change are influencing changes in water cycles, putting freshwater ecosystems under strain. Pollution, construction and mining activities pose additional challenges.
        `,
        question:"Which of the following are true statements about water?",
        mcq: [
            {
                index: 0,
                option: 'The demand for water has seen a steady decline over time.',
                isTrue: false
            },
            {
                index: 1,
                option: "Freshwater constitutes about 1% of the world's  water supply.",
                isTrue: false
            },
            {
                index: 2,
                option: 'Over two thirds of the surface of the earth is covered with water',
                isTrue: false
            },
            {
                index: 3,
                option: `Most of the Earth's water is solidified in the form of snow.`,
                isTrue: false
            },
            {
                index: 4,
                option: `Human survival does not depend on water.`,
                isTrue: false
            },

        ],
        type: 'reading',
        subType: "multiple_choice_multiple_answer",
        time: 10,
        difficulty: 4
    },
    {
        id: "rmcq_004",
        paragraph: `Water covers more than 66% of the surface of earth. Nearly all of Earth's water is found in seas and other saltwater bodies, while most of the remaining freshwater is solidified in the form of snow cover.

        Waterways, lakes, wetlands and aquifers that contain easily accessible freshwater represent less than 1% of the world's water supply. But this valuable resource supports an enormous diversity of life, and is vital for human survival.
        
        The demand for water for a variety of different uses such as drinking, sanitation, farming, and energy production continues to increase as the worldwide population grows. In the meantime, human activities and environmental change are influencing changes in water cycles, putting freshwater ecosystems under strain. Pollution, construction and mining activities pose additional challenges.
        `,
        question:"Which of the following are true statements about water?",
        mcq: [
            {
                index: 0,
                option: 'The demand for water has seen a steady decline over time.',
                isTrue: false
            },
            {
                index: 1,
                option: "Freshwater constitutes about 1% of the world's  water supply.",
                isTrue: false
            },
            {
                index: 2,
                option: 'Over two thirds of the surface of the earth is covered with water',
                isTrue: false
            },
            {
                index: 3,
                option: `Most of the Earth's water is solidified in the form of snow.`,
                isTrue: false
            },
            {
                index: 4,
                option: `Human survival does not depend on water.`,
                isTrue: false
            },

        ],
        type: 'reading',
        subType: "multiple_choice_multiple_answer",
        time: 10,
        difficulty: 4
    },
    {
        id: "rmcq_005",
        paragraph: `Water covers more than 66% of the surface of earth. Nearly all of Earth's water is found in seas and other saltwater bodies, while most of the remaining freshwater is solidified in the form of snow cover.

        Waterways, lakes, wetlands and aquifers that contain easily accessible freshwater represent less than 1% of the world's water supply. But this valuable resource supports an enormous diversity of life, and is vital for human survival.
        
        The demand for water for a variety of different uses such as drinking, sanitation, farming, and energy production continues to increase as the worldwide population grows. In the meantime, human activities and environmental change are influencing changes in water cycles, putting freshwater ecosystems under strain. Pollution, construction and mining activities pose additional challenges.
        `,
        question:"Which of the following are true statements about water?",
        mcq: [
            {
                index: 0,
                option: 'The demand for water has seen a steady decline over time.',
                isTrue: false
            },
            {
                index: 1,
                option: "Freshwater constitutes about 1% of the world's  water supply.",
                isTrue: false
            },
            {
                index: 2,
                option: 'Over two thirds of the surface of the earth is covered with water',
                isTrue: false
            },
            {
                index: 3,
                option: `Most of the Earth's water is solidified in the form of snow.`,
                isTrue: false
            },
            {
                index: 4,
                option: `Human survival does not depend on water.`,
                isTrue: false
            },

        ],
        type: 'reading',
        subType: "multiple_choice_multiple_answer",
        time: 10,
        difficulty: 4
    },
]