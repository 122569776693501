import { writeFromDictationQuestion } from "../listeningQuestions/writeFromDictationQuestion";

export const writeFromDictationTest = [
    {
        id: "lwfdt_001",
        type: 'listening',
        subType: "write_from_dictation",
        questions: [{ qId: "lwfdq_001", testIndex: 0 },
        { qId: "lwfdq_002", testIndex: 1 },
        { qId: "lwfdq_003", testIndex: 2 },
        { qId: "lwfdq_004", testIndex: 3 },
        { qId: "lwfdq_005", testIndex: 4 },
        { qId: "lwfdq_006", testIndex: 5 }],
        duration: writeFromDictationQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lwfdt_002",
        type: 'listening',
        subType: "write_from_dictation",
        questions: [{ qId: "lwfdq_001", testIndex: 0 },
        { qId: "lwfdq_002", testIndex: 1 },
        { qId: "lwfdq_003", testIndex: 2 },
        { qId: "lwfdq_004", testIndex: 3 },
        { qId: "lwfdq_005", testIndex: 4 },
        { qId: "lwfdq_006", testIndex: 5 }],
        duration: writeFromDictationQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0

    },
    {
        id: "lwfdt_003",
        type: 'listening',
        subType: "write_from_dictation",
        questions: [{ qId: "lwfdq_001", testIndex: 0 },
        { qId: "lwfdq_002", testIndex: 1 },
        { qId: "lwfdq_003", testIndex: 2 },
        { qId: "lwfdq_004", testIndex: 3 },
        { qId: "lwfdq_005", testIndex: 4 },
        { qId: "lwfdq_006", testIndex: 5 }],
        duration: writeFromDictationQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lwfdt_004",
        type: 'listening',
        subType: "write_from_dictation",
        questions: [{ qId: "lwfdq_001", testIndex: 0 },
        { qId: "lwfdq_002", testIndex: 1 },
        { qId: "lwfdq_003", testIndex: 2 },
        { qId: "lwfdq_004", testIndex: 3 },
        { qId: "lwfdq_005", testIndex: 4 },
        { qId: "lwfdq_006", testIndex: 5 }],
        duration: writeFromDictationQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lwfdt_005",
        type: 'listening',
        subType: "write_from_dictation",
        questions: [{ qId: "lwfdq_001", testIndex: 0 },
        { qId: "lwfdq_002", testIndex: 1 },
        { qId: "lwfdq_003", testIndex: 2 },
        { qId: "lwfdq_004", testIndex: 3 },
        { qId: "lwfdq_005", testIndex: 4 },
        { qId: "lwfdq_006", testIndex: 5 }],
        duration: writeFromDictationQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lwfdt_006",
        type: 'listening',
        subType: "write_from_dictation",
        questions: [{ qId: "lwfdq_001", testIndex: 0 },
        { qId: "lwfdq_002", testIndex: 1 },
        { qId: "lwfdq_003", testIndex: 2 },
        { qId: "lwfdq_004", testIndex: 3 },
        { qId: "lwfdq_005", testIndex: 4 },
        { qId: "lwfdq_006", testIndex: 5 }],
        duration: writeFromDictationQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
]