
import { useEffect } from 'react'
import AudioAnimation from '../../Components/AudioAnimation/AudioAnimation'
import './styles.scss'
const HighlightCorrectSummary = (props) => {
    const { question, option, setOption, setTestState, hearingTime, waitingTime, testState } = props

    const changeSelect = (e) => {
        setOption(prev => {
            if (prev) {
                if (prev.index == e.index) {
                    return null;
                } else {
                    return { ...e }
                }

            }
            else {
                return { ...e };

            }
        })

    }
    useEffect(() => {
        if (option && question) {
            setTestState("completed")
        }
        else {
            setTestState("stopped")
        }
    }, [option, question])
    return (
        <div className='hcm-container' >
            <div className="hcm-box">
                <div className="hcm-left">
                    <div className="audio-box">
                        {testState == "completed" ? null : (
                            <div className="status-time">
                                00:
                                {testState == "waiting"
                                    ? waitingTime
                                    : hearingTime}
                            </div>
                        )}
                        {testState == "completed" ?
                            <div className="completed-mark">
                                <i className="fas fa-check-circle"></i>
                            </div>
                            : (
                                <AudioAnimation
                                    color={testState == "waiting" ? "grey" : "grey"}
                                    animationState={
                                        testState == "waiting" ? "paused" : "running"
                                    }
                                />
                            )}{" "}
                        <div className="status-progress">
                            <div
                                className="status-progress-inner"
                                style={{
                                    width: `${((35 -
                                        Number(
                                            testState == "completed"
                                                ? 0
                                                : testState == "waiting"
                                                    ? waitingTime
                                                    : hearingTime
                                        )) /
                                        35) *
                                        100
                                        }%`,
                                }}
                            ></div>
                        </div>
                        <div className="status-heading">Current Status: <span className="status-text">
                            {testState}
                        </span>
                        </div>
                    </div>
                </div>
                <div className="hcm-right">
                    <div className="practice-header">You will hear an audio. Click on paragraph that best relates to the recording.
                    </div>


                    <div className="hcm-selection-container">
                        {
                            question && question.paragraphs.map((hdl, index) => {
                                return <div className="hdl-item"
                                    onClick={() => changeSelect(hdl)}
                                >
                                    <div className="hdl-circle">
                                        <span style={{
                                            display: "block", height: "30px",
                                            border: "1px solid #1366b0",
                                            width: "30px", borderRadius: "50%",
                                            background: option && option.index == hdl.index ? '#1366b0' : 'white',
                                            color: option && option.index == hdl.index ? '#fff' : '#000'
                                        }}></span>
                                    </div>
                                    <div className="practice-desc hdl-para" style={{ background: option && option.index == hdl.index ? '#1366b0' : 'transparent', color: option && option.index == hdl.index ? '#fff' : '#000' }}>{hdl.option}</div>
                                </div>
                            })
                        }

                    </div>
                </div>
            </div>

        </div >
    )
}

export default HighlightCorrectSummary