import React, { useEffect, useState } from "react";

export const LayoutContext = React.createContext();

const AdminLayoutProvider = ({ children, ...props }) => {
  const [loginModal, setLoginModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [mobileNav, setMobileNav] = useState(false);
  const [topSheetData, setTopSheetData] = useState(null);
  const [sliderCollapsed, setSliderCollapsed] = useState(false);
  const [examLink, setExamLink] = useState("");

  const [backgroundOverlay, setBackgroundOverlay] = useState(false);

  const [topSheet, setTopSheet] = useState(false);

  const [topSheetContent, setTopSheetContent] = useState(null);

  useEffect(() => {
    // fetch("http://gravitybackend.logindesigns.com/map-reviews")
    //   .then((values) => {
    //     return values.json();
    //   })
    //   .then((data) => {
    //     console.log("reviews ", data.data.result.reviews);
    //     setReviews(data.data.result.reviews);
    //   })
    //   .catch((err) => console.log("error fetching reviews: ", err));
  }, []);
  const providerValue = {
    sliderCollapsed,
    setSliderCollapsed,
    loginModal,
    setLoginModal,
    reviews,
    setReviews,
    mobileNav,
    setMobileNav,
    topSheetData,
    setTopSheetData,
    backgroundOverlay,
    setBackgroundOverlay,
    topSheet,
    setTopSheet,
    topSheetContent,
    setTopSheetContent,
    examLink,
    setExamLink,
  };
  return (
    <LayoutContext.Provider value={providerValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export default AdminLayoutProvider;
