import MobileAudio from '../../Audio/describeImage/mob-1.mp3'
import MobileImage from '../../Audio/describeImage/mob-1.webp'

import GraphAudio from '../../Audio/describeImage/graph.mp3'
import GraphImage from '../../Audio/describeImage/graph.webp'

import GlobalWarmingAudio from '../../Audio/describeImage/global-warming.mp3'
import GlobalWarmingImage from '../../Audio/describeImage/global-warming1.webp'

import InternetAudio from '../../Audio/describeImage/internet1.mp3'
import InternetImage from '../../Audio/describeImage/internet1.webp'

import WaterAudio from '../../Audio/describeImage/water1.mp3'
import WaterImage from '../../Audio/describeImage/water1.webp'

import TouristAudio from '../../Audio/describeImage/tourist1.mp3'
import TouristImage from '../../Audio/describeImage/tourist1.webp'

import FruitsAudio from '../../Audio/describeImage/fruits.mp3'
import FruitsImage from '../../Audio/describeImage/fruits.webp'

export const describeImageQuestions = [
    {
        id:"diq_001",
        audio: MobileAudio,
        image: MobileImage,
        questionText:"Look at the graph below. In 25 seconds please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"describe_image",
        time: 40,
        difficulty: 4,
        waitingTime: 25,
    },
    {
        id:"diq_002",
        audio: GraphAudio,
        image: GraphImage,
        questionText:"Look at the graph below. In 25 seconds please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"describe_image",
        time: 40,
        difficulty: 4,
        waitingTime: 25,
    },
    {
        id:"diq_003",
        audio: GlobalWarmingAudio,
        image: GlobalWarmingImage,
        questionText:"Look at the graph below. In 25 seconds please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"describe_image",
        time: 40,
        difficulty: 3,
        waitingTime: 25,
    },
    {
        id:"diq_004",
        audio: InternetAudio,
        image: InternetImage,
        questionText:"Look at the graph below. In 25 seconds please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"describe_image",
        time: 40,
        difficulty: 2,
        waitingTime: 25,
    },
    {
        id:"diq_005",
        audio: WaterAudio,
        image: WaterImage,
        questionText:"Look at the graph below. In 25 seconds please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"describe_image",
        time: 40,
        difficulty: 3,
        waitingTime: 25,
    },
    {
        id:"diq_006",
        audio: TouristAudio,
        image: TouristImage,
        questionText:"Look at the graph below. In 25 seconds please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"describe_image",
        time: 40,
        difficulty: 4,
        waitingTime: 25,
    },
    {
        id:"diq_007",
        audio: FruitsAudio,
        image: FruitsImage,
        questionText:"Look at the graph below. In 25 seconds please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.",
        type: 'speaking',
        subType:"describe_image",
        time: 40,
        difficulty: 3,
        waitingTime: 25,
    },
 
] 