import { chooseMultipleAnswerQuestion } from "../listeningQuestions/chooseMultipleAnswerQuestion";

export const chooseMultipleAnswerQuestionTest = [
    {
        id: "lcmat_001",
        type: 'listening',
        subType: "listening_choose_multiple_answer",
        questions: [{ qId: "lcmq_001", testIndex: 0 },
        { qId: "lcmq_002", testIndex: 1 },
        { qId: "lcmq_003", testIndex: 2 },
        { qId: "lcmq_004", testIndex: 3 },
        { qId: "lcmq_005", testIndex: 4 },
        { qId: "lcmq_006", testIndex: 5 }],
        duration: chooseMultipleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lcmat_002",
        type: 'listening',
        subType: "listening_choose_multiple_answer",
        questions: [{ qId: "lcmq_001", testIndex: 0 },
        { qId: "lcmq_002", testIndex: 1 },
        { qId: "lcmq_003", testIndex: 2 },
        { qId: "lcmq_004", testIndex: 3 },
        { qId: "lcmq_005", testIndex: 4 },
        { qId: "lcmq_006", testIndex: 5 }],
        duration: chooseMultipleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0

    },
    {
        id: "lcmat_003",
        type: 'listening',
        subType: "listening_choose_multiple_answer",
        questions: [{ qId: "lcmq_001", testIndex: 0 },
        { qId: "lcmq_002", testIndex: 1 },
        { qId: "lcmq_003", testIndex: 2 },
        { qId: "lcmq_004", testIndex: 3 },
        { qId: "lcmq_005", testIndex: 4 },
        { qId: "lcmq_006", testIndex: 5 }],
        duration: chooseMultipleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lcmat_004",
        type: 'listening',
        subType: "listening_choose_multiple_answer",
        questions: [{ qId: "lcmq_001", testIndex: 0 },
        { qId: "lcmq_002", testIndex: 1 },
        { qId: "lcmq_003", testIndex: 2 },
        { qId: "lcmq_004", testIndex: 3 },
        { qId: "lcmq_005", testIndex: 4 },
        { qId: "lcmq_006", testIndex: 5 }],
        duration: chooseMultipleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lcmat_005",
        type: 'listening',
        subType: "listening_choose_multiple_answer",
        questions: [{ qId: "lcmq_001", testIndex: 0 },
        { qId: "lcmq_002", testIndex: 1 },
        { qId: "lcmq_003", testIndex: 2 },
        { qId: "lcmq_004", testIndex: 3 },
        { qId: "lcmq_005", testIndex: 4 },
        { qId: "lcmq_006", testIndex: 5 }],
        duration: chooseMultipleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lcmat_006",
        type: 'listening',
        subType: "listening_choose_multiple_answer",
        questions: [{ qId: "lcmq_001", testIndex: 0 },
        { qId: "lcmq_002", testIndex: 1 },
        { qId: "lcmq_003", testIndex: 2 },
        { qId: "lcmq_004", testIndex: 3 },
        { qId: "lcmq_005", testIndex: 4 },
        { qId: "lcmq_006", testIndex: 5 }],
        duration: chooseMultipleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
]