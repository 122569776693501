
import { useEffect } from 'react'
import AudioAnimation from '../../Components/AudioAnimation/AudioAnimation'
import './styles.scss'
const SummarizeSpokenText = (props) => {
    const
        {
            testState,
            waitingTime,
            recordingTime,
            hearingTime,
            question,
            option,
            setOption,
            setTestState,
            questionState, setQuestionState
        } = props
    const onWriteUp = (e) => {
        setOption(e)
    }
    useEffect(() => {
        console.log("option", option)
        if (option) {

            setQuestionState("completed")
        }
        else {
            setQuestionState("notcomplete")
        }
    }, [option])
    return (
        <div className='summarize-spoken-text-container'>
            <div className="summarize-spoken-text-box">
                <div className="practice-header">
                    {question && question.questonText}
                </div>
                <div className="audio-box">
                    {testState == "completed" ? null : (
                        <div className="status-time">
                            00:
                            {testState == "waiting"
                                ? waitingTime
                                : hearingTime}
                        </div>
                    )}
                    {testState == "completed" ?
                        <div className="completed-mark">
                            <i className="fas fa-check-circle"></i>
                        </div>
                        : (
                            <AudioAnimation
                                color={testState == "waiting" ? "grey" : "grey"}
                                animationState={
                                    testState == "waiting" ? "paused" : "running"
                                }
                            />
                        )}{" "}
                    <div className="status-progress">
                        <div
                            className="status-progress-inner"
                            style={{
                                width: `${((35 -
                                    Number(
                                        testState == "completed"
                                            ? 0
                                            : testState == "waiting"
                                                ? waitingTime
                                                : hearingTime
                                    )) /
                                    35) *
                                    100
                                    }%`,
                            }}
                        ></div>
                    </div>
                    <div className="status-heading">Current Status: <span className="status-text">
                        {testState}
                    </span>
                    </div>
                </div>
                <div className="summarize-spoken-text-box">
                    <textarea onChange={(e) => onWriteUp(e.target.value)} className='practice-write-area' name="" id="" placeholder='write your answer here'></textarea>
                </div>
            </div>

        </div>
    )
}

export default SummarizeSpokenText