import React from "react";
import { useState, useEffect } from "react";
import "./styles.scss";
const MCQ = (props) => {

  const { question, option, setOption, setTestState } = props
  const changeSelect = (e) => {
    setOption(prev => {
      if (prev && prev.length > 0) {
        let myarr = prev;
        let modify = false
        let ss = prev.map((p, index) => {
          if (p && p.index == e.index) {
            modify = true
            myarr.splice(index, 1);
            return p
          }
          return p
        })
        if (!modify) {

          return [...ss, { ...e }]
        }
        else {
          return [...myarr]
        }
      }
      else {
        return [{ ...e }];

      }
    })

  }
  useEffect(() => {
    if (option && question && option.length > 0) {
      setTestState("completed")
    }
    else {
      setTestState("stopped")
    }
  }, [option, option?.length, question])

  return (
    <div className="MCQ-container">
      <div className="MCQ-box">
        <div className="MCQ-left practice-desc">
          {question && question.paragraph}
        </div>
        <div className="MCQ-right">
          <div className="practice-header">
            Read the text and answer the question by selecting all the correct
            responses. More than one response is correct.
          </div>
          <div className="MCQ-right-sub-heading">
            {question && question.question}
          </div>
          <div className="MCQ-check-boxes">
            <div className="choose-multiple-check-boxes">
              {question && question.mcq.map((vv, index) => {

                return (
                  <div
                    className="check-box-container"
                    onClick={() => {
                      changeSelect(vv)
                    }}
                  >
                    <div className="check-round">
                      {option && option.find(op => op.index == vv.index) && (
                        <div className="check-mark">
                          <i class="fa-solid fa-check"></i>
                        </div>
                      )}
                    </div>
                    <div className="ckeck-name">{vv.option}</div>
                  </div>
                );
              })}
            </div>
            {/* <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
            <label for="vehicle1"> I have a bike</label>
            <br />
            <input type="checkbox" id="vehicle2" name="vehicle2" value="Car" />
            <label for="vehicle2"> I have a car</label>
            <br />
            <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />
            <label for="vehicle3"> I have a boat</label>
            <br /> */}
          </div>
        </div>
      </div>

    </div>
  );
};

export default MCQ;
