import { useState } from "react";
import { useEffect } from "react";
import "./styles.scss";
import { DragDropContext } from "react-beautiful-dnd";
import MyDroppable from "./Droppable";
import MyDraggable from "./Draggable";
const ReorderParagraph = (props) => {
  const { setTestState, setOption, question } = props
  const [sortedParas, setSortedParas] = useState([])
  const [unsortedParas, setUnsortedParas] = useState([])
  useEffect(() => {
    if (question && question.paragraphs) {
      let cc = question.paragraphs.map((q, index) =>
        ({ data: q, sorted: false, orderedId: `draggable${index}` }))
      setUnsortedParas([...cc])
    }
  }, [question])
  useEffect(() => {
    if (question && sortedParas.length > 0 && sortedParas.length == question.paragraphs.length) {
      setOption(unsortedParas)
      setTestState("completed")
    }
    else {
      setOption([])
      setTestState("stopped")
    }
  }, [sortedParas.length])

  function handleDragEnd(event) {
    const { source, destination, draggableId } = event
    if (!destination) {
      return;
    }
    const srcIndex = source.index
    const destIndex = destination.index
    if (source.droppableId == destination.droppableId) {
      if (srcIndex == destIndex) {
        return;
      }
      if (source.droppableId == 'droppable0') {
        return setUnsortedParas(prev=>{
          let cc = [...prev]
          const [down] = cc.splice(srcIndex, 1)
          cc.splice(destIndex, 0, down)
          return[...cc]
        })
      }
      if (source.droppableId == 'droppable1') {
        let cc = [...sortedParas]
        const [down] = cc.splice(srcIndex, 1)
        cc.splice(destIndex, 0, down)
        return setSortedParas([...cc])
      }
    }
    if (destination.droppableId == 'droppable1') {
      let unsorts = [...unsortedParas]
      let sorts = [...sortedParas]
      const [down] = unsorts.splice(srcIndex, 1)
      if (sorts.length > 0) {
        sorts.splice(destIndex, 0, down)
        
      }
      else {
        sorts = [down]
      }
      setUnsortedParas([...unsorts])
      setSortedParas([...sorts])
    }
    if (destination.droppableId == 'droppable0') {
      let unsorts = [...unsortedParas]
      let sorts = [...sortedParas]

      const [down] = sorts.splice(srcIndex, 1)
      if (unsorts.length > 0) {
        unsorts.splice(destIndex, 0, down)
        
      }
      else {
        unsorts = [down]
      }
      setSortedParas([...sorts])
      setUnsortedParas([...unsorts])

    }
  }
  return (
    <DragDropContext onDragEnd={handleDragEnd} >
      <div className="reorder-paragraph-container">
        <div className="reorder-box">
          <div className="practice-header">
            The text boxes in the left panel have been placed in random order,
            Restore the original order by dragging the text boxes from left to the
            right panel
          </div>
          <div className="re-order-body">
            <div className="re-order-left">
              <div className="re-order-sub-heading">Source</div>

              <MyDroppable id={0}>
                {

                  unsortedParas.length > 0 ?
                    unsortedParas.map((pp, index) => {
                      return <MyDraggable id={index} paragraph={pp} status={'unsorted'} key={index} />
                    })
                    :
                    <div className="drop-text">Drop your paragraph here</div>
                }

              </MyDroppable>
            </div>
            <div className="re-order-right">
              <div className="re-order-sub-heading">Target</div>
              <MyDroppable id={1}>
                {

                  sortedParas.length > 0 ?
                    sortedParas.map((pp, index) => {
                      return <MyDraggable id={index} paragraph={pp} status={'sorted'} key={index} />
                    })
                    :
                    <div className="drop-text">Drop your paragraph here</div>
                }
              </MyDroppable>
            </div>
          </div>
        </div>
      </div>

    </DragDropContext>
  );
};

export default ReorderParagraph;
