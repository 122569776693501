import React, { useEffect, useState } from "react";
import {
  AppstoreOutlined,
  MinusSquareOutlined,
  MobileOutlined,
  GitlabOutlined,
  BorderlessTableOutlined,
  MailOutlined,
  IdcardOutlined,
  BorderBottomOutlined,
  InstagramOutlined,
  BookOutlined,
  InsertRowBelowOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import "./styles.css";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../Assets/Images/pte.png";
import { useMediaQuery } from "react-responsive";
const { Header, Sider, Content } = Layout;
const Slider = (props) => {
  const isTablet = useMediaQuery({
    query: "(max-width: 992px)",
  });

  const { collapsed, setCollapsed } = props;
  const { pathname } = useLocation();
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  useEffect(() => {
    if (pathname.split("/")[2] == "") {
      setSelectedMenu("dashboard");
    } else {
      setSelectedMenu(pathname.split("/")[2]);
    }
  }, [pathname]);
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem(
      <Link to="/admin">Dashboard</Link>,
      "dashboard",
      <AppstoreOutlined />
    ),
    // getItem("Landing Page", "landing-page", <InstagramOutlined />, [
    //   getItem(
    //     <Link to="/admin/landing-hero-texts">Hero Texts</Link>,
    //     "landing-hero-texts",
    //     <MinusSquareOutlined />
    //   ),
    //   getItem(
    //     <Link to="/admin/landing-hero-banner">Hero Banner</Link>,
    //     "landing-hero-banner",
    //     <AppstoreOutlined />
    //   ),
    //   getItem(
    //     <Link to="/admin/landing-about">Landing About</Link>,
    //     "landing-about",
    //     <AppstoreOutlined />
    //   ),
    // ]),
   
  ];
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={isTablet ? 0 : 100}
      className="sider-container"
    >
      <div className="sider-logo">
        {collapsed ? <img src={Logo} /> : <img src={Logo} alt="" />}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        className="sider-menu myScrollbar"
        onClick={(e) => {
          setSelectedMenu(e.key);
          if (isTablet) {
            setCollapsed(true);
          }
        }}
        defaultSelectedKeys={[selectedMenu]}
        selectedKeys={[selectedMenu]}
        items={items}
      />
    </Sider>
  );
};

export default Slider;
