

export const rnwFillInTheBlanksQuestion = [
    {
        id: "rnwfitbq_001",
        paragraph: `In the second century of the Christian era, the empire of Rome comprehended the fairest part of the earth and the most civilized portion of mankind. The frontiers of that extensive monarchy were guarded by ancient renown and disciplined valour. The gentle but powerful influence of laws and manners had gradually cemented the union of the provinces. Their peaceful inhabitants enjoyed and abused the advantages of wealth and luxury. The image of a free constitution was preserved with decent reverence: the Roman senate appeared to possess the sovereign authority and devolved on the emperors all the executive powers of government. During a happy period of more than fourscore years, the public administration was conducted by the virtue and abilities of Nerva, Trajan, Hadrian, and the two Antonines. It is the design of this, and of the two succeeding chapters, to describe the prosperous condition of their empire; and afterwards, from the death of Marcus Antoninus, to deduce the most important circumstances of its decline and fall; a revolution which will ever be remembered, and is still felt by the nations of the earth.`,
        blanks: [
            {
                index: 23,
                options: ['portion', 'ponder', 'pasted', 'plead'],
                answer: 'portion'
            },
            {
                index: 44,
                options: ['ignorance', 'imaginary', 'interior', 'influence'],
                answer: 'influence'
            },
            {
                index: 88,
                options: ['amazing', 'authority', 'arresting', 'analog'],
                answer: 'authority'
            },
            {
                index: 111,
                options: ['administration', 'across', 'adage', 'applications'],
                answer: 'administration'
            },
            {
                index: 138,
                options: ['comparing', 'chapters', 'coloring', 'cloudy'],
                answer: 'chapters'
            },
            {
                index: 160,
                options: ['celebratory', 'centrally', 'circumnavigated', 'circumstances'],
                answer: 'circumstances'
            }
        ],
        type: 'reading',
        subType: "reading_and_writing_fill_in_the_blanks",
        time: 10,
        difficulty: 4
    },
    {
        id: "rnwfitbq_002",
        paragraph: `In the second century of the Christian era, the empire of Rome comprehended the fairest 
        part of the earth and the most civilized portion of mankind. The frontiers of that extensive monarchy 
        were guarded by ancient renown and disciplined valour. The gentle but powerful influence of laws and 
        manners had gradually cemented the union of the provinces. Their peaceful inhabitants enjoyed and 
        abused the advantages of wealth and luxury. The image of a free constitution was preserved with 
        decent reverence: the Roman senate appeared to possess the sovereign authority and devolved on the 
        emperors all the executive powers of government. During a happy period of more than fourscore years, 
        the public administration was conducted by the virtue and abilities of Nerva, Trajan, Hadrian, and the 
        two Antonines. It is the design of this, and of the two succeeding chapters, to describe the prosperous 
        condition of their empire; and afterwards, from the death of Marcus Antoninus, to deduce the most 
        important circumstances of its decline and fall; a revolution which will ever be remembered, and is 
        still felt by the nations of the earth.
        `,
        blanks: [
            {
                index: 23,
                options: ['portion', 'ponder', 'pasted', 'plead'],
                answer: 'portion'
            },
            {
                index: 43,
                options: ['ignorance', 'imaginary', 'interior', 'influence'],
                answer: 'influence'
            },
            {
                index: 87,
                options: ['amazing', 'authority', 'arresting', 'analog'],
                answer: 'authority'
            },
            {
                index: 109,
                options: ['administration', 'across', 'adage', 'applications'],
                answer: 'administration'
            },
            {
                index: 136,
                options: ['comparing', 'chapters', 'coloring', 'cloudy'],
                answer: 'chapters'
            },
            {
                index: 158,
                options: ['celebratory', 'centrally', 'circumnavigated', 'circumstances'],
                answer: 'circumstances'
            }
        ],
        type: 'reading',
        subType: "reading_and_writing_fill_in_the_blanks",
        time: 10,
        difficulty: 4
    },
    {
        id: "rnwfitbq_003",
        paragraph: `In the second century of the Christian era, the empire of Rome comprehended the fairest 
        part of the earth and the most civilized portion of mankind. The frontiers of that extensive monarchy 
        were guarded by ancient renown and disciplined valour. The gentle but powerful influence of laws and 
        manners had gradually cemented the union of the provinces. Their peaceful inhabitants enjoyed and 
        abused the advantages of wealth and luxury. The image of a free constitution was preserved with 
        decent reverence: the Roman senate appeared to possess the sovereign authority and devolved on the 
        emperors all the executive powers of government. During a happy period of more than fourscore years, 
        the public administration was conducted by the virtue and abilities of Nerva, Trajan, Hadrian, and the 
        two Antonines. It is the design of this, and of the two succeeding chapters, to describe the prosperous 
        condition of their empire; and afterwards, from the death of Marcus Antoninus, to deduce the most 
        important circumstances of its decline and fall; a revolution which will ever be remembered, and is 
        still felt by the nations of the earth.
        `,
        blanks: [
            {
                index: 23,
                options: ['portion', 'ponder', 'pasted', 'plead'],
                answer: 'portion'
            },
            {
                index: 43,
                options: ['ignorance', 'imaginary', 'interior', 'influence'],
                answer: 'influence'
            },
            {
                index: 87,
                options: ['amazing', 'authority', 'arresting', 'analog'],
                answer: 'authority'
            },
            {
                index: 109,
                options: ['administration', 'across', 'adage', 'applications'],
                answer: 'administration'
            },
            {
                index: 136,
                options: ['comparing', 'chapters', 'coloring', 'cloudy'],
                answer: 'chapters'
            },
            {
                index: 158,
                options: ['celebratory', 'centrally', 'circumnavigated', 'circumstances'],
                answer: 'circumstances'
            }
        ],
        type: 'reading',
        subType: "reading_and_writing_fill_in_the_blanks",
        time: 10,
        difficulty: 3
    },
    {
        id: "rnwfitbq_004",
        paragraph: `In the second century of the Christian era, the empire of Rome comprehended the fairest 
        part of the earth and the most civilized portion of mankind. The frontiers of that extensive monarchy 
        were guarded by ancient renown and disciplined valour. The gentle but powerful influence of laws and 
        manners had gradually cemented the union of the provinces. Their peaceful inhabitants enjoyed and 
        abused the advantages of wealth and luxury. The image of a free constitution was preserved with 
        decent reverence: the Roman senate appeared to possess the sovereign authority and devolved on the 
        emperors all the executive powers of government. During a happy period of more than fourscore years, 
        the public administration was conducted by the virtue and abilities of Nerva, Trajan, Hadrian, and the 
        two Antonines. It is the design of this, and of the two succeeding chapters, to describe the prosperous 
        condition of their empire; and afterwards, from the death of Marcus Antoninus, to deduce the most 
        important circumstances of its decline and fall; a revolution which will ever be remembered, and is 
        still felt by the nations of the earth.
        `,
        blanks: [
            {
                index: 23,
                options: ['portion', 'ponder', 'pasted', 'plead'],
                answer: 'portion'
            },
            {
                index: 43,
                options: ['ignorance', 'imaginary', 'interior', 'influence'],
                answer: 'influence'
            },
            {
                index: 87,
                options: ['amazing', 'authority', 'arresting', 'analog'],
                answer: 'authority'
            },
            {
                index: 109,
                options: ['administration', 'across', 'adage', 'applications'],
                answer: 'administration'
            },
            {
                index: 136,
                options: ['comparing', 'chapters', 'coloring', 'cloudy'],
                answer: 'chapters'
            },
            {
                index: 158,
                options: ['celebratory', 'centrally', 'circumnavigated', 'circumstances'],
                answer: 'circumstances'
            }
        ],
        type: 'reading',
        subType: "reading_and_writing_fill_in_the_blanks",
        time: 10,
        difficulty: 2
    },
    {
        id: "rnwfitbq_005",
        paragraph: `In the second century of the Christian era, the empire of Rome comprehended the fairest 
        part of the earth and the most civilized portion of mankind. The frontiers of that extensive monarchy 
        were guarded by ancient renown and disciplined valour. The gentle but powerful influence of laws and 
        manners had gradually cemented the union of the provinces. Their peaceful inhabitants enjoyed and 
        abused the advantages of wealth and luxury. The image of a free constitution was preserved with 
        decent reverence: the Roman senate appeared to possess the sovereign authority and devolved on the 
        emperors all the executive powers of government. During a happy period of more than fourscore years, 
        the public administration was conducted by the virtue and abilities of Nerva, Trajan, Hadrian, and the 
        two Antonines. It is the design of this, and of the two succeeding chapters, to describe the prosperous 
        condition of their empire; and afterwards, from the death of Marcus Antoninus, to deduce the most 
        important circumstances of its decline and fall; a revolution which will ever be remembered, and is 
        still felt by the nations of the earth.
        `,
        blanks: [
            {
                index: 23,
                options: ['portion', 'ponder', 'pasted', 'plead'],
                answer: 'portion'
            },
            {
                index: 43,
                options: ['ignorance', 'imaginary', 'interior', 'influence'],
                answer: 'influence'
            },
            {
                index: 87,
                options: ['amazing', 'authority', 'arresting', 'analog'],
                answer: 'authority'
            },
            {
                index: 109,
                options: ['administration', 'across', 'adage', 'applications'],
                answer: 'administration'
            },
            {
                index: 136,
                options: ['comparing', 'chapters', 'coloring', 'cloudy'],
                answer: 'chapters'
            },
            {
                index: 158,
                options: ['celebratory', 'centrally', 'circumnavigated', 'circumstances'],
                answer: 'circumstances'
            }
        ],
        type: 'reading',
        subType: "reading_and_writing_fill_in_the_blanks",
        time: 10,
        difficulty: 3
    },

]