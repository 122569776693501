import { highlightCorrectParagraphQuestion } from "../listeningQuestions/highlightCorrectParagraphQuestion";

export const highlightCorrectParagraphTest = [
    {
        id: "hcpt_001",
        type: 'listening',
        subType: "listening_highlight_correct_paragraph",
        questions: [{ qId: "lhcpq_001", testIndex: 0 },
        { qId: "lhcpq_002", testIndex: 1 },
        { qId: "lhcpq_003", testIndex: 2 },
        { qId: "lhcpq_004", testIndex: 3 },
        { qId: "lhcpq_005", testIndex: 4 },
        { qId: "lhcpq_006", testIndex: 5 }],
        duration: highlightCorrectParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "hcpt_002",
        type: 'listening',
        subType: "listening_highlight_correct_paragraph",
        questions: [{ qId: "lhcpq_001", testIndex: 0 },
        { qId: "lhcpq_002", testIndex: 1 },
        { qId: "lhcpq_003", testIndex: 2 },
        { qId: "lhcpq_004", testIndex: 3 },
        { qId: "lhcpq_005", testIndex: 4 },
        { qId: "lhcpq_006", testIndex: 5 }],
        duration: highlightCorrectParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0

    },
    {
        id: "hcpt_003",
        type: 'listening',
        subType: "listening_highlight_correct_paragraph",
        questions: [{ qId: "lhcpq_001", testIndex: 0 },
        { qId: "lhcpq_002", testIndex: 1 },
        { qId: "lhcpq_003", testIndex: 2 },
        { qId: "lhcpq_004", testIndex: 3 },
        { qId: "lhcpq_005", testIndex: 4 },
        { qId: "lhcpq_006", testIndex: 5 }],
        duration: highlightCorrectParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "hcpt_004",
        type: 'listening',
        subType: "listening_highlight_correct_paragraph",
        questions: [{ qId: "lhcpq_001", testIndex: 0 },
        { qId: "lhcpq_002", testIndex: 1 },
        { qId: "lhcpq_003", testIndex: 2 },
        { qId: "lhcpq_004", testIndex: 3 },
        { qId: "lhcpq_005", testIndex: 4 },
        { qId: "lhcpq_006", testIndex: 5 }],
        duration: highlightCorrectParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "hcpt_005",
        type: 'listening',
        subType: "listening_highlight_correct_paragraph",
        questions: [{ qId: "lhcpq_001", testIndex: 0 },
        { qId: "lhcpq_002", testIndex: 1 },
        { qId: "lhcpq_003", testIndex: 2 },
        { qId: "lhcpq_004", testIndex: 3 },
        { qId: "lhcpq_005", testIndex: 4 },
        { qId: "lhcpq_006", testIndex: 5 }],
        duration: highlightCorrectParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "hcpt_006",
        type: 'listening',
        subType: "listening_highlight_correct_paragraph",
        questions: [{ qId: "lhcpq_001", testIndex: 0 },
        { qId: "lhcpq_002", testIndex: 1 },
        { qId: "lhcpq_003", testIndex: 2 },
        { qId: "lhcpq_004", testIndex: 3 },
        { qId: "lhcpq_005", testIndex: 4 },
        { qId: "lhcpq_006", testIndex: 5 }],
        duration: highlightCorrectParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
]