import MobileAudio from '../../Audio/describeImage/mob-1.mp3'


export const listeningChooseSingleAnswerQuestion = [
    {
        id: "lcsaq_001",
        audio: MobileAudio,
        question: "What is the writer's main point about renewable energy pricing?",
        mcq: [
            {
                index: 0,
                option: 'Electric cooperatives plan to increase the energy pricing.',
                isTrue: false
            },
            {
                index: 1,
                option: "Coal capacity is being increased to meet rising consumer demand.",
                isTrue: false
            },
            {
                index: 2,
                option: 'The prices for new wind and solar projects are higher than the operating costs of existing coal assets.',
                isTrue: false
            },
            {
                index: 3,
                option: `The decrease in renewable energy pricing can benefit consumers.`,
                isTrue: true
            },

        ],
        type: 'listening',
        subType: "listening_choose_single_answer",
        time: 10,
        waitingTime:5,
        difficulty: 4
    },
    {
        id: "lcsaq_002",
        audio: MobileAudio,
        question: "What is the writer's main point about renewable energy pricing?",
        mcq: [
            {
                index: 0,
                option: 'Electric cooperatives plan to increase the energy pricing.',
                isTrue: false
            },
            {
                index: 1,
                option: "Coal capacity is being increased to meet rising consumer demand.",
                isTrue: false
            },
            {
                index: 2,
                option: 'The prices for new wind and solar projects are higher than the operating costs of existing coal assets.',
                isTrue: false
            },
            {
                index: 3,
                option: `The decrease in renewable energy pricing can benefit consumers.`,
                isTrue: true
            },

        ],
        type: 'listening',
        subType: "listening_choose_single_answer",
        time: 10,
        waitingTime:5,
        difficulty: 4
    },
    {
        id: "lcsaq_003",
        audio: MobileAudio,
        question: "What is the writer's main point about renewable energy pricing?",
        mcq: [
            {
                index: 0,
                option: 'Electric cooperatives plan to increase the energy pricing.',
                isTrue: false
            },
            {
                index: 1,
                option: "Coal capacity is being increased to meet rising consumer demand.",
                isTrue: false
            },
            {
                index: 2,
                option: 'The prices for new wind and solar projects are higher than the operating costs of existing coal assets.',
                isTrue: false
            },
            {
                index: 3,
                option: `The decrease in renewable energy pricing can benefit consumers.`,
                isTrue: true
            },

        ],
        type: 'listening',
        subType: "listening_choose_single_answer",
        time: 10,
        waitingTime:5,
        difficulty: 4
    },
    {
        id: "lcsaq_004",
        audio: MobileAudio,
        question: "What is the writer's main point about renewable energy pricing?",
        mcq: [
            {
                index: 0,
                option: 'Electric cooperatives plan to increase the energy pricing.',
                isTrue: false
            },
            {
                index: 1,
                option: "Coal capacity is being increased to meet rising consumer demand.",
                isTrue: false
            },
            {
                index: 2,
                option: 'The prices for new wind and solar projects are higher than the operating costs of existing coal assets.',
                isTrue: false
            },
            {
                index: 3,
                option: `The decrease in renewable energy pricing can benefit consumers.`,
                isTrue: true
            },

        ],
        type: 'listening',
        subType: "listening_choose_single_answer",
        time: 10,
        waitingTime:5,
        difficulty: 4
    },
    {
        id: "lcsaq_005",
        audio: MobileAudio,
        question: "What is the writer's main point about renewable energy pricing?",
        mcq: [
            {
                index: 0,
                option: 'Electric cooperatives plan to increase the energy pricing.',
                isTrue: false
            },
            {
                index: 1,
                option: "Coal capacity is being increased to meet rising consumer demand.",
                isTrue: false
            },
            {
                index: 2,
                option: 'The prices for new wind and solar projects are higher than the operating costs of existing coal assets.',
                isTrue: false
            },
            {
                index: 3,
                option: `The decrease in renewable energy pricing can benefit consumers.`,
                isTrue: true
            },

        ],
        type: 'listening',
        subType: "listening_choose_single_answer",
        time: 10,
        waitingTime:5,
        difficulty: 4
    },
]