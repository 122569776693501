import { listeningChooseSingleAnswerQuestion } from "../listeningQuestions/chooseSingleAnswerQuestion";

export const listeningChooseSingleAnswerTest = [
    {
        id: "lcsat_001",
        type: 'listening',
        subType: "listening_choose_single_answer",
        questions: [{ qId: "lcsaq_001", testIndex: 0 },
        { qId: "lcsaq_002", testIndex: 1 },
        { qId: "lcsaq_003", testIndex: 2 },
        { qId: "lcsaq_004", testIndex: 3 },
        { qId: "lcsaq_005", testIndex: 4 },
        { qId: "lcsaq_006", testIndex: 5 }],
        duration: listeningChooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lcsat_002",
        type: 'listening',
        subType: "listening_choose_single_answer",
        questions: [{ qId: "lcsaq_001", testIndex: 0 },
        { qId: "lcsaq_002", testIndex: 1 },
        { qId: "lcsaq_003", testIndex: 2 },
        { qId: "lcsaq_004", testIndex: 3 },
        { qId: "lcsaq_005", testIndex: 4 },
        { qId: "lcsaq_006", testIndex: 5 }],
        duration: listeningChooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0

    },
    {
        id: "lcsat_003",
        type: 'listening',
        subType: "listening_choose_single_answer",
        questions: [{ qId: "lcsaq_001", testIndex: 0 },
        { qId: "lcsaq_002", testIndex: 1 },
        { qId: "lcsaq_003", testIndex: 2 },
        { qId: "lcsaq_004", testIndex: 3 },
        { qId: "lcsaq_005", testIndex: 4 },
        { qId: "lcsaq_006", testIndex: 5 }],
        duration: listeningChooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lcsat_004",
        type: 'listening',
        subType: "listening_choose_single_answer",
        questions: [{ qId: "lcsaq_001", testIndex: 0 },
        { qId: "lcsaq_002", testIndex: 1 },
        { qId: "lcsaq_003", testIndex: 2 },
        { qId: "lcsaq_004", testIndex: 3 },
        { qId: "lcsaq_005", testIndex: 4 },
        { qId: "lcsaq_006", testIndex: 5 }],
        duration: listeningChooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lcsat_005",
        type: 'listening',
        subType: "listening_choose_single_answer",
        questions: [{ qId: "lcsaq_001", testIndex: 0 },
        { qId: "lcsaq_002", testIndex: 1 },
        { qId: "lcsaq_003", testIndex: 2 },
        { qId: "lcsaq_004", testIndex: 3 },
        { qId: "lcsaq_005", testIndex: 4 },
        { qId: "lcsaq_006", testIndex: 5 }],
        duration: listeningChooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lcsat_006",
        type: 'listening',
        subType: "listening_choose_single_answer",
        questions: [{ qId: "lcsaq_001", testIndex: 0 },
        { qId: "lcsaq_002", testIndex: 1 },
        { qId: "lcsaq_003", testIndex: 2 },
        { qId: "lcsaq_004", testIndex: 3 },
        { qId: "lcsaq_005", testIndex: 4 },
        { qId: "lcsaq_006", testIndex: 5 }],
        duration: listeningChooseSingleAnswerQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
]