import "./styles.scss";
import AudioAnimation from "../../Components/AudioAnimation/AudioAnimation";


type Props = {
    question?: any
}
const ReadAloud = (props) => {

    return (
        <div className="read-aloud-container">

            <div className="read-aloud-body">
                <div className="read-aloud-box">
                    <div className="read-aloud-heading practice-header">
                        Look at the text below. In 35 seconds, you must read this text aloud
                        as naturally and clearly as possible. You have 40 seconds to read
                        aloud.
                    </div>
                    <div className="reacrding-box audio-box">
                        {props.testState == "completed" ? null : (
                            <div className="status-time">
                                00:
                                {props.testState == "waiting"
                                    ? props.waitingTime
                                    : props.recordingTime}
                            </div>
                        )}
                        {props.testState == "completed" ?
                            <div className="completed-mark">
                                <i className="fas fa-check-circle"></i>
                            </div>
                            : (
                                <AudioAnimation
                                    color={props.testState == "waiting" ? "grey" : "blue"}
                                    animationState={
                                        props.testState == "waiting" ? "paused" : "running"
                                    }
                                />
                            )}{" "}
                        <div className="status-progress">
                            <div
                                className="status-progress-inner"
                                style={{
                                    width: `${((35 -
                                        Number(
                                            props.testState == "completed"
                                                ? 0
                                                : props.testState == "waiting"
                                                    ? props.waitingTime
                                                    : props.recordingTime
                                        )) /
                                        35) *
                                        100
                                        }%`,
                                }}
                            ></div>
                        </div>
                        <div className="status-heading">Current Status: <span className="status-text">
                            {props.testState}
                        </span>
                        </div>
                    </div>
                    <div className="read-aloud-statement practice-desc">

                        {props.question && props.question.readingText}
                    </div>
                </div>
            </div>


        </div>
    );
};

export default ReadAloud;
