import { mcQuestion } from "../readingQuestions/mcQuestion";
import { reOrderParagraphQuestion } from "../readingQuestions/reOrderParagraphQuestion";

export const reOrderParagraphTest = [
    {
        id: "ropt_001",
        type: 'reading',
        subType: "reorder_paragraph",
        questions: [{ qId: "ropq_001", testIndex: 0 },
        { qId: "ropq_002", testIndex: 1 },
        { qId: "ropq_003", testIndex: 2 },
        { qId: "ropq_004", testIndex: 3 },
        { qId: "ropq_005", testIndex: 4 },
        { qId: "ropq_006", testIndex: 5 }],
        duration: reOrderParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "ropt_002",
        type: 'reading',
        subType: "reorder_paragraph",
        questions: [{ qId: "ropq_001", testIndex: 0 },
        { qId: "ropq_002", testIndex: 1 },
        { qId: "ropq_003", testIndex: 2 },
        { qId: "ropq_004", testIndex: 3 },
        { qId: "ropq_005", testIndex: 4 },
        { qId: "ropq_006", testIndex: 5 }],
        duration: reOrderParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0

    },
    {
        id: "ropt_003",
        type: 'reading',
        subType: "reorder_paragraph",
        questions: [{ qId: "ropq_001", testIndex: 0 },
        { qId: "ropq_002", testIndex: 1 },
        { qId: "ropq_003", testIndex: 2 },
        { qId: "ropq_004", testIndex: 3 },
        { qId: "ropq_005", testIndex: 4 },
        { qId: "ropq_006", testIndex: 5 }],
        duration: reOrderParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "ropt_004",
        type: 'reading',
        subType: "reorder_paragraph",
        questions: [{ qId: "ropq_001", testIndex: 0 },
        { qId: "ropq_002", testIndex: 1 },
        { qId: "ropq_003", testIndex: 2 },
        { qId: "ropq_004", testIndex: 3 },
        { qId: "ropq_005", testIndex: 4 },
        { qId: "ropq_006", testIndex: 5 }],
        duration: reOrderParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "ropt_005",
        type: 'reading',
        subType: "reorder_paragraph",
        questions: [{ qId: "ropq_001", testIndex: 0 },
        { qId: "ropq_002", testIndex: 1 },
        { qId: "ropq_003", testIndex: 2 },
        { qId: "ropq_004", testIndex: 3 },
        { qId: "ropq_005", testIndex: 4 },
        { qId: "ropq_006", testIndex: 5 }],
        duration: reOrderParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "ropt_006",
        type: 'reading',
         subType: "reorder_paragraph",
        questions: [{ qId: "ropq_001", testIndex: 0 },
        { qId: "ropq_002", testIndex: 1 },
        { qId: "ropq_003", testIndex: 2 },
        { qId: "ropq_004", testIndex: 3 },
        { qId: "ropq_005", testIndex: 4 },
        { qId: "ropq_006", testIndex: 5 }],
        duration: reOrderParagraphQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
]