import { useContext } from "react";
import TestBox from "../../../ProtectedComponents/TestBox/TestBox";
import "./styles.scss";
import { AuthContext } from "../../../Context/AuthContext/AuthContext";
const ListeningFillInTheBlanksPage = () => {
  const { tests, setTests } = useContext(AuthContext)

  return (
    <div className="test-boxes-container">
      {tests.filter(td => td.type == "listening" && td.subType == "listening_fill_in_the_blanks").map((rd, index) => {
        return <TestBox key={index} testData={rd} link='/practice/pte-listening/fill-in-the-blanks-test' />;
      })}
    </div>
  );
};

export default ListeningFillInTheBlanksPage;
