
import { useEffect } from 'react'
import AudioAnimation from '../../Components/AudioAnimation/AudioAnimation'
import './styles.scss'
const ListeningMultipleAnswers = (props) => {
    const { question, option, setOption, setTestState, testState, waitingTime, hearingTime } = props
    const changeSelect = (e) => {
        setOption(prev => {
            if (prev && prev.length > 0) {
                let myarr = prev;
                let modify = false
                let ss = prev.map((p, index) => {
                    if (p && p.index == e.index) {
                        modify = true
                        myarr.splice(index, 1);
                        return p
                    }
                    return p
                })
                if (!modify) {

                    return [...ss, { ...e }]
                }
                else {
                    return [...myarr]
                }
            }
            else {
                return [{ ...e }];

            }
        })

    }
    useEffect(() => {

        if (option && question && option.length > 0) {
            setTestState("completed")
        }
        else {
            setTestState("stopped")
        }
    }, [option, option?.length, question])
    return (
        <div className='listening-multiple-answers-container' >
            <div className="listening-multiple-answers-box">
                <div className="lma-left">
                    <div className="audio-box">
                        {testState == "completed" ? null : (
                            <div className="status-time">
                                00:
                                {testState == "waiting"
                                    ? waitingTime
                                    : hearingTime}
                            </div>
                        )}
                        {testState == "completed" ?
                            <div className="completed-mark">
                                <i className="fas fa-check-circle"></i>
                            </div>
                            : (
                                <AudioAnimation
                                    color={testState == "waiting" ? "grey" : "grey"}
                                    animationState={
                                        testState == "waiting" ? "paused" : "running"
                                    }
                                />
                            )}{" "}
                        <div className="status-progress">
                            <div
                                className="status-progress-inner"
                                style={{
                                    width: `${((35 -
                                        Number(
                                            testState == "completed"
                                                ? 0
                                                : testState == "waiting"
                                                    ? waitingTime
                                                    : hearingTime
                                        )) /
                                        35) *
                                        100
                                        }%`,
                                }}
                            ></div>
                        </div>
                        <div className="status-heading">Current Status: <span className="status-text">
                            {testState}
                        </span>
                        </div>
                    </div>
                </div>
                <div className="lma-right">
                    <div className="practice-header">Listen to the recording and answer the question by selecting al the correct responses.
                        You will need to select more than one response.
                    </div>
                    <div className="lma-question">
                        {question && question.question}
                    </div>

                    <div className="lma-selection-container">
                        <div className="choose-multiple-check-boxes">
                            {question && question.mcq.map((vv, index) => {

                                return (
                                    <div
                                        className="check-box-container"
                                        onClick={() => {
                                            changeSelect(vv)
                                        }}
                                    >
                                        <div className="check-round">
                                            {option && option.find(op => op.index == vv.index) && (
                                                <div className="check-mark">
                                                    <i className="fa-solid fa-check"></i>
                                                </div>
                                            )}
                                        </div>
                                        <div className="ckeck-name">{vv.option}</div>
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default ListeningMultipleAnswers