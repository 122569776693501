import MobileAudio from '../../Audio/describeImage/mob-1.mp3'
import GraphAudio from '../../Audio/describeImage/graph.mp3'
import GlobalWarmingAudio from '../../Audio/describeImage/global-warming.mp3'
import InternetAudio from '../../Audio/describeImage/internet1.mp3'
import WaterAudio from '../../Audio/describeImage/water1.mp3'
import TouristAudio from '../../Audio/describeImage/tourist1.mp3'
import FruitsAudio from '../../Audio/describeImage/fruits.mp3'

export const writeFromDictationQuestion = [
    {
        id: "lwfdq_001",
        audio: MobileAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "write_from_dictation",
        time: 15,
        difficulty: 4,
        waitingTime: 5,
    },
    {
        id: "lwfdq_002",
        audio: GraphAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "write_from_dictation",
        time: 20,
        difficulty: 4,
        waitingTime: 5,
    },
    {
        id: "lwfdq_003",
        audio: GlobalWarmingAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "write_from_dictation",
        time: 10,
        difficulty: 3,
        waitingTime: 5,
    },
    {
        id: "lwfdq_004",
        audio: InternetAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "write_from_dictation",
        time: 20,
        difficulty: 2,
        waitingTime: 5,
    },
    {
        id: "lwfdq_005",
        audio: WaterAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "write_from_dictation",
        time: 14,
        difficulty: 3,
        waitingTime: 5,
    },
    {
        id: "lwfdq_006",
        audio: TouristAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "write_from_dictation",
        time: 10,
        difficulty: 4,
        waitingTime: 5,
    },
    {
        id: "lwfdq_007",
        audio: FruitsAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "write_from_dictation",
        time: 10,
        difficulty: 3,
        waitingTime: 5,
    },

] 