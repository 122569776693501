
import MobileAudio from '../../Audio/describeImage/mob-1.mp3'

export const chooseMultipleAnswerQuestion = [
    {
        id: "lcmq_001",
        audio: MobileAudio,
        mcq: [
            {
                index: 0,
                option: 'The demand for water has seen a steady decline over time.',
                isTrue: false
            },
            {
                index: 1,
                option: "Freshwater constitutes about 1% of the world's  water supply.",
                isTrue: false
            },
            {
                index: 2,
                option: 'Over two thirds of the surface of the earth is covered with water',
                isTrue: false
            },
            {
                index: 3,
                option: `Most of the Earth's water is solidified in the form of snow.`,
                isTrue: false
            },
            {
                index: 4,
                option: `Human survival does not depend on water.`,
                isTrue: false
            },

        ],
        type: 'listening',
        subType: "listening_choose_multiple_answer",
        time: 10,
        waitingTime: 5,
        difficulty: 4
    },
    {
        id: "lcmq_002",
        audio: MobileAudio,
        question: "Which of the following are true statements about water?",
        mcq: [
            {
                index: 0,
                option: 'The demand for water has seen a steady decline over time.',
                isTrue: false
            },
            {
                index: 1,
                option: "Freshwater constitutes about 1% of the world's  water supply.",
                isTrue: false
            },
            {
                index: 2,
                option: 'Over two thirds of the surface of the earth is covered with water',
                isTrue: false
            },
            {
                index: 3,
                option: `Most of the Earth's water is solidified in the form of snow.`,
                isTrue: false
            },
            {
                index: 4,
                option: `Human survival does not depend on water.`,
                isTrue: false
            },

        ],
        type: 'listening',
        subType: "listening_choose_multiple_answer",
        time: 10,
        waitingTime: 5,
        difficulty: 4
    },
    {
        id: "lcmq_003",
        audio: MobileAudio,
        question: "Which of the following are true statements about water?",
        mcq: [
            {
                index: 0,
                option: 'The demand for water has seen a steady decline over time.',
                isTrue: false
            },
            {
                index: 1,
                option: "Freshwater constitutes about 1% of the world's  water supply.",
                isTrue: false
            },
            {
                index: 2,
                option: 'Over two thirds of the surface of the earth is covered with water',
                isTrue: false
            },
            {
                index: 3,
                option: `Most of the Earth's water is solidified in the form of snow.`,
                isTrue: false
            },
            {
                index: 4,
                option: `Human survival does not depend on water.`,
                isTrue: false
            },

        ],
        type: 'listening',
        subType: "listening_choose_multiple_answer",
        time: 10,
        waitingTime: 5,
        difficulty: 4
    },
    {
        id: "lcmq_004",
        audio: MobileAudio,
        question: "Which of the following are true statements about water?",
        mcq: [
            {
                index: 0,
                option: 'The demand for water has seen a steady decline over time.',
                isTrue: false
            },
            {
                index: 1,
                option: "Freshwater constitutes about 1% of the world's  water supply.",
                isTrue: false
            },
            {
                index: 2,
                option: 'Over two thirds of the surface of the earth is covered with water',
                isTrue: false
            },
            {
                index: 3,
                option: `Most of the Earth's water is solidified in the form of snow.`,
                isTrue: false
            },
            {
                index: 4,
                option: `Human survival does not depend on water.`,
                isTrue: false
            },

        ],
        type: 'listening',
        subType: "listening_choose_multiple_answer",
        time: 10,
        waitingTime: 5,
        difficulty: 4
    },
    {
        id: "lcmq_005",
        audio: MobileAudio,
        question: "Which of the following are true statements about water?",
        mcq: [
            {
                index: 0,
                option: 'The demand for water has seen a steady decline over time.',
                isTrue: false
            },
            {
                index: 1,
                option: "Freshwater constitutes about 1% of the world's  water supply.",
                isTrue: false
            },
            {
                index: 2,
                option: 'Over two thirds of the surface of the earth is covered with water',
                isTrue: false
            },
            {
                index: 3,
                option: `Most of the Earth's water is solidified in the form of snow.`,
                isTrue: false
            },
            {
                index: 4,
                option: `Human survival does not depend on water.`,
                isTrue: false
            },

        ],
        type: 'listening',
        subType: "listening_choose_multiple_answer",
        time: 10,
        waitingTime: 5,
        difficulty: 4
    },
]