import React, { useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import "./styles.scss";
import { AuthContext } from "../../../Context/AuthContext/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
const Headers = (props) => {
  const { collapsed, setCollapsed } = props;
  const navigate = useNavigate();
  const { authUser, setAuthUser, jobs, contact, setLogout } =
    useContext(AuthContext);
  const [notificationCount, setNotificationCount] = useState(0);
  const notificationMenu = (
    <Menu
      className="notificationDropdown"
      onClick={(e) => {
        navigate(e.key);
      }}
    >
      <Menu.Item key="contact-data">
        <div className="notify-icon">
          <i class="fa-solid fa-comment"></i>{" "}
        </div>{" "}
        You have{" "}
        {contact.reduce(
          (total, sal) => (sal.seen == "false" ? total + 1 : total + 0),
          0
        )}{" "}
        contact requests{" "}
      </Menu.Item>
      <Menu.Item key="warranty-data">
        <div className="notify-icon">
          <i class="fa-solid fa-comment"></i>{" "}
        </div>{" "}
        You have{" "}
        {jobs.reduce(
          (total, sal) => (sal.seen == "false" ? total + 1 : total + 0),
          0
        )}{" "}
        jobs requests{" "}
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    let count =
      contact.reduce(
        (total, sal) => (sal.seen == "false" ? total + 1 : total + 0),
        0
      ) +
      jobs.reduce(
        (total, sal) => (sal.seen == "false" ? total + 1 : total + 0),
        0
      );
    setNotificationCount(count);
  }, [jobs, contact]);
  return (
    <div className="my-layout-header">
      <div className="my-header-left">
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          }
        )}
      </div>
      <div className="my-header-right">
        {/* <div className="header-icon">
          <i class="fa-solid fa-house"></i>
        </div> */}
        <div className="header-icon">
          <Dropdown
            style={{ width: "100px" }}
            className="notification-dropdown"
            overlay={notificationMenu}
            trigger={["click"]}
            placement="bottomRight"
          >
            <div
              style={{
                position: "relative",
                fontSize: "18px",
                color: notificationCount > 0 ? "red" : "grey",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "-10px",
                  right: "-20px",
                  fontSize: "18px",
                  height: "25px",
                  width: "25px",
                  borderRadius: "50%",
                  background: "red",
                  color: "white",
                  fontSize: "14px",
                  display: notificationCount > 0 ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {notificationCount}
              </div>
              <i class="fa-solid fa-bell"></i>
            </div>
          </Dropdown>
        </div>
        <div className="header-icon" onClick={()=> setLogout()}>
          <i class="fa-solid fa-power-off"></i>
        </div>
      </div>
    </div>
  );
};

export default Headers;
