import { fillInTheBlanksQuestion } from "../readingQuestions/fillInTheBlanksQuestion";

export const fillInTheBlanksTest = [
    {
        id: "rfitbt_001",
        type: 'reading',
        subType: "fill_in_the_blanks",
        questions: [{ qId: "fitbq_001", testIndex: 0 },
        { qId: "fitbq_002", testIndex: 1 },
        { qId: "fitbq_003", testIndex: 2 },
        { qId: "fitbq_004", testIndex: 3 },
        { qId: "fitbq_005", testIndex: 4 },
        { qId: "fitbq_006", testIndex: 5 }],
        duration: fillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rfitbt_002",
        type: 'reading',
        subType: "fill_in_the_blanks",
        questions: [{ qId: "fitbq_001", testIndex: 0 },
        { qId: "fitbq_002", testIndex: 1 },
        { qId: "fitbq_003", testIndex: 2 },
        { qId: "fitbq_004", testIndex: 3 },
        { qId: "fitbq_005", testIndex: 4 },
        { qId: "fitbq_006", testIndex: 5 }],
        duration: fillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0

    },
    {
        id: "rfitbt_003",
        type: 'reading',
        subType: "fill_in_the_blanks",
        questions: [{ qId: "fitbq_001", testIndex: 0 },
        { qId: "fitbq_002", testIndex: 1 },
        { qId: "fitbq_003", testIndex: 2 },
        { qId: "fitbq_004", testIndex: 3 },
        { qId: "fitbq_005", testIndex: 4 },
        { qId: "fitbq_006", testIndex: 5 },
        ],
        duration: fillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rfitbt_004",
        type: 'reading',
        subType: "fill_in_the_blanks",
        questions: [{ qId: "fitbq_001", testIndex: 0 },
        { qId: "fitbq_002", testIndex: 1 },
        { qId: "fitbq_003", testIndex: 2 },
        { qId: "fitbq_004", testIndex: 3 },
        { qId: "fitbq_005", testIndex: 4 },
        { qId: "fitbq_006", testIndex: 5 }],
        duration: fillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rfitbt_005",
        type: 'reading',
        subType: "fill_in_the_blanks",
        questions: [{ qId: "fitbq_001", testIndex: 0 },
        { qId: "fitbq_002", testIndex: 1 },
        { qId: "fitbq_003", testIndex: 2 },
        { qId: "fitbq_004", testIndex: 3 },
        { qId: "fitbq_005", testIndex: 4 },
        { qId: "fitbq_006", testIndex: 5 }
        ],
        duration: fillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rfitbt_006",
        type: 'reading',
        subType: "fill_in_the_blanks",
        questions: [{ qId: "fitbq_001", testIndex: 0 },
        { qId: "fitbq_002", testIndex: 1 },
        { qId: "fitbq_003", testIndex: 2 },
        { qId: "fitbq_004", testIndex: 3 },
        { qId: "fitbq_005", testIndex: 4 },
        { qId: "fitbq_006", testIndex: 5 }],
        duration: fillInTheBlanksQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
]