import { useDraggable } from '@dnd-kit/core';
type Props = {
  text: string,
  id: number,
  status: string
}
const Draggable = ({ text, id, status }: Props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `draggable-${status}-${id}`,
    data: {
      text:text
    }
  });
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;
  return (
  
    <p className="best-word" ref={setNodeRef} style={style} {...listeners} {...attributes}
      >
      {text}
    </p>
  )
}

export default Draggable