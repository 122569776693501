import { Droppable } from "react-beautiful-dnd";


const MyDroppable = ({ children, id }) => {
 
  const getListStyle = isDraggingOver => ({
    borderStyle: isDraggingOver ? "dashed" : "solid",
    borderColor: isDraggingOver ? "red" : "initial",

  });

  return (
    <Droppable droppableId={`droppable${id}`} index={id}>
      {(provided, snapshot) => (

        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}

          className="managed-boxes empty-box">
          {children}

          {provided.placeholder}
        </div>

      )}
    </Droppable>

  )
}

export default MyDroppable