import React, { useEffect, useRef, useState } from "react";
import { logout } from "../../utils/api-calls/authCalls";
import CryptoJS from "crypto-js";
import { testProcessData } from "../../Assets/Data/process";
import { testsData } from "../../Assets/Data/tests";
import initializeIndexDb from "../../utils/indexDb";
export const AuthContext = React.createContext();

const AuthProvider = ({ children, ...props }) => {
  const [authUser, setAuthUser] = useState(null);
  const indexDB = useRef()
  const [authStatus, setAuthStatus] = useState("checking");

  const [heroText, setHeroText] = useState(null);
  const [heroBanner, setHeroBanner] = useState([]);
  const [landingAbout, setLandingAbout] = useState(null);
  const [aboutHeader, setAboutHeader] = useState(null);
  const [aboutInfo, setAboutInfo] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [jobsHeader, setJobsHeader] = useState(null);
  const [jobsTexts, setJobsTexts] = useState(null);

  const [contact, setContact] = useState([]);
  const [booking, setBooking] = useState([]);
  const [team, setTeam] = useState([]);

  const [contactHeader, setContactHeader] = useState(null);

  const [serviceHeader, setServiceHeader] = useState(null);
  const [serviceTexts, setServiceTexts] = useState(null);

  const [service, setService] = useState([]);
  const [treatment, setTreatment] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [causes, setCauses] = useState([]);
  const [diseases, setDiseases] = useState([]);
  const [whatIs, setWhatIs] = useState([]);
  const [pricing, setPricing] = useState([]);
  const [pricingList, setPricingList] = useState([]);

  const [priceHeader, setPriceHeader] = useState(null);

  const [acessToken, setAcessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [footerLinks, setFooterLinks] = useState(null);
  const [footerAbout, setFooterAbout] = useState(null);
  const [footerContact, setFooterContact] = useState(null);
  const [activeUsers, setActiveUsers] = useState([]);
  const [sessionDuration, setSessionDuration] = useState([]);
  const [bounceRate, setBounceRate] = useState([]);
  const [sessionPerUser, setSessionPerUser] = useState([]);
  const [trafficSource, setTrafficSource] = useState([]);
  const [sessionsByDevice, setSessionsByDevice] = useState([]);
  const [countryBasedUser, setCountryBasedUser] = useState([]);
  const [pageViews, setPageViews] = useState([]);
  const [secret, setSecret] = useState("alohomora");
  const [testProcess, setTestProcess] = useState([...testProcessData]);
  const [tests, setTests] = useState([...testsData]);

  const setLogout = () => {
    logout();
    setAcessToken(null);
    setRefreshToken(null);
    setAuthUser(null);
  };
  useEffect(()=>{
    let cc = initializeIndexDb()
    indexDB.current =cc
  },[])

  const providerValue = {
    testProcess,
    setTestProcess,
    pricing,
    setPricing,
    pricingList,
    setPricingList,
    serviceTexts,
    setServiceTexts,
    team,
    setTeam,
    booking,
    setBooking,
    diseases,
    setDiseases,
    treatment,
    setTreatment,
    symptoms,
    setSymptoms,
    causes,
    setCauses,
    whatIs,
    setWhatIs,
    priceHeader,
    setPriceHeader,
    serviceHeader,
    setServiceHeader,
    service,
    setService,
    contactHeader,
    setContactHeader,
    jobsHeader,
    setJobsHeader,
    jobsTexts,
    setJobsTexts,
    aboutInfo,
    setAboutInfo,
    aboutHeader,
    setAboutHeader,
    heroText,
    setHeroText,
    heroBanner,
    setHeroBanner,
    landingAbout,
    setLandingAbout,
    jobs,
    setJobs,
    authUser,
    setAuthUser,
    contact,
    setContact,
    footerLinks,
    setFooterLinks,
    footerAbout,
    setFooterAbout,
    footerContact,
    setFooterContact,
    acessToken,
    setAcessToken,
    refreshToken,
    setRefreshToken,
    activeUsers,
    setActiveUsers,
    sessionDuration,
    setSessionDuration,
    bounceRate,
    setBounceRate,
    sessionPerUser,
    setSessionPerUser,
    trafficSource,
    setTrafficSource,
    sessionsByDevice,
    setSessionsByDevice,
    countryBasedUser,
    setCountryBasedUser,
    pageViews,
    setPageViews,
    setLogout,
    secret,
    setSecret,
    authStatus,
    setAuthStatus,
    tests,
    setTests,
    indexDB
  };
  console.log("process", testProcess)
  useEffect(() => {
    let data = localStorage.getItem("epiAuth");
    if (
      data &&
      CryptoJS.AES.decrypt(data, secret) &&
      CryptoJS.AES.decrypt(data, secret).toString(CryptoJS.enc.Utf8)
    ) {
      const decryptUser = CryptoJS.AES.decrypt(data, secret);
      const originalText = decryptUser.toString(CryptoJS.enc.Utf8);
      setAuthUser(JSON.parse(originalText));
      setAuthStatus("checked");
    } else {
      setAuthStatus("checked");

      setLogout();
    }
  }, []);
  useEffect(() => {
    const newToken = localStorage.getItem("epiToken");
    if (
      newToken &&
      CryptoJS.AES.decrypt(newToken, secret) &&
      CryptoJS.AES.decrypt(newToken, secret).toString(CryptoJS.enc.Utf8)
    ) {
      const decryptAccess = CryptoJS.AES.decrypt(newToken, secret);
      const originalText = decryptAccess.toString(CryptoJS.enc.Utf8);
      setAcessToken(JSON.parse(originalText));
    } else {
      setLogout();
    }
  }, []);
  useEffect(() => {
    const refreshToken = localStorage.getItem("epiRefreshToken");
    if (
      refreshToken &&
      CryptoJS.AES.decrypt(refreshToken, secret) &&
      CryptoJS.AES.decrypt(refreshToken, secret).toString(CryptoJS.enc.Utf8)
    ) {
      const decryptRefresh = CryptoJS.AES.decrypt(refreshToken, secret);
      const originalText = decryptRefresh.toString(CryptoJS.enc.Utf8);
      setRefreshToken(JSON.parse(originalText));
    } else {
      setLogout();
    }
  }, []);
  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
