import React from "react";
import "./styles.scss";
const LandingProvides = () => {
  const provideData = [
    {
      name: "Reading",
      detail: "Contains 90% exam vocabs",
      icon: (
        <svg
          width="55"
          height="55"
          viewBox="0 0 55 55"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4_42)">
            <path
              d="M54.4946 35.4228C54.4958 35.6211 54.4578 35.8177 54.3826 36.0013C54.3075 36.1848 54.1969 36.3517 54.057 36.4923C53.9171 36.6328 53.7508 36.7444 53.5676 36.8204C53.3844 36.8964 53.188 36.9355 52.9897 36.9352L45.4502 36.9549C45.2525 36.9552 45.0566 36.9167 44.8737 36.8413C44.6909 36.766 44.5247 36.6553 44.3847 36.5157C44.2446 36.3761 44.1335 36.2102 44.0576 36.0276C43.9817 35.845 43.9425 35.6492 43.9423 35.4515C43.9411 35.2535 43.979 35.0572 44.0538 34.8738C44.1286 34.6905 44.2389 34.5237 44.3783 34.3831C44.5177 34.2425 44.6834 34.1307 44.8661 34.0542C45.0487 33.9778 45.2447 33.9381 45.4427 33.9375L52.9822 33.9179C53.1803 33.9173 53.3767 33.9558 53.5599 34.0311C53.7432 34.1064 53.9098 34.2172 54.0503 34.3569C54.1908 34.4967 54.3023 34.6628 54.3785 34.8457C54.4548 35.0285 54.4942 35.2247 54.4946 35.4228ZM52.1016 27.1459C52.1867 27.3249 52.2356 27.5189 52.2457 27.7169C52.2557 27.9148 52.2266 28.1127 52.1601 28.2994C52.0936 28.4861 51.9909 28.6578 51.858 28.8048C51.725 28.9518 51.5644 29.0711 51.3853 29.156L44.5741 32.3904C44.2124 32.5616 43.7975 32.5821 43.4207 32.4473C43.0439 32.3126 42.736 32.0336 42.5648 31.6719C42.3937 31.3102 42.3732 30.8953 42.508 30.5185C42.6427 30.1416 42.9216 29.8338 43.2834 29.6626L50.093 26.4297C50.4544 26.2583 50.869 26.2375 51.2456 26.3718C51.6223 26.5062 51.9302 26.7846 52.1016 27.1459ZM52.1423 43.7133C52.3122 43.3513 52.3314 42.9367 52.1956 42.5607C52.0599 42.1846 51.7804 41.8778 51.4185 41.7078L44.5922 38.508C44.412 38.4158 44.2151 38.3609 44.0132 38.3464C43.8113 38.332 43.6085 38.3583 43.417 38.4239C43.2255 38.4895 43.0492 38.593 42.8985 38.7282C42.7479 38.8633 42.626 39.0275 42.5401 39.2108C42.4542 39.3941 42.4061 39.5928 42.3987 39.795C42.3913 39.9973 42.4246 40.199 42.4968 40.3881C42.569 40.5773 42.6785 40.7499 42.8188 40.8958C42.9591 41.0417 43.1273 41.1578 43.3135 41.2373L50.1383 44.4386C50.5003 44.6079 50.9146 44.6266 51.2904 44.4906C51.6661 44.3546 51.9725 44.075 52.1423 43.7133Z"
              fill="#FA743E"
            />
            <path
              d="M42.644 22.9314C40.5028 22.2995 38.5832 20.5986 37.9152 19.5386C36.7692 17.717 38.152 17.2451 37.6845 12.1664C37.2548 7.51756 30.8537 2.51735 23.0729 2.00919C19.5354 1.77848 15.8561 2.50076 12.0863 4.56207C2.40559 9.85632 -1.43052 21.0465 6.04567 31.8536C12.786 41.5947 6.46185 45.9963 6.46185 45.9963C6.46185 45.9963 6.71216 48.047 10.8559 50.8035C15.0011 53.5584 20.0858 51.6901 20.0858 51.6901C20.0858 51.6901 21.7626 45.7279 27.2453 46.2767C31.1749 46.8558 36.6938 46.0822 38.0886 44.7326C39.393 43.4705 37.861 41.8887 38.9014 38.3633C35.3352 38.0134 33.3749 36.314 33.1864 33.9179C37.1447 34.3025 38.7778 33.0464 39.9087 31.6636C39.877 30.8494 39.7956 29.1213 39.7172 27.9632C43.2185 26.9499 44.5606 23.4968 42.644 22.9314Z"
              fill="#226699"
            />
          </g>
          <defs>
            <clipPath id="clip0_4_42">
              <rect
                width="54.2846"
                height="54.2846"
                fill="white"
                transform="translate(0.454346 0.501282)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      name: "Writing",
      detail: "Improve RA in 14 days",
      icon: (
        <svg
          width="49"
          height="49"
          viewBox="0 0 49 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4_45)">
            <path
              d="M8.84955 29.6181C8.74605 30.8415 9.2755 31.2078 10.1049 31.789C10.9249 32.3636 15.9209 27.9846 17.5544 27.3185C19.1879 26.6523 34.5222 23.4053 33.8574 20.4409C33.1926 17.4764 28.5588 17.0385 24.9893 17.6436C21.4198 18.2487 14.5886 21.3883 12.5929 23.0828C10.5972 24.776 8.84955 29.6181 8.84955 29.6181ZM20.2906 31.6045C20.2906 31.6045 15.5706 33.1372 15.5706 37.118C15.5706 41.0989 16.7197 43.3441 17.2638 44.4335C17.8092 45.5216 19.5037 46.5779 21.8484 45.5628C24.5713 44.3831 21.1969 37.5957 29.8101 40.7114C33.7565 42.1379 37.8502 44.066 41.831 42.739C45.6076 41.4797 47.0619 36.7292 47.0619 34.0753C47.0619 28.6428 43.081 30.0945 41.7541 31.4214C40.4271 32.7484 29.8115 35.4023 28.4845 35.4023C27.1576 35.4023 20.2906 31.6045 20.2906 31.6045Z"
              fill="#D2A077"
            />
            <path
              d="M26.2446 38.3057C26.2446 38.3057 26.0854 36.5594 24.4201 36.3564C23.052 36.1905 20.8957 36.647 19.7757 38.8856C18.6558 41.1255 16.6653 40.7513 16.7489 42.617C16.8312 44.4827 17.4124 45.9344 18.6146 46.3072C19.8182 46.6814 21.518 46.018 21.725 44.0275C21.932 42.0371 22.1815 40.2125 23.466 39.4243C24.7531 38.6374 26.2446 38.3057 26.2446 38.3057Z"
              fill="#E2C196"
            />
            <path
              d="M24.5037 35.4023C24.5037 35.4023 23.1767 34.0753 20.5228 34.0753C17.8689 34.0753 11.6799 36.9057 13.5563 42.5347C14.8832 46.5155 18.6571 44.6498 19.1958 40.7101C19.7359 36.7651 24.5037 35.4023 24.5037 35.4023Z"
              fill="#D2A077"
            />
            <path
              d="M23.1767 35.4023C23.1767 35.4023 21.8497 34.0753 19.1958 34.0753C16.5419 34.0753 12.7136 36.1056 12.561 42.0371C12.4363 46.8884 17.91 44.691 17.8689 40.7101C17.8277 36.7292 23.1767 35.4023 23.1767 35.4023Z"
              fill="#E2C196"
            />
            <path
              d="M7.25322 36.7292C7.25322 41.1042 7.85964 43.7435 10.4751 43.2937C14.8129 42.5479 13.1661 37.1711 16.9626 35.6584C19.7186 34.561 23.7314 34.8224 27.6047 37.122C30.1565 38.6374 28.4845 32.7484 25.8306 31.4214C23.1767 30.0945 15.215 31.4214 13.888 31.4214C12.561 31.4214 7.25322 36.7292 7.25322 36.7292Z"
              fill="#D2A077"
            />
            <path
              d="M7.04356 36.9602C4.93237 40.4965 6.21687 44.1523 9.90713 43.364C13.478 42.6024 11.6892 37.9633 15.215 35.4023C17.1908 33.9665 21.449 33.4849 24.1029 34.8118C26.7568 36.1388 27.1576 32.7484 24.5036 31.4214C21.8497 30.0945 13.1714 31.3883 11.8471 31.4705C9.31398 31.6284 7.04356 36.9602 7.04356 36.9602Z"
              fill="#E2C196"
            />
            <path
              d="M13.1157 39.5344C12.9015 39.7801 12.641 39.9812 12.349 40.1261C12.0571 40.2711 11.7395 40.3571 11.4143 40.3793C11.0891 40.4015 10.7627 40.3594 10.4538 40.2554C10.1449 40.1514 9.85945 39.9876 9.61387 39.7733L9.35378 39.5464C9.10815 39.3323 8.90711 39.0719 8.76216 38.78C8.61721 38.4882 8.53119 38.1707 8.509 37.8456C8.48682 37.5205 8.52892 37.1943 8.63289 36.8854C8.73685 36.5766 8.90066 36.2913 9.11493 36.0459L39.002 1.78784C39.2162 1.54217 39.4767 1.3411 39.7686 1.19613C40.0606 1.05116 40.3782 0.965128 40.7034 0.942947C41.0286 0.920766 41.355 0.962872 41.6639 1.06686C41.9728 1.17084 42.2582 1.33467 42.5038 1.54899L42.7626 1.7759C43.2585 2.2083 43.5624 2.81998 43.6075 3.47642C43.6525 4.13287 43.435 4.78032 43.0027 5.27641L13.1157 39.5344Z"
              fill="#3B88C3"
            />
            <path
              d="M14.5382 37.9049C11.4066 41.4943 3.50723 48.5325 2.5067 47.6607C1.5075 46.7889 7.40715 38.0071 10.5388 34.4163C13.6704 30.8256 12.7428 33.6971 13.8469 34.6618C14.9509 35.6252 17.6698 34.3168 14.5382 37.9049Z"
              fill="#3B88C3"
            />
            <path
              d="M9.47189 34.1098C9.47189 35.4368 12.6845 36.651 14.0114 35.324C15.3384 33.997 18.4448 32.3675 22.5291 34.0753C32.0885 38.0695 28.4845 32.7484 24.5037 31.4214C20.5228 30.0945 13.888 31.4214 12.561 31.4214C11.2341 31.4214 9.47189 34.1098 9.47189 34.1098Z"
              fill="#D2A077"
            />
            <path
              d="M8.83894 30.1515C8.36389 28.6746 11.7423 20.4926 13.2723 19.2254C15.199 17.6304 22.6525 15.1609 25.3065 15.1609C27.9604 15.1609 47.0619 24.7866 47.0619 31.4214C47.0619 38.0562 44.408 38.8855 40.593 39.9219C37.5423 40.7512 31.1517 40.243 28.3598 37.6409C25.9129 35.3611 24.213 33.7847 21.8497 32.7484C17.4681 30.8269 14.5475 33.311 13.2205 34.6379C10.5666 37.2919 5.56666 34.427 9.73728 29.8874C12.7216 26.6377 15.3503 25.6253 17.8689 24.7866C25.8306 22.1327 31.1384 22.1327 28.4845 19.4788C27.5464 18.5406 27.0355 19.9379 25.7298 20.1755C23.1024 20.6532 21.0111 20.9743 17.5491 22.1287C16.3522 22.5268 10.6861 26.1401 8.83894 30.1515Z"
              fill="#F3D2A2"
            />
            <path
              d="M18.129 22.4976C19.5408 21.9828 25.6953 20.8628 28.8972 19.7535C31.4834 18.8565 33.7525 20.5974 28.8441 22.4445C24.1069 24.2266 19.1852 24.0807 15.5958 25.5058C14.2383 26.0446 13.0613 24.3447 18.129 22.4976Z"
              fill="#D2A077"
            />
            <path
              d="M30.4577 11.5476C30.4577 11.5476 22.5915 20.5643 19.2502 24.3673C22.2412 23.1386 24.9177 23.3721 27.4747 21.8673C30.0317 20.3639 31.1981 18.7596 35.0782 14.3103C36.5192 12.6596 30.4577 11.5476 30.4577 11.5476Z"
              fill="#3B88C3"
            />
          </g>
          <defs>
            <clipPath id="clip0_4_45">
              <rect
                width="47.7704"
                height="47.7704"
                fill="white"
                transform="translate(0.618439 0.901428)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
    {
      name: "Listening",
      detail: "Accurate score report analysis",
      icon: (
        <svg
          width="44"
          height="54"
          viewBox="0 0 44 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.4522 8.04022L18.5273 11.4446C21.8788 12.0055 24.9844 13.5605 27.4414 15.9079C29.8984 18.2553 31.5934 21.2868 32.3064 24.6093L35.8096 24.2142C35.0164 20.1902 33.0331 16.4971 30.1167 13.6133C27.2002 10.7295 23.485 8.78798 19.4522 8.04022Z"
            fill="#1366B0"
          />
          <path
            d="M35.3567 8.53686C31.5384 4.69871 26.6725 2.07249 21.3684 0.987122L20.4438 4.38979C30.152 6.29936 37.7955 14.0335 39.5688 23.7892L43.0697 23.3941C42.105 17.7605 39.4091 12.5675 35.3567 8.53686ZM14.6197 14.9914C14.5044 14.9899 14.3893 14.9899 14.2744 14.9914C10.6848 15.0246 7.25339 16.4738 4.72673 19.0239C2.20007 21.5739 0.782552 25.0185 0.782532 28.6084H4.3204C4.32041 25.9475 5.36977 23.394 7.24075 21.502C9.11173 19.61 11.6534 18.5322 14.3141 18.5024C14.3909 18.5018 14.4677 18.5018 14.5445 18.5024C20.0021 18.5781 24.4711 23.0812 24.5066 28.5403C24.5213 29.9221 24.2498 31.2919 23.7093 32.5636C23.1687 33.8353 22.3708 34.9813 21.3657 35.9296C21.1089 36.1755 20.9045 36.4707 20.7648 36.7976C20.6251 37.1245 20.5529 37.4763 20.5527 37.8318V43.3944C20.5509 45.0364 19.8979 46.6108 18.7369 47.772C17.5759 48.9332 16.0017 49.5864 14.3596 49.5884H13.4118V53.1003H14.3852C16.9583 53.0974 19.4252 52.0739 21.2447 50.2545C23.0641 48.435 24.0876 45.9681 24.0904 43.3949V38.202C26.6395 35.6255 28.0616 32.1424 28.0442 28.518C28.0109 24.9585 26.589 21.5526 24.0814 19.026C21.5739 16.4994 18.1789 15.0518 14.6197 14.9914Z"
            fill="#1366B0"
          />
          <path
            d="M20.1108 28.6083C20.1141 27.8564 19.9688 27.1112 19.6834 26.4155C19.3979 25.7198 18.9778 25.0874 18.4473 24.5545C17.9167 24.0216 17.2861 23.5988 16.5917 23.3103C15.8973 23.0218 15.1528 22.8732 14.4008 22.8732C13.6488 22.8732 12.9043 23.0218 12.2099 23.3103C11.5155 23.5988 10.8849 24.0216 10.3543 24.5545C9.82378 25.0874 9.40371 25.7198 9.11823 26.4155C8.83275 27.1112 8.68749 27.8564 8.69079 28.6083H12.2026C12.2006 28.3184 12.2559 28.0308 12.3655 27.7624C12.475 27.4939 12.6366 27.2497 12.841 27.0439C13.0453 26.8382 13.2883 26.6749 13.556 26.5634C13.8237 26.452 14.1108 26.3946 14.4008 26.3946C14.6908 26.3946 14.9779 26.452 15.2456 26.5634C15.5133 26.6749 15.7563 26.8382 15.9606 27.0439C16.165 27.2497 16.3266 27.4939 16.4361 27.7624C16.5457 28.0308 16.601 28.3184 16.599 28.6083H20.1108ZM12.7424 32.0291H16.2542V35.541H12.7424V32.0291ZM1.32883 43.4427H4.84069V46.9545H1.32883V43.4427ZM2.60736 36.0185L5.09047 33.5353L14.7481 43.1929L12.265 45.6761L2.60736 36.0185Z"
            fill="#1366B0"
          />
        </svg>
      ),
    },
    {
      name: "Speaking",
      detail: "View more study tools",
      icon: (
        <svg
          width="51"
          height="44"
          viewBox="0 0 51 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.6278 7.2643C21.052 7.2643 23.3769 8.22731 25.0911 9.94148C26.8052 11.6557 27.7682 13.9806 27.7682 16.4048C27.7682 18.829 26.8052 21.1539 25.0911 22.8681C23.3769 24.5822 21.052 25.5453 18.6278 25.5453C16.2036 25.5453 13.8786 24.5822 12.1645 22.8681C10.4503 21.1539 9.48728 18.829 9.48728 16.4048C9.48728 13.9806 10.4503 11.6557 12.1645 9.94148C13.8786 8.22731 16.2036 7.2643 18.6278 7.2643ZM18.6278 30.1155C24.729 30.1155 36.9087 33.1776 36.9087 39.256V43.8262H0.346802V39.256C0.346802 33.1776 12.5265 30.1155 18.6278 30.1155ZM36.3603 8.08694C40.9762 13.1142 40.9762 20.0838 36.3603 24.6998L32.5213 20.8379C34.4408 18.1415 34.4408 14.6452 32.5213 11.9488L36.3603 8.08694ZM43.924 0.408936C52.9046 9.66367 52.836 23.5115 43.924 32.4006L40.1993 28.6759C46.5291 21.4092 46.5291 11.0347 40.1993 4.13368L43.924 0.408936Z"
            fill="#1366B0"
          />
        </svg>
      ),
    },

    {
      name: "Mock Tests",
      detail: "View more study tools",
      icon: <i class="fa-solid fa-laptop-code"></i>,
    },
    {
      name: "Real Exam Questions",
      detail: "View more study tools",
      icon: <i class="fa-solid fa-book-open"></i>,
    },
    {
      name: "User-friendly interface",
      detail: "View more study tools",
      icon: <i class="fa-solid fa-gear"></i>,
    },
    {
      name: "AI Scoring",
      detail: "View more study tools",
      icon: <i class="fa-solid fa-chart-simple"></i>,
    },
  ];

  return (
    <div className="landing-provides-container">
      <div className="container">
        <h2 className=" section-header landing-provides-header">
          WE FOCUSED ON
        </h2>
        <div className=" secion-subheader landing-provides-sub-header">
          Dream PTE provides you
        </div>
        <div className="landing-provides">
          {provideData.map((st, index) => {
            return (
              <div
                className="st-box"
                key={index}
                data-aos="zoom-in"
                aos-duration={index * 10 + 5000}
              >
                <div className="st-icon">{st.icon}</div>
                <div className="st-info">
                  <div className="roboto-bold st-title">{st.name}</div>
                  <div className="roboto-light st-plus">
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.67832 20.4944V11.9252H0.109131V9.06882H8.67832V0.499634H11.5347V9.06882H20.1039V11.9252H11.5347V20.4944H8.67832Z"
                        fill="#1366B0"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LandingProvides;
