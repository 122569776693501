import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import './styles.scss'
import { RecordState } from "audio-react-recorder";
import { useRecorderPermission } from '../../Components/RecordPermission/RecordPermission';
import { useSearchParams } from 'react-router-dom';
import { allQuestions } from '../../Assets/Data/questions';
import { invokeSaveAsDialog } from "recordrtc";

type Props = {
    children: ReactElement,
    noHearing?: boolean,
    noRecord?: boolean,
    justHearing?: boolean
}

const TestContainer = (props: Props) => {
    const waitingInterval = useRef<any>(null);
    const recorder = useRecorderPermission("audio");
    const recordingInterval = useRef<any>(null);
    const hearingInterval = useRef<any>(null)
    const [recordState, setRecordState] = useState(null);
    const [testState, setTestState] = useState("stopped");
    const [waitingTime, setWaitingTime] = useState<Number>(0);
    const [recordingTime, setRecordingTime] = useState<Number>(0);
    const [hearingTime, setHearingTime] = useState<Number>(5)
    const [searchParams, setSearchParams] = useSearchParams()
    const [myProcess, setMyProcess] = useState<any>(null)
    const [testIndex, setTestIndex] = useState<any>(0)
    const [testData, setTestData] = useState<any>(null)
    const [myQuestion, setMyQuestion] = useState<any>(null)
    const [file, setFile] = useState<any>()
    const [option, setOption] = useState<any>([])
    const [goForward, setGoForward] = useState(false)
    const [questionState, setQuestionState] = useState('notcompleted')

    useEffect(() => {
        setTestIndex(Number(searchParams.get("index")) + 1)
    }, [searchParams])
    useEffect(() => {
        let dd = localStorage.getItem('testData')
        if (dd) {
            let cc = JSON.parse(dd)
            setTestData(cc)
        }
        console.log("FILE", file)
    }, [])
    useEffect(() => {
        if (testData && testIndex) {
            let qq = testData.questions.find(tq => tq.testIndex + 1 == testIndex)
            if (qq) {
                let myq = allQuestions.find(aq => aq.id == qq.qId)
                setMyQuestion(myq)
            }
            else {
                setMyQuestion(null)
            }
        }

    }, [testData, testIndex])
    useEffect(() => {
        if (testData) {
            let testp = localStorage.getItem('testProcess')
            let testd;
            let process;
            if (testp) {
                testd = JSON.parse(testp)
                process = testd.find((tp) => tp.test == testData.id)
            }
            else {
                testd = []
                process = null;
            }
            if (process) {
                setMyProcess(process)
            }
            else {
                let pc = {
                    id: `tpc_00${Math.random()}${testd.length + 1}`,
                    user: "usr_001",
                    test: testData.id,
                    started: true,
                    completed: false,
                    active: true,
                    currentIndex: 0,
                    totalIndex: testData.questions.length,
                    savedState: false,
                    savedIndexTimer: 0,
                    totalTimer: 30,
                    answered: [

                    ]
                }
                setMyProcess({ ...pc })
                localStorage.setItem("testProcess", JSON.stringify([...testd, pc]))
            }

        }
    }, [testData])
    const start = () => {
        setRecordState(RecordState.START);
    };

    const stop = () => {
        setRecordState(RecordState.STOP);
    };

    //audioData contains blob and blobUrl
    const onStop = (audioData: any) => {
        console.log("audioData", audioData);
    };

    const setWaitingTimer = (e: Number) => {
        setWaitingTime(e);
    };
    const setRecordingTimer = (e: Number) => {
        setRecordingTime(e);
    };
    const setHearingTimer = (e: Number) => {
        setHearingTime(e)
    }
    const startRecordingInterval = () => {
        let count = myQuestion.time;

        recordingInterval.current = setInterval(async () => {
            if (count == 0) {
                await recorder.stopRecording();
                let blob = await recorder.getBlob();
                let blobUrl = URL.createObjectURL(blob)
                setFile({ blobUrl: blobUrl, blob: blob })
                setTestState("completed");
                clearInterval(recordingInterval.current);
                if (testIndex == testData?.questions?.length) {
                    let testp = localStorage.getItem('testProcess')
                    if (testp) {
                        let testd = JSON.parse(testp)
                        let cc = testd.map(pr => {
                            if (pr.id == myProcess.id) {
                                return {
                                    ...pr,
                                    started: true,
                                    completed: true,
                                    active: false,
                                }
                            }
                            else {
                                return { ...pr }
                            }
                        })
                        localStorage.setItem("testProcess", JSON.stringify(cc))
                    }
                    window.close()
                }

                invokeSaveAsDialog(blob, `random_name.webm`);
            }
            count = count - 1;
            setRecordingTimer(count);
        }, 1000);
    };
    const startHearingInterval = () => {
        let count = myQuestion.time
        let cc = new Audio(myQuestion.audio)
        cc.play()

        hearingInterval.current = setInterval(async () => {
            if (count == 0) {
                if (!props.justHearing) {
                    setTestState('recording')
                    clearInterval(hearingInterval.current)
                    startRecordingInterval()
                    await recorder.startRecording();
                }
                else {
                    setTestState("completed")
                    clearInterval(hearingInterval.current)
                }
            }
            count = count - 1
            setHearingTimer(count)
        }, 1000)
    }

    const startWaitingInterval = () => {
        let count = myQuestion.waitingTime ? myQuestion.waitingTime : 5
        waitingInterval.current = setInterval(async () => {
            if (count == 0) {
                if (props.noHearing) {
                    setTestState("recording");
                    clearInterval(waitingInterval.current);
                    startRecordingInterval();
                    await recorder.startRecording();
                }
                else {
                    setTestState('hearing')
                    clearInterval(waitingInterval.current)
                    startHearingInterval()
                }
            }
            count = count - 1
            setWaitingTimer(count)
        }, 1000)
    }
    useEffect(() => {
        if (!props.noRecord) {

            if (recorder && myQuestion && testIndex) {
                if (testState === "stopped") {
                    setTestState("waiting")
                    startWaitingInterval();

                }
            }
        }
    }, [recorder, myQuestion, testIndex]);
    const startRecording = async () => {
        recorder.startRecording();
    };
    const stopRecording = async () => {
        await recorder.stopRecording();
        let blob = await recorder.getBlob();
        invokeSaveAsDialog(blob, `random_name.webm`);
    };
    const checkTimer = (e: Number) => {
        if (e >= 10) {
            return e.toString();
        } else {
            return `0${e}`;
        }
    };
    const renderChildren = () => {
        return React.Children.map(props.children, (child: ReactElement) => {
            return React.cloneElement(child, {
                question: myQuestion,
                testState: testState,
                setTestState,
                waitingTime: checkTimer(waitingTime),
                recordingTime: checkTimer(recordingTime),
                hearingTime: checkTimer(hearingTime),
                option: file,
                setOption: setFile,
                questionState,
                setQuestionState,
                goForward,
                setGoForward

            });
        });
    };
    useEffect(() => {
        if (props.justHearing) {
            if (questionState == 'completed' && testState == 'completed') {
                setGoForward(true)
            }
            else {
                setGoForward(false)
            }
        }
        else {
            if (testState == 'completed') {
                setGoForward(true)
            }
            else {
                setGoForward(false)
            }
        }
    }, [questionState, testState])
    return (
        <div className='test-container'>
            <div className="test-page-header">
                Question: <span>{testIndex}</span>
            </div>
            {renderChildren()}

            <div className="test-footer">
                <button className="alternate-button" onClick={
                    () => {
                        window.close()

                    }

                }>Save & Exit</button>
                <button
                    className={`
                    ${!goForward || testIndex == testData?.questions?.length ?
                            "disabled-button" : "main-button"}`}
                    disabled={!goForward || testIndex == testData?.questions?.length}

                    onClick={() => {
                        setSearchParams(`index=${testIndex}`)
                        clearInterval(waitingInterval.current)
                        clearInterval(recordingInterval.current)
                        setRecordingTime(0)
                        setWaitingTime(0)
                        setTestState("waiting")
                        let testp = localStorage.getItem('testProcess')
                        if (testp) {
                            let testd = JSON.parse(testp)
                            let cc = testd.map(pr => {
                                if (pr.id == myProcess.id) {
                                    return {
                                        ...pr,
                                        started: true,
                                        currentIndex: testIndex,
                                        answered: [
                                            ...pr.answered,
                                            {
                                                question: myQuestion.id,
                                                answer: file,
                                                testIndex: testIndex - 1

                                            }
                                        ]
                                    }
                                }
                                else {
                                    return { ...pr }
                                }
                            })
                            localStorage.setItem("testProcess", JSON.stringify(cc))
                        }
                        setTestState("stopped")


                    }}>Next</button>
            </div>

        </div>
    )
}

export default TestContainer 