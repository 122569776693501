
import { useEffect } from 'react'
import AudioAnimation from '../../Components/AudioAnimation/AudioAnimation'
import './styles.scss'
const HighlightIncorrectWords = (props) => {
    const { question, option, setOption, setTestState, testState, waitingTime, hearingTime } = props
    const changeSelect = (e) => {
        setOption(prev => {
          if (prev && prev.length > 0) {
            let myarr = prev;
            let modify = false
            let ss = prev.map((p, index) => {
              if (p && p.index == e.index) {
                modify = true
                myarr.splice(index, 1);
                return p
              }
              return p
            })
            if (!modify) {
    
              return [...ss, { ...e }]
            }
            else {
              return [...myarr]
            }
          }
          else {
            return [{ ...e }];
    
          }
        })
    
      }
      useEffect(() => {
        if (option && question && option.length > 0) {
          setTestState("completed")
        }
        else {
          setTestState("stopped")
        }
      }, [option, option?.length, question])
      console.log("option", option)
      return(
        <div className='listening-fib-container'>
            <div className="listening-fib-box">
                <div className="practice-header">You will hear an audio. type the missing word in each blank</div>
                <div className="audio-box">
                    {testState == "completed" ? null : (
                        <div className="status-time">
                            00:
                            {testState == "waiting"
                                ? waitingTime
                                : hearingTime}
                        </div>
                    )}
                    {testState == "completed" ?
                        <div className="completed-mark">
                            <i className="fas fa-check-circle"></i>
                        </div>
                        : (
                            <AudioAnimation
                                color={testState == "waiting" ? "grey" : "grey"}
                                animationState={
                                    testState == "waiting" ? "paused" : "running"
                                }
                            />
                        )}{" "}
                    <div className="status-progress">
                        <div
                            className="status-progress-inner"
                            style={{
                                width: `${((35 -
                                    Number(
                                        testState == "completed"
                                            ? 0
                                            : testState == "waiting"
                                                ? waitingTime
                                                : hearingTime
                                    )) /
                                    35) *
                                    100
                                    }%`,
                            }}
                        ></div>
                    </div>
                    <div className="status-heading">Current Status: <span className="status-text">
                        {testState}
                    </span>
                    </div>
                </div>
                <div className=" fib-texts ">
                    {question && question.paragraph.split(" ").map((pg, index) => {

                        return <span className="selectable-text practice-desc " key={index}
                            onClick={() => {
                                changeSelect({option:pg, index: index})

                            }}
                            style={{
                                background: option && option.find(op => op.index == index) ? '#1366b0' : 'transparent',
                                color: option && option.find(op => op.index == index) ? '#fff' : '#000',
                                padding: option && option.find(op => op.index == index) ? '0px 5px' : '0'
                            }}

                        >{pg + " "}</span>
                    })}
                </div>
            </div>
            
        </div>
    )
}

export default HighlightIncorrectWords