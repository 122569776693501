import React from "react";
import PrivateLayout from "../Layout/PrivateLayout/Index";
import LandingPage from "../Pages/LandingPage/LandingPage";
import Dashboard from "../ProtoctedPages/Dashboard/Dashboard";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
  Router,
} from "react-router-dom";
import ReadAloud from "../ProtoctedPages/ReadAloud/ReadAloud";
import RepeatSentence from "../ProtoctedPages/RepeatSentence/RepeatSentence";
import DescribeImage from "../ProtoctedPages/DescribeImage/DescribeImage";
import ReTellLecture from "../ProtoctedPages/ReTellLecture/ReTellLecture";
import AnswerShortQuestion from "../ProtoctedPages/AnswerShortQuestion/AnswerShortQuestion";
import SummarizeText from "../ProtoctedPages/SummarizeText/SummarizeText";
import WriteEssay from "../ProtoctedPages/WriteEssay/WriteEssay";
import FillInTheBlanks from "../ProtoctedPages/RnWFillInTheBlanks/RnWFillInTheBlanks";
import MCQ from "../ProtoctedPages/MCQ/MCQ";
import ReorderParagraph from "../ProtoctedPages/ReorderParagraph/ReorderParagraph";
import SelectBestWord from "../ProtoctedPages/SelectBestWord/SelectBestWord";
import ChooseSingleAnswer from "../ProtoctedPages/ChooseSingleAnswer/ChooseSingleAnswer";
import SummarizeSpokenText from "../ProtoctedPages/SummarizeSpokenText/SummarizeSpokenText";
import ListeningMultipleAnswers from "../ProtoctedPages/ListeningMultipleAnswers/ListeningMultipleAnswers";
import ListeningFillInTheBlanks from "../ProtoctedPages/ListeningFillInTheBlanks/ListeningFillInTheBlanks";
import HighlightCorrectSummary from "../ProtoctedPages/HighlightCorrectSummary/HighLightCorrectSummary";
import HighlightIncorrectWords from "../ProtoctedPages/HighlightIncorrectWords/HighlightIncorrectWords";
import WriteFromDictation from "../ProtoctedPages/WriteFromDictation/WriteFromDictation";
import ListeningSingleAnswer from "../ProtoctedPages/ListeningSingleAnswers/ListeningSingleAnswers";
import VideoLessons from "../ProtoctedPages/VideoLessonsPage/VideoLessons.tsx/VideoLessons";
import MaterialDownload from "../ProtoctedPages/MaterialDownload/MaterialDownload";
import MockTest from "../ProtoctedPages/MockTest/MockTest";
import VideoPage from "../ProtoctedPages/VideoPage/VideoPage";
import AdminLayout from "../Layout/AdminLayout/IndexLayout";
import AdminDashboard from "../NpPages/AdminDashboard/AdminDashboard";
import ReadAloudPage from "../ProtoctedPages/PracticePages/ReadAloudPage/ReadAloudPage";
import RulesPage from "../ProtoctedPages/RulesPage/RulesPage";
import TestingPage from "../ProtoctedPages/TestingPage/TestingPage";
import TestContainer from "../ProtoctedPages/TestContainer/TestContainer";
import RepeatSentencePage from "../ProtoctedPages/PracticePages/RepeatSentencePage/RepeatSentencePage";
import DescribeImagePage from "../ProtoctedPages/PracticePages/DescribeImagePage/DescribeImagePage";
import RetellLecturePage from "../ProtoctedPages/PracticePages/RetellLecturePage/RetellLecturePage";
import AnswerShortQuestionPage from "../ProtoctedPages/PracticePages/AnswerShortQuestionPage/AnswerShortQuestionPage";
import Analytical from "../ProtoctedPages/Analytical/Analytical";
import RnWFillInTheBlanksPage from "../ProtoctedPages/PracticePages/RnWFillInTheBlanksPage/RnWFillInTheBlanksPage";
import RnWFillInTheBlanks from "../ProtoctedPages/RnWFillInTheBlanks/RnWFillInTheBlanks";
import McqPage from "../ProtoctedPages/PracticePages/McqPage/McqPage";
import ReorderParagrapgPage from "../ProtoctedPages/PracticePages/ReorderParagraphPage/ReorderParagrapgPage";
import FillInTheBlanksPage from "../ProtoctedPages/PracticePages/FillInTheBlanksPage/FillInTheBlanksPage";
import ChooseSingleAnswerPage from "../ProtoctedPages/PracticePages/ChooseSingleAnswerPage/ChooseSingleAnswerPage";
import ListeningSummarizeSpokenPage from "../ProtoctedPages/PracticePages/ListeningSummarizeSpokenPage/ListeningSummarizeSpokenPage";
import ListeningWriteFromDictationPage from "../ProtoctedPages/PracticePages/ListeningWriteFromDictationPage/ListeningWriteFromDictationPage";
import ListeningHighlightCorrectParagraphPage from "../ProtoctedPages/PracticePages/ListeningHighlightCorrectParagraphPage/ListeningHighlightCorrectParagraphPage";
import ListeningChooseSingleAnswerPage from "../ProtoctedPages/PracticePages/ListeningChooseSingleAnswerPage/ListeningChooseSingleAnswerPage";
import ListeningFillInTheBlanksPage from "../ProtoctedPages/PracticePages/ListeningFillInTheBlanksPage/ListeningFillInTheBlanksPage";
import ListeningChooseMultipleAnswerPage from "../ProtoctedPages/PracticePages/ListeningChooseMultipleAnswerPage/ListeningChooseMultipleAnswerPage";
import ListeningHighlightIncorrectWordsPage from "../ProtoctedPages/PracticePages/ListeningHighlightIncorrectWordsPage/ListeningHighlightIncorrectWordsPage";
const IndexRoutes = () => {
  const router = createBrowserRouter([
    {
      path: "/",

      children: [
        {
          path: "/",
          element: <LandingPage />,
        },
        {
          path: "/practice/dashboard",
          element: (
            <PrivateLayout>
              <Dashboard />
            </PrivateLayout>
          ),

        },
        {
          path: "/practice/video-lessons",
          element: (
            <PrivateLayout>
              <VideoLessons />
            </PrivateLayout>
          ),
        },
        {
          path: "/practice/video-lessons/video-player",
          element:
            <PrivateLayout>
              <VideoPage />
            </PrivateLayout>
        },


        // Test Array Pages

        {
          path: "/practice/pte-speaking/read-aloud",
          element:
            <PrivateLayout >
              <ReadAloudPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-speaking/repeat-sentence",
          element:
            <PrivateLayout>
              <RepeatSentencePage />
            </PrivateLayout>
        },

        {
          path: "/practice/pte-speaking/describe-image",
          element:
            <PrivateLayout>
              <DescribeImagePage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-speaking/re-tell-lecture",
          element:
            <PrivateLayout>
              <RetellLecturePage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-speaking/answer-short-question",
          element:
            <PrivateLayout>
              <AnswerShortQuestionPage />
            </PrivateLayout>
        },

        {
          path: "/practice/pte-writing/summarize-written-text",
          element:
            <PrivateLayout>
              <SummarizeText />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-writing/write-essay",
          element:
            <PrivateLayout>
              <WriteEssay />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-reading/r-n-w-fill-in-the-blanks",
          element:
            <PrivateLayout>
              <RnWFillInTheBlanksPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-reading/m-c-q",
          element:
            <PrivateLayout>
              <McqPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-reading/choose-single-answer",
          element:
            <PrivateLayout>
              <ChooseSingleAnswerPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-reading/re-order-paragraphs",
          element:
            <PrivateLayout>
              <ReorderParagrapgPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-reading/fill-in-the-blanks",
          element:
            <PrivateLayout>
              <FillInTheBlanksPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/summarize-spoken-text",
          element:
            <PrivateLayout>
              <ListeningSummarizeSpokenPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/choose-multiple-answer",
          element:
            <PrivateLayout>
              <ListeningChooseMultipleAnswerPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/choose-single-answer",
          element:
            <PrivateLayout>
              <ListeningChooseSingleAnswerPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/fill-in-the-blanks",
          element:
            <PrivateLayout>
              <ListeningFillInTheBlanksPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/highlight-correct-summary",
          element:
            <PrivateLayout>
              <ListeningHighlightCorrectParagraphPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/highlight-incorrect-words",
          element:
            <PrivateLayout>
              <ListeningHighlightIncorrectWordsPage />
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/write-from-dictation",
          element:
            <PrivateLayout>
              <ListeningWriteFromDictationPage />
            </PrivateLayout>
        },


        // New window pages

        {
          path: "/practice/rules",
          element:
            <RulesPage />
        },
        {
          path: "/practice/testing",
          element:
            <TestingPage />
        },
        {
          path: "/practice/pte-speaking/read-aloud-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer noHearing={true}>
                <ReadAloud />
              </TestContainer>
            </PrivateLayout>
        },

        {
          path: "/practice/pte-speaking/repeat-sentence-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer>
                <RepeatSentence />
              </TestContainer>
            </PrivateLayout>
        },

        {
          path: "/practice/pte-speaking/describe-image-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer noHearing={true}>
                <DescribeImage />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-speaking/re-tell-lecture-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer >
                <ReTellLecture />
              </TestContainer>
            </PrivateLayout>
        },

        {
          path: "/practice/pte-speaking/answer-short-question-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer >
                <AnswerShortQuestion />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-reading/r-n-w-fill-in-the-blanks-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer noRecord={true}>
                <RnWFillInTheBlanks />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-reading/m-c-q-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer noRecord={true}>
                <MCQ />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-reading/reorder-paragraph-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer noRecord={true}>
                <ReorderParagraph />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-reading/fill-in-the-blanks-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer noRecord={true}>
                <SelectBestWord />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-reading/choose-single-answer-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer noRecord={true}>
                <ChooseSingleAnswer />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/summarize-spoken-text-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer justHearing>
                <SummarizeSpokenText />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/write-from-dictation-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer justHearing>
                <WriteFromDictation />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/choose-multiple-answer-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer justHearing>
                <ListeningMultipleAnswers />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/choose-single-answer-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer justHearing>
                <ListeningSingleAnswer />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/highlight-incorrect-words-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer justHearing>
                <HighlightIncorrectWords />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/highlight-correct-paragraph-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer justHearing>
                <HighlightCorrectSummary />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-listening/fill-in-the-blanks-test",
          element:
            <PrivateLayout test noOuter>
              <TestContainer justHearing>
                <ListeningFillInTheBlanks />
              </TestContainer>
            </PrivateLayout>
        },
        {
          path: "/practice/pte-mock-test",
          element:
            <PrivateLayout>
              <MockTest />
            </PrivateLayout>
        },
        {
          path: "/practice/material-download",
          element:
            <PrivateLayout>
              <MaterialDownload />
            </PrivateLayout>
        },
        {
          path: "/practice/analytics",
          element:
            <PrivateLayout>
              <Analytical />
            </PrivateLayout>
        },
      ],



    },
    {
      path: "/admin",
      element: (
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      ),
      children: [
        {
          path: "/admin",
          element: <AdminDashboard />,
        },
        {
          path: "/admin/dashboard",
          element: <AdminDashboard />,
        },
      ]
    }

  ]);
  return <RouterProvider router={router} />;
};

export default IndexRoutes;
