import React, { useState } from "react";
// import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import LayoutBody from "./Body/Body";
import Slider from "./Slider/Slider";
import { Layout, Menu } from "antd";
import "./styles.scss";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { useMediaQuery } from "react-responsive";
const AdminLayout = (props) => {
  const isTablet = useMediaQuery({
    query: "(max-width: 992px)",
  });
  const [collapsed, setCollapsed] = useState(isTablet ? true : false);
  const { authUser, authStatus } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname.split("/")[1] == "admin" && pathname.split("/").length == 2) {
      navigate("/admin/dashboard");
    }
  }, [pathname]);
  useEffect(() => {
    if (!authUser && authStatus=='checked') {
      navigate("/admin/admin-login");
    }
  }, [authUser, authStatus]);
  return (
    <div>
      <Slider collapsed={collapsed} setCollapsed={setCollapsed} />
      <div
        className={` my-layouty-body ${
          collapsed ? "my-layout-collapsed" : ""
        } ${collapsed && isTablet ? "my-layout-collapsed-tablet" : ""}`}
      >
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />
        <LayoutBody>{props.children}</LayoutBody>
        {/* <Footer collapsed={collapsed} setCollapsed={setCollapsed} /> */}
      </div>
      {isTablet && !collapsed && (
        <div
          className="mobile-collapsed-drop"
          onClick={() => setCollapsed(true)}
        ></div>
      )}
    </div>
  );
};

export default AdminLayout;
