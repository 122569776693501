import { summarizeSpokenQuestion } from "../listeningQuestions/summarizeSpokenQuestion";

export const summarizeSpokenTest = [
    {
        id: "lsst_001",
        type: 'listening',
        subType: "listening_summarize_spoken_text",
        questions: [{ qId: "ssq_001", testIndex: 0 },
        { qId: "ssq_002", testIndex: 1 },
        { qId: "ssq_003", testIndex: 2 },
        { qId: "ssq_004", testIndex: 3 },
        { qId: "ssq_005", testIndex: 4 }],
        duration: summarizeSpokenQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lsst_002",
        type: 'listening',
        subType: "listening_summarize_spoken_text",
        questions: [{ qId: "ssq_001", testIndex: 0 },
        { qId: "ssq_002", testIndex: 1 },
        { qId: "ssq_003", testIndex: 2 },
        { qId: "ssq_004", testIndex: 3 },
        { qId: "ssq_005", testIndex: 4 }],
        duration: summarizeSpokenQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0

    },
    {
        id: "lsst_003",
        type: 'listening',
        subType: "listening_summarize_spoken_text",
        questions: [{ qId: "ssq_001", testIndex: 0 },
        { qId: "ssq_002", testIndex: 1 },
        { qId: "ssq_003", testIndex: 2 },
        { qId: "ssq_004", testIndex: 3 },
        { qId: "ssq_005", testIndex: 4 }],
        duration: summarizeSpokenQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lsst_004",
        type: 'listening',
        subType: "listening_summarize_spoken_text",
        questions: [{ qId: "ssq_001", testIndex: 0 },
        { qId: "ssq_002", testIndex: 1 },
        { qId: "ssq_003", testIndex: 2 },
        { qId: "ssq_004", testIndex: 3 },
        { qId: "ssq_005", testIndex: 4 }],
        duration: summarizeSpokenQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lsst_005",
        type: 'listening',
        subType: "listening_summarize_spoken_text",
        questions: [{ qId: "ssq_001", testIndex: 0 },
        { qId: "ssq_002", testIndex: 1 },
        { qId: "ssq_003", testIndex: 2 },
        { qId: "ssq_004", testIndex: 3 },
        { qId: "ssq_005", testIndex: 4 }],
        duration: summarizeSpokenQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "lsst_006",
        type: 'listening',
        subType: "listening_summarize_spoken_text",
        questions: [{ qId: "ssq_001", testIndex: 0 },
        { qId: "ssq_002", testIndex: 1 },
        { qId: "ssq_003", testIndex: 2 },
        { qId: "ssq_004", testIndex: 3 },
        { qId: "ssq_005", testIndex: 4 }],
        duration: summarizeSpokenQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
]