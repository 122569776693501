import AudioAnimation from '../../Components/AudioAnimation/AudioAnimation'
import './styles.scss'


const DescribeImage = (props) => {
  const { testState, waitingTime, recordingTime, question } = props



  return (
    <div className='describe-image-container'>
      <div className="describe-image-box">
        <div className="practice-header">
          {question && question.questionText}
        </div>
        <div className="describe-image-body">
          <div className="describe-image-image">
            <img src={question && question.image} alt="" />
          </div>

          <div className="reacrding-box audio-box">
            {testState == "completed" ? null : (
              <div className="status-time">
                00:
                {testState == "waiting"
                  ? waitingTime
                  : recordingTime}
              </div>
            )}
            {testState == "completed" ?
              <div className="completed-mark">
                <i className="fas fa-check-circle"></i>
              </div>
              : (
                <AudioAnimation
                  color={testState == "waiting" ? "grey" : "blue"}
                  animationState={
                    testState == "waiting" ? "paused" : "running"
                  }
                />
              )}{" "}
            <div className="status-progress">
              <div
                className="status-progress-inner"
                style={{
                  width: `${((35 -
                    Number(
                      testState == "completed"
                        ? 0
                        : testState == "waiting"
                          ? waitingTime
                          : recordingTime
                    )) /
                    35) *
                    100
                    }%`,
                }}
              ></div>
            </div>
            <div className="status-heading">Current Status: <span className="status-text">
              {testState}
            </span>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  )
}

export default DescribeImage