import AnimalAudio from '../../Audio/listening/sumamarizespoken/animals-2.mp3'
import UniversityAudio from '../../Audio/listening/sumamarizespoken/university.mp3'
import AbroadAudio from '../../Audio/listening/sumamarizespoken/studyabroad.mp3'
import LiteratureAudio from '../../Audio/listening/sumamarizespoken/literature.mp3'
import ArticAudio from '../../Audio/listening/sumamarizespoken/arctic-ice.mp3'

export const summarizeSpokenQuestion = [
    {
        id: "ssq_001",
        audio: ArticAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "listening_summarize_spoken_text",
        time: 54,
        difficulty: 4,
        waitingTime: 5,
    },
    {
        id: "ssq_002",
        audio: UniversityAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "listening_summarize_spoken_text",
        time: 75,
        difficulty: 4,
        waitingTime: 5,
    },
    {
        id: "ssq_003",
        audio: AnimalAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "listening_summarize_spoken_text",
        time: 82,
        difficulty: 3,
        waitingTime: 5,
    },
    {
        id: "ssq_004",
        audio: AbroadAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "listening_summarize_spoken_text",
        time: 57,
        difficulty: 2,
        waitingTime: 5,
    },
    {
        id: "ssq_005",
        audio: LiteratureAudio,
        questionText: "You will hear a short lecture. Write a summary on what you have just heard from the lecturer in your own words. You have 10 minutes to finish this task. Your response will be judged on the Quality of your writing and on how well your response presents the key points presented in the lecture.",
        type: 'listening',
        subType: "listening_summarize_spoken_text",
        time: 52,
        difficulty: 3,
        waitingTime: 5,
    },


] 