import './styles.scss'
import AudioAnimation from '../../Components/AudioAnimation/AudioAnimation'


import './styles.scss'
const RepeatSentence = (props) => {
  const
    { testState, question,
      waitingTime,
      recordingTime,
      hearingTime } = props


  return (
    <div className='repeat-sentence-container'>
      <div className="repeat-sentence-box">
        <div className="practice-header">
          You will hear a sentence. Please repeat the sentence exactly as you hear it.
          You will hear the sentence only once.
        </div>

        <div className="audio-box">
          {testState == "completed" ? null : (
            <div className="status-time">
              00:
              {testState == "waiting"
                ? waitingTime :
                testState == 'hearing' ? hearingTime : recordingTime}
            </div>
          )}
          {testState == "completed" ?
            <div className="completed-mark">
              <i className="fas fa-check-circle"></i>
            </div>
            : (
              <AudioAnimation
                color={testState == "waiting" ? "grey" : testState == 'hearing' ? "grey" : 'blue'}
                animationState={
                  testState == "waiting" ? "paused" : "running"
                }
              />
            )}{" "}
          <div className="status-progress">
            <div
              className="status-progress-inner"
              style={{
                width: `${((35 -
                  Number(
                    testState == "completed"
                      ? 0
                      : testState == "waiting"
                        ? waitingTime :
                        testState == 'hearing' ?
                          hearingTime : recordingTime
                  )) /
                  35) *
                  100
                  }%`,
              }}
            ></div>
          </div>
          <div className="status-heading">Current Status: <span className="status-text">
            {testState}
          </span>
          </div>
        </div>
      </div>

    </div>
  )
}

export default RepeatSentence