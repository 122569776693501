import { answerShortQuestion } from "../speakingQuestions/answerShortQuestion";

export const answerShortQuestionTest = [
    {
        id: "rst_001",
        type: 'speaking',
        subType: "answer_short_question",
        questions: [{ qId: "asqq_001", testIndex: 0 },
        { qId: "asqq_002", testIndex: 1 },
        { qId: "asqq_003", testIndex: 2 },
        { qId: "asqq_004", testIndex: 3 },
        { qId: "asqq_005", testIndex: 4 },
        { qId: "asqq_006", testIndex: 5 },
        { qId: "asqq_007", testIndex: 6 },
        { qId: "asqq_008", testIndex: 7 },
        { qId: "asqq_009", testIndex: 8 },
        { qId: "asqq_010", testIndex: 9 }],
        duration: answerShortQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "rst_002",
        type: 'speaking',
        subType: "answer_short_question",
        questions: [{ qId: "asqq_001", testIndex: 0 },
        { qId: "asqq_002", testIndex: 1 },
        { qId: "asqq_003", testIndex: 2 },
        { qId: "asqq_004", testIndex: 3 },
        { qId: "asqq_005", testIndex: 4 },
        { qId: "asqq_006", testIndex: 5 },
        { qId: "asqq_007", testIndex: 6 },
        { qId: "asqq_008", testIndex: 7 },
        { qId: "asqq_009", testIndex: 8 },
        { qId: "asqq_010", testIndex: 9 }],
        duration: answerShortQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 2
    },
    {
        id: "rst_003",
        type: 'speaking',
        subType: "answer_short_question",
        questions: [{ qId: "asqq_001", testIndex: 0 },
        { qId: "asqq_002", testIndex: 1 },
        { qId: "asqq_003", testIndex: 2 },
        { qId: "asqq_004", testIndex: 3 },
        { qId: "asqq_005", testIndex: 4 },
        { qId: "asqq_006", testIndex: 5 },
        { qId: "asqq_007", testIndex: 6 },
        { qId: "asqq_008", testIndex: 7 },
        { qId: "asqq_009", testIndex: 8 },
        { qId: "asqq_010", testIndex: 9 }],
        duration: answerShortQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 3
    },
    {
        id: "rst_004",
        type: 'speaking',
        subType: "answer_short_question",
        questions: [{ qId: "asqq_001", testIndex: 0 },
        { qId: "asqq_002", testIndex: 1 },
        { qId: "asqq_003", testIndex: 2 },
        { qId: "asqq_004", testIndex: 3 },
        { qId: "asqq_005", testIndex: 4 },
        { qId: "asqq_006", testIndex: 5 },
        { qId: "asqq_007", testIndex: 6 },
        { qId: "asqq_008", testIndex: 7 },
        { qId: "asqq_009", testIndex: 8 },
        { qId: "asqq_010", testIndex: 9 }],
        duration: answerShortQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 4
    },
    {
        id: "rst_005",
        type: 'speaking',
        subType: "answer_short_question",
        questions: [{ qId: "asqq_001", testIndex: 0 },
        { qId: "asqq_002", testIndex: 1 },
        { qId: "asqq_003", testIndex: 2 },
        { qId: "asqq_004", testIndex: 3 },
        { qId: "asqq_005", testIndex: 4 },
        { qId: "asqq_006", testIndex: 5 },
        { qId: "asqq_007", testIndex: 6 },
        { qId: "asqq_008", testIndex: 7 },
        { qId: "asqq_009", testIndex: 8 },
        { qId: "asqq_010", testIndex: 9 }],
        duration: answerShortQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 5
    },
    {
        id: "rst_006",
        type: 'speaking',
        subType: "answer_short_question",
        questions: [{ qId: "asqq_001", testIndex: 0 },
        { qId: "asqq_002", testIndex: 1 },
        { qId: "asqq_003", testIndex: 2 },
        { qId: "asqq_004", testIndex: 3 },
        { qId: "asqq_005", testIndex: 4 },
        { qId: "asqq_006", testIndex: 5 },
        { qId: "asqq_007", testIndex: 6 },
        { qId: "asqq_008", testIndex: 7 },
        { qId: "asqq_009", testIndex: 8 },
        { qId: "asqq_010", testIndex: 9 }],
        duration: answerShortQuestion.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 6
    },
]