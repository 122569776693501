import { chooseMultipleAnswerQuestion } from "./listeningQuestions/chooseMultipleAnswerQuestion";
import { listeningChooseSingleAnswerQuestion } from "./listeningQuestions/chooseSingleAnswerQuestion";
import { highlightCorrectParagraphQuestion } from "./listeningQuestions/highlightCorrectParagraphQuestion";
import { listeningHighlightIncorrectWordsQuestion } from "./listeningQuestions/highlightIncorrectWordsQuestions";
import { listeningFillInTheBlanksQuestion } from "./listeningQuestions/listeningFillInTheBlanksQuestion";
import { summarizeSpokenQuestion } from "./listeningQuestions/summarizeSpokenQuestion";
import { writeFromDictationQuestion } from "./listeningQuestions/writeFromDictationQuestion";
import { chooseSingleAnswerQuestion } from "./readingQuestions/chooseSingleAnswerQuestion";
import { fillInTheBlanksQuestion } from "./readingQuestions/fillInTheBlanksQuestion";
import { mcQuestion } from "./readingQuestions/mcQuestion";
import { reOrderParagraphQuestion } from "./readingQuestions/reOrderParagraphQuestion";
import { rnwFillInTheBlanksQuestion } from "./readingQuestions/rnwFillInTheBlanksQuestion";
import { answerShortQuestion } from "./speakingQuestions/answerShortQuestion";
import { describeImageQuestions } from "./speakingQuestions/describeImage";
import { retellLectureQuestions } from "./speakingQuestions/reTellLecture";
import { readAloudQuestions } from "./speakingQuestions/readAloud";
import { repeatSentenceQuestions } from "./speakingQuestions/repeatSentence";

export const allQuestions = [
    ...readAloudQuestions,
    ...repeatSentenceQuestions,
    ...describeImageQuestions,
    ...retellLectureQuestions,
    ...answerShortQuestion,
    ...rnwFillInTheBlanksQuestion,
    ...mcQuestion,
    ...reOrderParagraphQuestion,
    ...fillInTheBlanksQuestion,
    ...chooseSingleAnswerQuestion,
    ...listeningFillInTheBlanksQuestion,
    ...listeningHighlightIncorrectWordsQuestion,
    ...chooseMultipleAnswerQuestion,
    ...writeFromDictationQuestion,
    ...summarizeSpokenQuestion,
    ...highlightCorrectParagraphQuestion,
    ...listeningChooseSingleAnswerQuestion
]