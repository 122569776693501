import StudentAudio from '../../Audio/repeatSentence/students.mp3'
import GlobalisationAudio from '../../Audio/repeatSentence/globalisation-4.mp3'
import TechAudio from '../../Audio/repeatSentence/tech.mp3'
import ThirtyDaysAudio from '../../Audio/repeatSentence/30-days.mp3'
import ApplyAudio from '../../Audio/repeatSentence/apply-1.mp3'
import WeatherAudio from '../../Audio/repeatSentence/weather-1.mp3'
import ChildrenAudio from '../../Audio/repeatSentence/children-1.mp3'
import LotsAudio from '../../Audio/repeatSentence/lots.mp3'
import JobsAudio from '../../Audio/repeatSentence/jobs-2.mp3'
import TransportAudio from '../../Audio/repeatSentence/transport.mp3'

export const repeatSentenceQuestions = [
    {
        id:"rsq_001",
        audio: StudentAudio,
        type: 'speaking',
        subType:"repeat_sentence",
        time: 7,
        difficulty: 4
    },
    {
        id:"rsq_002",
        audio: GlobalisationAudio,
        type: 'speaking',
        subType:"repeat_sentence",
        time: 7,
        difficulty: 4
    },
    {
        id:"rsq_003",
        audio: TechAudio,
        type: 'speaking',
        subType:"repeat_sentence",
        time: 5,
        difficulty: 3
    },
    {
        id:"rsq_004",
        audio: ThirtyDaysAudio,
        type: 'speaking',
        subType:"repeat_sentence",
        time: 4,
        difficulty: 2
    },
    {
        id:"rsq_005",
        audio: ApplyAudio,
        type: 'speaking',
        subType:"repeat_sentence",
        time: 4,
        difficulty: 3
    },
    {
        id:"rsq_006",
        audio: WeatherAudio,
        type: 'speaking',
        subType:"repeat_sentence",
        time: 4,
        difficulty: 4
    },
    {
        id:"rsq_007",
        audio: ChildrenAudio,
        type: 'speaking',
        subType:"repeat_sentence",
        time: 4,
        difficulty: 3
    },
    {
        id:"rsq_008",
        audio: LotsAudio,
        type: 'speaking',
        subType:"repeat_sentence",
        time: 5,
        difficulty: 2
    },
    {
        id:"rsq_009",
        audio: JobsAudio,
        type: 'speaking',
        subType:"repeat_sentence",
        time: 4,
        difficulty: 2
    },
    {
        id:"rsq_010",
        audio: TransportAudio,
        type: 'speaking',
        subType:"repeat_sentence",
        time: 4,
        difficulty: 3
    },
] 