import { describeImageQuestions } from "../speakingQuestions/describeImage";

export const describeImageTest = [
    {
        id: "dit_001",
        type: 'speaking',
        subType: "describe_image",
        questions: [
            { qId: "diq_001", testIndex: 0 },
            { qId: "diq_002", testIndex: 1 },
            { qId: "diq_003", testIndex: 2 },
            { qId: "diq_004", testIndex: 3 },
            { qId: "diq_005", testIndex: 4 },
            { qId: "diq_006", testIndex: 5 },
            { qId: "diq_007", testIndex: 6 },
        ],
        duration: describeImageQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 0
    },
    {
        id: "dit_002",
        type: 'speaking',
        subType: "describe_image",
        questions: [
            { qId: "diq_001", testIndex: 0 },
            { qId: "diq_002", testIndex: 1 },
            { qId: "diq_003", testIndex: 2 },
            { qId: "diq_004", testIndex: 3 },
            { qId: "diq_005", testIndex: 4 },
            { qId: "diq_006", testIndex: 5 },
            { qId: "diq_007", testIndex: 6 },
        ],
        duration: describeImageQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 2
    },
    {
        id: "dit_003",
        type: 'speaking',
        subType: "describe_image",
        questions: [
            { qId: "diq_001", testIndex: 0 },
            { qId: "diq_002", testIndex: 1 },
            { qId: "diq_003", testIndex: 2 },
            { qId: "diq_004", testIndex: 3 },
            { qId: "diq_005", testIndex: 4 },
            { qId: "diq_006", testIndex: 5 },
            { qId: "diq_007", testIndex: 6 },
        ],
        duration: describeImageQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 3
    },
    {
        id: "dit_004",
        type: 'speaking',
        subType: "describe_image",
        questions: [
            { qId: "diq_001", testIndex: 0 },
            { qId: "diq_002", testIndex: 1 },
            { qId: "diq_003", testIndex: 2 },
            { qId: "diq_004", testIndex: 3 },
            { qId: "diq_005", testIndex: 4 },
            { qId: "diq_006", testIndex: 5 },
            { qId: "diq_007", testIndex: 6 },
        ],
        duration: describeImageQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 4
    },
    {
        id: "dit_005",
        type: 'speaking',
        subType: "describe_image",
        questions: [
            { qId: "diq_001", testIndex: 0 },
            { qId: "diq_002", testIndex: 1 },
            { qId: "diq_003", testIndex: 2 },
            { qId: "diq_004", testIndex: 3 },
            { qId: "diq_005", testIndex: 4 },
            { qId: "diq_006", testIndex: 5 },
            { qId: "diq_007", testIndex: 6 },
        ],
        duration: describeImageQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 5
    },
    {
        id: "dit_006",
        type: 'speaking',
        subType: "describe_image",
        questions: [
            { qId: "diq_001", testIndex: 0 },
            { qId: "diq_002", testIndex: 1 },
            { qId: "diq_003", testIndex: 2 },
            { qId: "diq_004", testIndex: 3 },
            { qId: "diq_005", testIndex: 4 },
            { qId: "diq_006", testIndex: 5 },
            { qId: "diq_007", testIndex: 6 },
        ],
        duration: describeImageQuestions.reduce((pr, cur, index) => pr + cur.time, 0),
        totalScore: 100,
        obtainedScore: 0,
        testIndex: 6
    },
]