import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LayoutContext } from "../../Context/LayoutContext/LayoutContext";
import "./styles.scss";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
const TestBox = (props) => {
  const navigate = useNavigate();
  const {
    duration,
    type,
    questions,
    position,
    totalScore,
    obtainedScore,
    testIndex,
    id,
    started,
    completed
  } = props.testData;
  const { link } = props;
  const { setExamLink, examLink } = useContext(LayoutContext)
  const { tests,
    setTests } = useContext(AuthContext)
  const [testProcess, setTestProcess] = useState(null)
  useEffect(() => {
    let cc = localStorage.getItem('testProcess')
    if (cc) {
      let dd = JSON.parse(cc)
      let s = dd.find(d => d.test == id)
      if (s) {
        setTestProcess(s)
      }
    }
  }, [localStorage.getItem('testProcess')])
  return (
    <div className="mock-test-box">
      <div className="mock-text-top">
        <div className="mock-test-header">Test - {testIndex}</div>
      </div>
      <div className="mock-test-bottom">
        <div className="mock-test-bottom-box mock-test-result">
          <span className="bottom-icon">
            <i class="fa-solid fa-clipboard"></i>
          </span>
          <span className="bottom-text">Result</span>
        </div>
        <div className="mock-test-bottom-box mock-test-answer">
          <span className="bottom-icon">
            <i class="fa-solid fa-calendar-check"></i>
          </span>
          <span className="bottom-text">Answer</span>
        </div>
        <div className="mock-test-bottom-box mock-test-analytics" onClick={() => {
          if (testProcess) {
            localStorage.setItem("mytestProcess", JSON.stringify(testProcess))
            navigate("/practice/analytics")
          }
        }}>
          <span className="bottom-icon">
            <i class="fa-solid fa-chart-simple"></i>
          </span>
          <span className="bottom-text">Analytics</span>
        </div>
      </div>
      <div className="mock-test-score" style={{
        background: testProcess && testProcess.started && !testProcess.completed ? 'red'
          :
          testProcess && testProcess.started && testProcess.completed ?
            "green" : "orange"
      }}>
        <span className="score-title">Test Status</span>
        <span className="score-number">
          {testProcess && testProcess.started && !testProcess.completed ? 'Ongoing' :
            testProcess && testProcess.started && testProcess.completed ? "Completed" : "Not Started"}
        </span>
      </div>
      <div className="mock-test-middle">
        <span className="test-middle-box test-duration">
          <span className="test-middle-title">{duration}</span>
          <span className="test-middle-number">Duration</span>
        </span>
        <span className="test-middle-box test-questions">
          <span className="test-middle-title">{questions.length}</span>
          <span className="test-middle-number">Questions</span>
        </span>
      </div>

      <div
        className="mock-test-status"
        onClick={() => {
          setExamLink(link)
          let cur = 0
          let cc = localStorage.getItem('testProcess')
          if (cc) {
            let dd = JSON.parse(cc)
            dd.map(tp => {
              if (tp.test == props.testData.id) {
                cur = tp.currentIndex
              }
              else {
                cur = 0
              }
            })
          }

          localStorage.setItem('examLink', `${link}/?index=${cur}`)

          localStorage.setItem('testData', JSON.stringify(props.testData))
          setTests(prev => {
            return prev.map(pp => {
              if (pp.id == props.testData.id) {
                return {
                  ...pp,
                  started: true
                }
              }
              else {
                return { ...pp }
              }
            })
          })
          window.open('/practice/testing', '_blank', 'popup')
          // navigate(link)
        }}
      >
        Start
      </div>
    </div>
  );
};

export default TestBox;
