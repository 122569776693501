import { data } from "jquery";
import React, { useEffe } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import AnalyticsBox from "../../NpComponents/AnalyticsBox/AnalyticsBox";
import WorldMap from "../../NpComponents/WorldMap/WorldMap";
import {
  fetchActiveUsers,
  fetchAverageSessionDuration,
  fetchBounceRate,
  fetchPageViews,
  fetchSessionByDevice,
  fetchSessionPerUser,
  fetchTrafficSource,
  fetchUserBasedCountry,
} from "../../utils/api-calls/analyticsCalls";
// import DeviceChart from "./DeviceChart";
import PagesView from "./PagesView";
// import SourceChart from "./SourceChart";
import "./styles.scss";
const Analytics = () => {
  const {
    activeUsers,
    setActiveUsers,
    sessionDuration,
    setSessionDuration,
    bounceRate,
    setBounceRate,
    sessionPerUser,
    setSessionPerUser,
    trafficSource,
    setTrafficSource,
    sessionsByDevice,
    setSessionsByDevice,
    countryBasedUser,
    setCountryBasedUser,
    pageViews,
    setPageViews,
  } = useContext(AuthContext);
  const [dashboardAnalyticsData, setDashBoardAnalyticsData] = useState([
    {
      icon: <i class="fa-solid fa-users"></i>,
      title: "Active Users",
      id: "users",
      data: 0,
      background: "blue",
    },
    {
      icon: <i class="fa-solid fa-person-walking-arrow-loop-left"></i>,
      title: "Bounce Rate",
      id: "bounce",
      data: "0%",
      background: "red",
    },
    {
      icon: <i class="fa-solid fa-copy"></i>,
      title: "Session Per User",
      id: "sessionPerUser",
      data: 0,
      background: "lightgreen",
    },
    {
      icon: <i class="fa-solid fa-stopwatch"></i>,
      title: "Session Duration",
      id: "sessionDuration",
      data: 0,
      background: "orange",
    },
  ]);
  useEffect(() => {
    if (bounceRate && bounceRate.length > 0) {
      let cc = dashboardAnalyticsData.map((da, index) => {
        if (da.id == "bounce") {
          return {
            ...da,
            data:
              parseFloat(bounceRate[0].metricValues[0].value * 100).toFixed(2) +
              "%",
          };
        }
        return da;
      });
      setDashBoardAnalyticsData([...cc]);
    }
  }, [bounceRate]);

  useEffect(() => {
    if (activeUsers && activeUsers.length > 0) {
      let cc = dashboardAnalyticsData.map((da, index) => {
        if (da.id == "users") {
          return { ...da, data: activeUsers[0].metricValues[0].value };
        }
        return da;
      });
      setDashBoardAnalyticsData([...cc]);
    }
  }, [activeUsers]);

  useEffect(() => {
    if (sessionPerUser && sessionPerUser.length > 0) {
      let cc = dashboardAnalyticsData.map((da, index) => {
        if (da.id == "sessionPerUser") {
          return {
            ...da,
            data: parseFloat(sessionPerUser[0].metricValues[0].value).toFixed(
              2
            ),
          };
        }
        return da;
      });
      setDashBoardAnalyticsData([...cc]);
    }
  }, [sessionPerUser]);

  useEffect(() => {
    if (sessionDuration && sessionDuration.length > 0) {
      let cc = dashboardAnalyticsData.map((da, index) => {
        if (da.id == "sessionDuration") {
          return {
            ...da,
            data: parseFloat(sessionDuration[0].metricValues[0].value).toFixed(
              2
            ),
          };
        }
        return da;
      });
      setDashBoardAnalyticsData([...cc]);
    }
  }, [sessionDuration]);

  const fetchingActiveUsers = async () => {
    let activeUsersf = await fetchActiveUsers();
    setActiveUsers(activeUsersf);
  };
  useEffect(() => {
    fetchingActiveUsers();
  }, []);
  const fetchingUserBasedCountry = async () => {
    let userBasedCountryf = await fetchUserBasedCountry();
    setCountryBasedUser(userBasedCountryf);
  };
  useEffect(() => {
    fetchingUserBasedCountry();
  }, []);
  const fetchingBounceRate = async () => {
    let bouncef = await fetchBounceRate();
    setBounceRate(bouncef);
  };
  useEffect(() => {
    fetchingBounceRate();
  }, []);
  const fetchingSessionDuration = async () => {
    let sessionDurationf = await fetchAverageSessionDuration();
    setSessionDuration(sessionDurationf);
  };
  useEffect(() => {
    fetchingSessionDuration();
  }, []);
  const fetchingSessionPerUser = async () => {
    let sessionPerUserf = await fetchSessionPerUser();
    setSessionPerUser(sessionPerUserf);
  };
  useEffect(() => {
    fetchingSessionPerUser();
  }, []);
  const fetchingTrafficSource = async () => {
    let trafficSourcef = await fetchTrafficSource();
    setTrafficSource(trafficSourcef);
  };
  useEffect(() => {
    fetchingTrafficSource();
  }, []);
  const fetchingSessionByDevice = async () => {
    let sessionByDevicef = await fetchSessionByDevice();
    setSessionsByDevice(sessionByDevicef);
  };
  useEffect(() => {
    fetchingSessionByDevice();
  }, []);
  const fetchingPageViews = async () => {
    let pageViewsf = await fetchPageViews();
    setPageViews(pageViewsf);
  };
  useEffect(() => {
    fetchingPageViews();
  }, []);

  return (
    <div>
      <div className="dashboard-analytics-boxes">
        <div className="row">
          {dashboardAnalyticsData.map((data, index) => {
            return (
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <AnalyticsBox item={data} />
              </div>
            );
          })}
        </div>
        <div className="row">
          <WorldMap />
          <div className="col-lg-12">
            <PagesView />
          </div>
          <div className="col-lg-12">
            {/* <DeviceChart /> */}
          </div>
          {/* <div className="col-lg-12">
            <SourceChart />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
