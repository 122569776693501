import { Draggable } from "react-beautiful-dnd";

const MyDraggable = ({ id, paragraph }) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "grey" : "f0f0f0",

    // styles we need to apply on draggables
    ...draggableStyle
  });


  return (
    <Draggable  draggableId={`${paragraph?.data?.assignedIndex}`} key={paragraph?.data?.assignedIndex} index={id}>
      {(provided, snapshot) => (
        <p className={`disrupted-para`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {paragraph?.data?.text}
        </p>

      )}
    </Draggable>

  )
}

export default MyDraggable