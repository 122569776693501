

export const reOrderParagraphQuestion = [
    {
        id: "ropq_001",
        paragraphs: [
            {
                text:'In the urban areas, 75 percent of the individuals who have taken the test have a heart age that is aleast one year older than their real age',
                assignedIndex:0,
                realIndex:3
            },
            {
                text:"A campaign has been lauched to help people find their heart's age",
                assignedIndex:1,
                realIndex:0
            },
            {
                text:"The free online heart age test asks people some basic lifestyle questions, including blood pressure, and will give an instant estimation of someone's age.",
                assignedIndex:2,
                realIndex:2
            },
            {
                text:"Those who have heart age higher than their real age are at an increased risk of heart attack or stroke",
                assignedIndex:3,
                realIndex:4
            },
            {
                text:"Ministry of health has created an online test in an effort to reduce the number of deaths from heart disease or stroke",
                assignedIndex:4,
                realIndex:1
            }
        ],
        type: 'reading',
        subType: "reorder_paragraph",
        time: 10,
        difficulty: 4
    },
    {
        id: "ropq_002",
        paragraphs: [
            {
                text:'In the urban areas, 75 percent of the individuals who have taken the test have a heart age that is aleast one year older than their real age',
                assignedIndex:0,
                realIndex:3
            },
            {
                text:"A campaign has been lauched to help people find their heart's age",
                assignedIndex:1,
                realIndex:0
            },
            {
                text:"The free online heart age test asks people some basic lifestyle questions, including blood pressure, and will give an instant estimation of someone's age.",
                assignedIndex:2,
                realIndex:2
            },
            {
                text:"Those who have heart age higher than their real age are at an increased risk of heart attack or stroke",
                assignedIndex:3,
                realIndex:4
            },
            {
                text:"Ministry of health has created an online test in an effort to reduce the number of deaths from heart disease or stroke",
                assignedIndex:4,
                realIndex:1
            }
        ],
        type: 'reading',
        subType: "reorder_paragraph",
        time: 10,
        difficulty: 4
    },
    {
        id: "ropq_003",
        paragraphs: [
            {
                text:'In the urban areas, 75 percent of the individuals who have taken the test have a heart age that is aleast one year older than their real age',
                assignedIndex:0,
                realIndex:3
            },
            {
                text:"A campaign has been lauched to help people find their heart's age",
                assignedIndex:1,
                realIndex:0
            },
            {
                text:"The free online heart age test asks people some basic lifestyle questions, including blood pressure, and will give an instant estimation of someone's age.",
                assignedIndex:2,
                realIndex:2
            },
            {
                text:"Those who have heart age higher than their real age are at an increased risk of heart attack or stroke",
                assignedIndex:3,
                realIndex:4
            },
            {
                text:"Ministry of health has created an online test in an effort to reduce the number of deaths from heart disease or stroke",
                assignedIndex:4,
                realIndex:1
            }
        ],
        type: 'reading',
        subType: "reorder_paragraph",
        time: 10,
        difficulty: 4
    },
    {
        id: "ropq_004",
        paragraphs: [
            {
                text:'In the urban areas, 75 percent of the individuals who have taken the test have a heart age that is aleast one year older than their real age',
                assignedIndex:0,
                realIndex:3
            },
            {
                text:"A campaign has been lauched to help people find their heart's age",
                assignedIndex:1,
                realIndex:0
            },
            {
                text:"The free online heart age test asks people some basic lifestyle questions, including blood pressure, and will give an instant estimation of someone's age.",
                assignedIndex:2,
                realIndex:2
            },
            {
                text:"Those who have heart age higher than their real age are at an increased risk of heart attack or stroke",
                assignedIndex:3,
                realIndex:4
            },
            {
                text:"Ministry of health has created an online test in an effort to reduce the number of deaths from heart disease or stroke",
                assignedIndex:4,
                realIndex:1
            }
        ],
        type: 'reading',
        subType: "reorder_paragraph",
        time: 10,
        difficulty: 4
    },
    {
        id: "ropq_005",
        paragraphs: [
            {
                text:'In the urban areas, 75 percent of the individuals who have taken the test have a heart age that is aleast one year older than their real age',
                assignedIndex:0,
                realIndex:3
            },
            {
                text:"A campaign has been lauched to help people find their heart's age",
                assignedIndex:1,
                realIndex:0
            },
            {
                text:"The free online heart age test asks people some basic lifestyle questions, including blood pressure, and will give an instant estimation of someone's age.",
                assignedIndex:2,
                realIndex:2
            },
            {
                text:"Those who have heart age higher than their real age are at an increased risk of heart attack or stroke",
                assignedIndex:3,
                realIndex:4
            },
            {
                text:"Ministry of health has created an online test in an effort to reduce the number of deaths from heart disease or stroke",
                assignedIndex:4,
                realIndex:1
            }
        ],
        type: 'reading',
        subType: "reorder_paragraph",
        time: 10,
        difficulty: 4
    },
]