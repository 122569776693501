import { useState, useEffect } from "react";
import "./styles.scss";
const ChooseSingleAnswer = (props) => {
  const { question, option, setOption, setTestState } = props

  const changeSelect = (e) => {
    setOption(prev => {
      if (prev) {
        if (prev.index == e.index) {
          return null;
        } else {
          return { ...e }
        }

      }
      else {
        return { ...e };

      }
    })

  }
  useEffect(() => {
    if (option && question) {
      setTestState("completed")
    }
    else {
      setTestState("stopped")
    }
  }, [option, question])
  return (
    <div className="choose-single-container">
      <div className="choose-single-box">
        <div className="choose-single-left practice-desc">
          {question && question.paragraph}
        </div>
        <div className="choose-single-right">
          <div className="practice-header">
            Read the text and answer the question by selecting all the correct
            responses. More than one response is correct.
          </div>
          <div className="choose-single-right-sub-heading">
            {question && question.question}
          </div>
          <div className="choose-single-check-boxes">
            {question && question.mcq.map((vv, index) => {

              return (
                <div
                  className="check-box-container"
                  onClick={() => {
                    changeSelect(vv)
                  }}
                >
                  <div className="check-round">
                    {option && option.index == vv.index && (
                      <div className="check-mark">
                        <i class="fa-solid fa-check"></i>
                      </div>
                    )}
                  </div>
                  <div className="ckeck-name">{vv.option}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default ChooseSingleAnswer;
