import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "../../Context/LayoutContext/LayoutContext";
import "./styles.scss";
const RulesPage = () => {
  const { examLink } = useContext(LayoutContext)
  const navigate = useNavigate()
  console.log("exam link", localStorage.getItem("examLink"))
  return (
    <div className="rules-page">
      <div className="container">
        <div className="rules-page-inner">
          <h2 className="secion-subheader practice-header">
            Rules:
          </h2>
          <div className="rules-list">
            <div className="rules-item">
              <span><i class="fa-solid fa-hand-point-right"></i></span>
              The total time is 300s</div>
            <div className="rules-item">
            <span><i class="fa-solid fa-hand-point-right"></i></span>
              Ther is 30 second available for each question
            </div>
            <div className="rules-item">
            <span><i class="fa-solid fa-hand-point-right"></i></span>
              There are 5 questions available</div>
            <div className="rules-item">
            <span><i class="fa-solid fa-hand-point-right"></i></span>
              Users can move to next section before the 30s is depleted but they
              have to wait until the questions are presented
            </div>
          </div>
          <div className="rules-buttons">
            <button className="main-button" onClick={() => navigate(localStorage.getItem('examLink'))}>Proceed</button>
            <button className="alternate-button"  onClick={() => {
                window.close()

              }}>Exit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RulesPage;
